import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FileInfo, FileRestrictions, SelectEvent, UploadEvent } from '@progress/kendo-angular-upload';
import { elementAt, first, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  UsuariosService, AlertService, OperacionesPredefinidasService, MenuService, MaquinasService, InformeProyectosService,
  PiezasPredefinidasService, OperacionesService, ConsumiblesService, RecetasService, ConfiguracionService
} from '@app/_services';
import { MyFunctions } from '@app/_helpers';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { Subscription, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process } from "@progress/kendo-data-query";
import { Renderer2, NgZone } from '@angular/core';
import { tumblrBoxIcon } from '@progress/kendo-svg-icons';


interface ItemHerramienta {
  nombre: string,
  id: number
}

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};
const tableRow = node => node.tagName.toLowerCase() === 'tr';

@Component({
  selector: 'app-operacionespredefinidas',
  templateUrl: 'operacionesPredefinidasDetalle.html'
})

export class OperacionesPredefinidasDetalleComponent {

  @ViewChild('tabstrip') public tabstrip: TabStripComponent;

  mySelection: number[] = [];
  modalReferenceRemove: NgbModalRef;
  @ViewChild('popupBorrar') popupBorrar: NgbModalRef; //referencia al popup de borrado

  modalReferenceCopy: NgbModalRef;
  @ViewChild('popupCopiar') popupCopiar: NgbModalRef; //referencia al popup de copiar

  modalReferenceCopyAdvice: NgbModalRef;
  @ViewChild('popupCopiarAviso') popupCopiarAviso: NgbModalRef; //referencia al popup de copiar

  modalReferencepopupAddHerramienta: NgbModalRef;
  @ViewChild('popupAddHerramienta') popupAddHerramienta: NgbModalRef; //referencia al popup de popupAddHerramienta

  modalReferencepopupFrecuencias: NgbModalRef;
  @ViewChild('popupFrecuencias') popupFrecuencias: NgbModalRef; //referencia al popup de popupFrecuencias

  modalReferencepopupDocumentoCalidad: NgbModalRef;
  @ViewChild('popupDocumentoCalidad') popupDocumentoCalidad: NgbModalRef; //referencia al popup de popupDocumentoCalidad


  //Datos para modal cotas
  esFuncion_modal: any;
  funcion_modal: any;
  imagen_modal: any;
  ruta_modal: any = "";
  utilMedicionData: any;
  utilMedicionSelected: any;
  valores_modal: any = [];
  elementoSeleccionado_modal: any;
  traduccionResto = this.translate.instant("resto");
  utillajeModal: any = [];
  selectedUtillaje: any;
  submitted2: any;
  errorRutaModal: any;


  //Datos para subida excel cotas
  public myFiles2: Array<FileInfo> = [];
  private _kendoFiles: FileInfo[];
  modalReferenceloadingupload: NgbModalRef;

  public hayDatosFiltro: boolean = false; // for copiar form
  piezasListas: boolean;
  //Datos completos para filtro
  private dataFiltro: any; //operaciones
  private dataFiltro2: any; //piezas

  public listaPiezas: any;
  public piezasSeleccionadas: any;

  public listaOperaciones: any;
  public operacionesSeleccionadas: any;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['.png'],
    maxFileSize: 1048576
  };
  public imagePreviews: any[] = [];
  public events: string[] = [];
  public percentajes: string[] = [];
  public tipoOperacionLista: any = [];
  public histCambiosAntes: String;
  public histCambiosDespues: String;
  public histCambiosCampos: String;
  public logo: string;
  public selectedItems: ItemHerramienta[] = [];
  public idOperacion: number;
  /*public herramientasCombo: Array<ItemHerramienta> = [];
  public herramientasComboSelected: string = "";
  public herramientas_placeHolder: string = "";*/
  public datosTolerancia: any = [];
  public datosControl: any = [];
  public valores: any = [];
  public valoresFinales: any = [];
  public isPred: number;
  public referencias: [];
  public idruta: number;
  public idMaquinas: any[];
  public estadoMaquinas: any[];
  public maquinasEstados: {};
  public idParte: number;
  public idOF: number;
  public idPieza: number;
  public idVista: any; //Para saber a d�nde hay que ir despu�s con bot�n cancelar, a qu� vista
  
  //historico cambios
  nombreHisto: String;
  idErpHisto: String;
  secuenciaHisto: String;
  tipooperacionSuperiorHisto: number;
  ordenHistoric: number;
  subordenHistoric: number;
  tipooperacionHistoric: number;
  obligatoriaHistoric: number;
  edicionHistoric: number;
  loteHistoric: number;
  nSerieHistoric: number;
  coladaHistoric: number;
  calidadHistoric: number;
  piezasseguidasHistoric: number;
  ladoHisto: number;
  tiempoEstimadoHisto: string;
  tiempoEstimadoPreparacionHisto: string;
  costeHisto: number;
  imagenHisto: string;
  observacionesHisto: string;
  calidadCantidadHisto: number;
  calidadProcesarHisto: number;
  calidadRestoHisto: number;
  calidadObservacionesHisto: string;
  calidadResetearFrecuenciaHisto: boolean;
  calidadSolicitarCotaPiezaNoOkNoCriticasHisto: any;
  calidadSolicitarCotaPiezaNoOkCriticasHisto: any;
  calidadSolicitarObservacionHisto: any;
  calidadNumPrimerasPiezasOkHisto: number;
  calidadSoloDecimalesHisto: boolean;
  mostrarValoresNoOKOperarioHisto: boolean;
  confirmacionMensajeHisto: string;
  confirmacionRetrocederHisto: string;
  limitesPrioridadHisto: number;
  limitesProcesaFueraTallerHisto: number;
  limitesProcesarFueraTurnoHisto: number;
  limitesInterrumpirHisto: number;
  valoresHisto = undefined;
  herramientasHisto: any = [];
  programasHisto: any = [];
  
  tiempoCambio: number;
  idReferencia: number;
  nombre: String;
  orden: number;
  lado: number;
  suborden: number;
  tiempoestimado: number;
  tiempoEstimadoPreparacion: number;
  coste: number;
  observaciones: String;
  iderp: String;
  limitado: Boolean;
  imagen: String;
  referencia: string;
  public tipooperacion: number;
  
  public tipooperacionCalidadLista: any = [];
  public tiposControladoresCotasNoCriticas: any = [];
  public tiposControladoresCotasCriticas: any = [];
  ddControladorCotasNoCriticasChanged: boolean = false;
  ddControladorCotasCriticasChanged: boolean = false;
  public tiposObservacionPiezas: any = [];
  ddObservacionPiezasChanged: boolean = false;

  cantidad: number;
  procesar: number;
  resto: number;
  mensaje: String;
  retroceder: String;
  escandallo: String;
  cantidadescandallo: number;
  idRuta: number;
  fechainilimite: Date;
  fechafinlimite: Date;
  escandallomin: number;
  tipooperacionValor: boolean;
  tipooperacionConfirmacion: boolean;
  tipooperacionValormaquina: boolean;
  tipooperacionInformativo: boolean;
  public piezasseleccionados: number[] = [];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  isAddMode: boolean;
  public botonesDisbled: string;
  form: FormGroup;
  user = this.userService.userValue;

  submitted = false;

  closeResult = '';
  tituloPopUpPregunta: any;

  fechaHoyStr: string;
  tituloPopUp: any;

  maquinas: any;
  cambiomaquina: any;
  ineficencia: any;
  continuo: any;
  prioridad: any;
  partido: any;
  fueraturno: any;
  fuerataller: any;

  private editedRowIndex: number;
  public formGroup: FormGroup;
  public discard: String;
  sanitizer: any;

  //For tabs
  calidadstr: String = '';
  limitesstr: String = '';
  escandallostr: String = '';
  herramientasstr: String = '';
  programasstr : String = '';
  recetasstr: String = '';

  calidadActivo = false; //to know if Calidad's switch is actived
  isOperacion = true; //to know if we entered the page by operation or by piece
  public operacionesHermanas: any = [];

  //Variables para nueva versión herramientas
  public herramientas: any = [];
  public herramientasFinales: any = [];
  public herramientasSelected: any = [];
  public herramientasTotales: any = [];
  public herramientasTotalesSeleccionadas: any = [];
  private currentSubscription: Subscription;

  //Variables para nueva versión programas
  public programas: any = [];
  public programasFinales: any = [];
  public programasSelected: any = [];
  public programasTotales: any = [];
  public programasTotalesSeleccionadas: any = [];
  private currentSubscriptionProgramas: Subscription;

  public state: State = {
    skip: 0,
    take: 100,
  };

  public gridData: any = process([], this.state);
  
  // Programas
  public gridProgramas: any = process([], this.state);

  public listaRecetas: [] = [];
  public recetasSelected: any = [];

  constructor(
    private configuracionService: ConfiguracionService,
    private formBuilder: FormBuilder,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    private operacionesPredefinidasService: OperacionesPredefinidasService,
    private operacionesService: OperacionesService,
    private piezasPredefinidasService: PiezasPredefinidasService,
    private menuService: MenuService,
    public router: Router,
    private maquinasService: MaquinasService,
    private translate: TranslateService,
    private modalService: NgbModal,
    protected alertService: AlertService,
    private myFunctions: MyFunctions,
    public informeProyectosService: InformeProyectosService,
    private renderer: Renderer2,
    private zone: NgZone,
    private consumiblesService: ConsumiblesService,
    private recetasService: RecetasService  ) {

    var nav = this.router.getCurrentNavigation();
    this.menuService.titulo = this.translate.instant('operacionespredefinidas').toUpperCase();
    this.calidadstr = this.translate.instant('calidad');
    this.limitesstr = this.translate.instant('limites');
    this.escandallostr = this.translate.instant('escandallo');
    this.herramientasstr = this.translate.instant('herramientas');
    this.programasstr = this.translate.instant('programas');
    this.recetasstr = this.translate.instant('recetas');

  }
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  ngOnInit() {
    this.tipooperacionCalidadLista = [ {id:1, nombre: this.translate.instant('informativo')},
                                       {id:2, nombre: this.translate.instant('valor')},
                                        {id:3, nombre: this.translate.instant('confirmacion')}, 
                                        {id:4, nombre: this.translate.instant('valormaquina')},];


    this.idMaquinas = [];
    this.estadoMaquinas = [];
    this.percentajes = ["0-", "25-", "50-", "75-", "100-"];
    this.idPieza = this.route.snapshot.params.idpieza;
    this.idOF = this.route.snapshot.params.idof;
    this.idParte = this.route.snapshot.params.idparte;
    this.idRuta = this.route.snapshot.params.idruta;
    this.idOperacion = this.route.snapshot.params.idoperacion;
    this.idVista = this.route.snapshot.params.idVista;
    if (this.idOperacion > 0) {
      this.isAddMode = false;
    }else{
      this.isAddMode = true;
    }
    this.escandallomin = 0;
    var maquinas_validador_model = this.maquinasService.get_maquinas_planificador_model();
    if (maquinas_validador_model == false) {
      this.maquinasService.getMaquinasPlanificador().subscribe(json => {
        this.maquinasService.set_maquinas_planificador_model(json);
        this.maquinas = this.maquinasService.get_maquinas_planificador_model();
        //this.cargarMaquinas();
      })
    } else {
      this.maquinas = maquinas_validador_model;
      // this.cargarMaquinas();
    }

    this.operacionesService.getdatosControl().pipe().subscribe((resultControl) => {
      this.datosControl = resultControl; //Este nunca va a cambiar, pero la tolerancia va a ser distinta para cada valor
      var r2, r3, r4, r5, r6, r7: boolean = false;

      this.operacionesService.Get_TipoOperacion_Combo().pipe(first()).subscribe((result: any) => {
        this.tipoOperacionLista = result;
        r2 = true;
        if (r2 && r3 && r4 && r5 && r6 && r7) {
          if (this.idOperacion > 0) {
            this.isAddMode = false;
            this.CargarDatos();
          } else {
            this.isAddMode = true;
          }
        }
      });

      var maquinas_validador_model = this.maquinasService.get_maquinas_planificador_model();
      if (maquinas_validador_model == false) {
        this.maquinasService.getMaquinasPlanificador().subscribe(json => {
          this.maquinasService.set_maquinas_planificador_model(json);
          this.maquinas = this.maquinasService.get_maquinas_planificador_model();
          r3 = true;
          if (r2 && r3 && r4 && r5 && r6 && r7) {
            if (this.idOperacion > 0) {
              this.isAddMode = false;
              this.CargarDatos();
            } else {
              this.isAddMode = true;
            }
          }
        })
      } else {
        this.maquinas = maquinas_validador_model;
        r3 = true;
        if (r2 && r3 && r4 && r5 && r6 && r7) {
          if (this.idOperacion > 0) {
            this.isAddMode = false;
            this.CargarDatos();
          } else {
            this.isAddMode = true;
          }
        }
      }
      if(this.idParte==undefined || this.idParte<0)
        r4 = true;
      else{
        this.piezasPredefinidasService.GetByID(this.idParte).pipe(first()).subscribe((result: any) => {
          result.operaciones.forEach(element => {
            if(Number(element.id) != Number(this.idOperacion))
              this.operacionesHermanas.push(element);
          });
          r4 = true;
          if (r2 && r3 && r4 && r5 && r6 && r7) {
            if (this.idOperacion > 0) {
              this.isAddMode = false;
              this.CargarDatos();
            } else {
              this.isAddMode = true;
            }
          }
        });
      }
      this.operacionesService.getdatosUtillaje().pipe().subscribe((resultUtillaje) => {
        this.utillajeModal = resultUtillaje;
        r5=true;
        if (r2 && r3 && r4 && r5 && r6 && r7) {
          if (this.idOperacion > 0) {
            this.isAddMode = false;
            this.CargarDatos();
          } else {
            this.isAddMode = true;
          }
        }
      });

      this.configuracionService.get_controlarCotas_DAT().subscribe((result: any) => {
        result.forEach(function (element) { element.nombre = this.translate.instant(element.nombre); }, this);
        this.tiposControladoresCotasCriticas = result;
        this.tiposControladoresCotasNoCriticas = result.filter(x=>x.id!=3);
        r6=true;
        if (r2 && r3 && r4 && r5 && r6 && r7) {
          if (this.idOperacion > 0) {
            this.isAddMode = false;
            this.CargarDatos();
          } else {
            this.isAddMode = true;
          }
        }
      });

      this.configuracionService.get_observacionPiezas_DAT().subscribe((result: any) => {
        result.forEach(function (element) { element.nombre = this.translate.instant(element.nombre); }, this);
        this.tiposObservacionPiezas = result;
        r7=true;
        if (r2 && r3 && r4 && r5 && r6 && r7) {
          if (this.idOperacion > 0) {
            this.isAddMode = false;
            this.CargarDatos();
          } else {
            this.isAddMode = true;
          }
        }
      });
    });

    

    this.tituloPopUp = document.getElementById("text_loading_popup");

    this.botonesDisbled = "disabled";
    this.isAddMode = !this.idOperacion || this.idOperacion == undefined;

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      idruta: this.idRuta,
      idparte: this.idParte,
      idpieza: this.idPieza,
      idof: this.idOF,
      idReferencia: ['',],
      nombre: ['', Validators.required],
      tipo_operacion: [undefined,],
      tipo_operacion_id: [undefined,],
      orden: [1,],
      lado: [0,],
      suborden: [0,],
      tiempoestimado: ['00:00:00', Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
      tiempoEstimadoPreparacion: ['00:00:00', Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
      coste: [0.0000],
      observaciones: ['',],
      iderp: ['',],
      limitado: [false,],
      imagen: [''],
      informativo: [true,],
      valor: ['',],
      confirmacion: ['',],
      solicitar: ['',],
      cantidad: [0,],
      secuencia: ['',],
      procesar: [0,],
      resto: [0,],
      solicitaredicion: ['',],
      mensaje: ['',],
      retroceder_data: [{}],
      retroceder: [null,],
      escandallo: [0,],
      cantidadescandallo: [0,],
      solicitarNSerie: ['',],
      solicitarColada: ['',],
      solicitarLote: ['',],
      fueraturno: [''],
      fuerataller: [''],
      cambiomaquina: [''],
      fechainilimite: [''],
      fechafinlimite: [''],
      tiemposParadasEst: ['',],
      ineficencia: ['',],
      procesocontinuo: ['',],
      prioridad: ['',],
      tiempoentremaquinas: ['',],
      piezasseguidas: ['',],
      obligatoria: ['',],
      partido: [''],
      contino: ['',],
      fechainicio: [''],
      fechafin: [''],
      continuo: ['',],
      tipooperacion: [undefined,],
      tiempocambiomaquina: [0,],
      tipooperacionValor: [false,],
      tipooperacionConfirmacion: [false,],
      tipooperacionValormaquina: [false,],
      tipooperacionInformativo: [false,],
      archivo: new FormControl(''),
     /* herramientas: new FormControl([]),*/
      calidadObservaciones: [''],
      resetearFrecuencia: [false,],
      idControlarCotasNoCriticas: [1,],
      idControlarCotasCriticas: [1,],
      primerasPiezasNum: [0,],
      idObservacionPiezas: [1,],
      inputarSoloDecimales: [false,],
      mostrarValoresNoOKOperario: [false,],
      todos:[-1,],
      vc:[0,],
      f:[0,],
      ap_ini:[0,],
      ap:[0,],
      d_min:[0,]

    }
    );
    this.operacionesPredefinidasService.GetReferencias().pipe().subscribe((result) => {
      this.referencias = result.operaciones;

    });
    this.tipooperacion = undefined;
  }

  public onStateChange(e) {


    this.CargarDatos();
  }

  cargarDatosFiltro(): void { //tomar datos para filtro

    //se toman los datos
    this.operacionesPredefinidasService.GetAll().subscribe((data: any) => {
      this.piezasPredefinidasService.GetAll().subscribe((data2: any) => {

        this.dataFiltro = data.data;
        this.dataFiltro2 = data2.piezas;

        var groupByPieza = [];
        var groupByOperacion = [];
  
  
        //GROUP POR PIEZA
        this.dataFiltro2.forEach(function (a) {
          if (!this[a.nombre]) {
            this[a.nombre] = {
              idPieza: a.id, nombrePieza: a.nombre,
            };
            groupByPieza.push(this[a.nombre]);
          }
        }, Object.create(null));
  
        this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
        this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0))
  
        //GROUP BY Operaciones
        this.dataFiltro.forEach(function (a) {
          if (!this[a.nombre]) {
            this[a.nombre] = {
              idOperacion: a.id, nombreOperacion: a.nombre,
            };
            groupByOperacion.push(this[a.nombre]);
          }
        }, Object.create(null));
  

        this.listaOperaciones = groupByOperacion.filter(item => (item.idOperacion != -1));
        this.listaOperaciones.sort((a, b) => (a.nombreOperacion > b.nombreOperacion) ? 1 : ((b.nombreOperacion > a.nombreOperacion) ? -1 : 0));
      });

    });
  }

  CambioFiltro(): void { //si los datos del filtro cambian actualizar cu�les son los datos seleccionados

    var idPiezas = [];
    if (this.piezasSeleccionadas != undefined)
      idPiezas.push(this.piezasSeleccionadas.nombrePieza);

    var idOperaciones = [];
    if (this.operacionesSeleccionadas != undefined)
      idOperaciones.push(this.operacionesSeleccionadas.nombreOperacion);

    if (!this.isOperacion){
      if (this.piezasSeleccionadas != undefined){
        this.piezasListas = true;
        var idParte = this.dataFiltro2.filter((elem)=> elem.id === this.piezasSeleccionadas.idPieza)[0].idParte;
        this.piezasPredefinidasService.GetByID(idParte ).pipe().subscribe((result: any) => {
          var axuOperaciones = [];
          result.operaciones.forEach(element => {
            var auxDict = {nombreOperacion: element.nombre, idOperacion: element.id};
            axuOperaciones.push(auxDict);
          });
          this.listaOperaciones = axuOperaciones;
          this.listaOperaciones.sort((a, b) => (a.nombreOperacion > b.nombreOperacion) ? 1 : ((b.nombreOperacion > a.nombreOperacion) ? -1 : 0)); 
        });
      } else{
        this.piezasListas = false;
      }
    }
    if(this.isOperacion){
      if(this.piezasSeleccionadas !== undefined || this.operacionesSeleccionadas !== undefined)
      this.hayDatosFiltro = true; //ya est�n cargados los datos del filtro, los botones se pueden habilitar
      else{
        this.hayDatosFiltro = false; //desactivamos el bot�n si dejan de haber objetos seleccionados
      }
    } else{
      if(this.piezasListas && this.operacionesSeleccionadas !== undefined)
      this.hayDatosFiltro = true; //ya est�n cargados los datos del filtro, los botones se pueden habilitar
      else{
        this.hayDatosFiltro = false; //desactivamos el bot�n si dejan de haber objetos seleccionados
      }
    }
  }

  public copyData(event: any) {
    event.preventDefault(); //prevent submit
    this.cargarDatosFiltro();
    this.modalReferenceCopy = this.modalService.open(this.popupCopiar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  public copyElements(){

    this.operacionesPredefinidasService.GetByID(this.operacionesSeleccionadas.idOperacion).subscribe((response: any)=>{
      this.valores = response.valores;
      if(this.valores){
        //Para solventar error seleccion
        this.valores.forEach(element => {
          element.tipoControl = this.datosControl.filter(x=>x.id==element.tipoControl)[0];
          if(element.tipoControl.id!=-1){
            element.datosTolerancia = this.datosTolerancia.filter(x=>x.idTolerancia == element.tipoControl.id);
            if(element.datosTolerancia.length==0){
              element.datosTolerancia = [];
              element.tipoTolerancia = {nombre: "", id: -1};
            }else{
              element.tipoTolerancia = element.datosTolerancia.filter(x=>x.id==element.tipoTolerancia)[0];
            }
          }else{
            element.datosTolerancia = [];
            element.tipoTolerancia = {nombre: "", id: -1};
          }
          element.idNuevo = element.id;
        });
        this.valoresHisto = [];
        this.valores.forEach(val => this.valoresHisto.push(val)); //Hacemos una copia del array
      }
      this.valoresFinales = this.valores;
    });
    
   
    this.modalReferenceCopyAdvice.close();
  }

  public adviceCopyElements(){
    this.modalReferenceCopy.close();
    this.modalReferenceCopyAdvice = this.modalService.open(this.popupCopiarAviso, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  public volverACopyElements(){
    this.limpiarFiltro();
    this.modalReferenceCopyAdvice.close();
    this.modalReferenceCopy = this.modalService.open(this.popupCopiar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
    
  }

  public cancelarCopia(){
    this.limpiarFiltro();
    this.modalReferenceCopy.close();
  }

  public limpiarFiltro(){
    this.piezasSeleccionadas = undefined;
    this.operacionesSeleccionadas = undefined;
    this.CambioFiltro();
  }

  public removeElements() {

    this.mySelection.forEach(id => {
      this.valoresFinales.filter(x => x.idNuevo === id).forEach(element => {
        element.id = -1;
      });
      if(this.valoresHisto!=undefined){
        this.valoresHisto.filter(x => x.idNuevo === id).forEach(element => {
        element.id = -1;
      });
      }
    });
    this.valores = this.valoresFinales.filter(x => x.id !== -1);
    if(this.valoresHisto!=undefined)
      this.valoresHisto = this.valoresHisto.filter(x => x.id !== -1);
    this.modalReferenceRemove.close();
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);

    this.formGroup = new FormGroup({
      "id": new FormControl(dataItem.id),
      "idoperacion": new FormControl(this.idOperacion),
      'orden': new FormControl(dataItem.orden),
      'nombre': new FormControl(dataItem.nombre),
      'unidades': new FormControl(dataItem.unidades),
      'conlimite': new FormControl(dataItem.conLimite),
      'minimo': new FormControl(dataItem.minimo),
      'maximo': new FormControl(dataItem.maximo),
      'valornominal': new FormControl(dataItem.vnominal),
      'variablemaquina': new FormControl(dataItem.varmaquina),
      'esfuncion': new FormControl(dataItem.esFuncion),
      'funcion': new FormControl(dataItem.funcion),
      'activo': new FormControl(dataItem.activo),

    });

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public removeHandler({ dataItem }) {
    if(this.mySelection.length>0){
      this.modalReferenceRemove = this.modalService.open(this.popupBorrar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
    }
  }

  private CargarDatos() {
    this.operacionesPredefinidasService.GetByID(this.idOperacion).pipe().subscribe((result) => {
      if (result.operacion[0]?.imagenBase64!= undefined && result.operacion[0]?.imagenBase64 != "" && result.operacion[0]?.imagenBase64 != null) {
        this.imageToShow = result.operacion[0].imagenBase64;
      }

      if (result.operacion[0].prioridad == null) {
        this.prioridad = 0;
      } else {
        this.prioridad = result.operacion[0].prioridad;
      }
      this.partido = false;
      this.fueraturno = false;
      this.fuerataller = false;
      this.cambiomaquina = false;

      this.ineficencia = false;
      this.continuo = false;
      this.tiempoCambio = 0;
      this.fechainilimite = null;
      this.fechafinlimite = null;
      // this.fechafinlimite.setMonth(this.fechafinlimite.getMonth() + 1);
      if (result.operacion[0].fechaIni != undefined)
        this.fechainilimite = new Date(result.operacion[0].fechaIni);
      if (result.operacion[0].fechaFin != undefined)
        this.fechafinlimite = new Date(result.operacion[0].fechaFin);

        this.nombreHisto = result.operacion[0].operacion;
        this.idErpHisto = result.operacion[0].idERP;
        this.ordenHistoric = result.operacion[0].orden;
        this.subordenHistoric = result.operacion[0].subOrden;
        this.secuenciaHisto = result.operacion[0].secuencia;
      if (result.operacion[0].limitado != null) {
        this.limitado = (result.operacion[0].limitado == 1);
      }

      this.tipooperacion = result.operacion[0].idOperacion_Tipo;
      this.tipooperacionHistoric = this.tipooperacion;
      this.obligatoriaHistoric = result.operacion[0].obligatorio;
      this.edicionHistoric = result.operacion[0].solicitar_edicion;
      this.loteHistoric = result.operacion[0].solicitar_Lote;
      this.nSerieHistoric = result.operacion[0].solicitar_N_Serie;
      this.coladaHistoric = result.operacion[0].solicitar_Colada;
      this.calidadHistoric = result.operacion[0].calidad;
      this.piezasseguidasHistoric = result.operacion[0].operarVariasPiezasAlMismoTiempo;
      this.ladoHisto = result.operacion[0].lado;
      this.tiempoEstimadoHisto = this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoS);
      this.tiempoEstimadoPreparacionHisto = this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoPreparacionS);
      this.costeHisto = result.operacion[0].coste;
      this.limitesProcesaFueraTallerHisto = result.operacion[0].subcontratado;
      this.limitesProcesarFueraTurnoHisto = result.operacion[0].planificarFueraDeTurno;
      this.limitesInterrumpirHisto = result.operacion[0].cortarAlFinalizarTurno;
      this.limitesPrioridadHisto = this.prioridad;
      this.imagenHisto = result.operacion[0].imagen;
      this.observacionesHisto = result.operacion[0].observaciones;
      this.calidadCantidadHisto = result.operacion[0].cantidad;
      this.calidadProcesarHisto = result.operacion[0].calidadProcesar;
      this.calidadRestoHisto = result.operacion[0].calidadResto;
      this.calidadObservacionesHisto = result.operacion[0].calidadObservaciones;
      this.confirmacionMensajeHisto = result.operacion[0].no_Correcto_Mensaje;
      if(retroceder_data?.nombre != undefined)
        this.confirmacionRetrocederHisto = retroceder_data.nombre;
      else{
        this.confirmacionRetrocederHisto = "sinAsignar";
      }
      var tipo_operacion = this.tipoOperacionLista.find(x => x.id === result.operacion[0].tipo_operacion);
      if (tipo_operacion == undefined) tipo_operacion = { id: undefined };
      this.tipooperacionSuperiorHisto = tipo_operacion.nombre;

      var retroceder_data = this.operacionesHermanas.find(x => x.id === result.operacion[0].no_Correcto_Retroceder_a);
      if (retroceder_data == undefined) retroceder_data = {};

      //Histo config calidad
      this.calidadResetearFrecuenciaHisto = result.operacion[0].resetearFrecuencia;
      this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto = result.operacion[0].idControlarCotasNoCriticas;
      this.calidadSolicitarCotaPiezaNoOkCriticasHisto = result.operacion[0].idControlarCotasCriticas;
      this.calidadSolicitarObservacionHisto = result.operacion[0].idObservacionPiezas;
      this.calidadNumPrimerasPiezasOkHisto = result.operacion[0].primerasPiezasNum;
      this.calidadSoloDecimalesHisto = result.operacion[0].inputarSoloDecimales;
      this.mostrarValoresNoOKOperarioHisto = result.operacion[0].mostrarValoresNoOKOperario;

      this.form = this.formBuilder.group({
        idDb: this.user.idDb,
        idruta: this.idRuta,
        idparte: this.idParte,
        idpieza: this.idPieza,
        idof: this.idOF,
        idReferencia: [result.operacion[0].operacion,],
        nombre: [result.operacion[0].operacion, Validators.required],
        tipo_operacion: [tipo_operacion,],
        tipo_operacion_id: [tipo_operacion.id,],
        orden: [result.operacion[0].orden,],
        lado: [result.operacion[0].lado,],
        suborden: [result.operacion[0].subOrden,],
        tiempoestimado: [this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoS), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
        tiempoEstimadoPreparacion: [this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoPreparacionS), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
        coste: [result.operacion[0].coste, Validators.required],
        observaciones: [result.operacion[0].observaciones,],
        iderp: [result.operacion[0].idERP,],
        informativo: ['',],
        valor: ['',],
        confirmacion: ['',],
        solicitar: ['',],
        cantidad: [result.operacion[0].cantidad,],
        procesar: [result.operacion[0].calidadProcesar,],
        resto: [result.operacion[0].calidadResto,],
        mensaje: [result.operacion[0].no_Correcto_Mensaje,],
        retroceder_data: [retroceder_data],
        retroceder: [result.operacion[0].no_Correcto_Retroceder_a,],
        secuencia: [result.operacion[0].secuencia,],
        escandallo: [result.operacion[0].idBOM,],
        cantidadescandallo: [result.operacion[0].cantidadBOM,],
        solicitarNSerie: [result.operacion[0].solicitar_N_Serie,],
        solicitarColada: [result.operacion[0].solicitar_Colada,],
        solicitarLote: [result.operacion[0].solicitar_Lote,],
        solicitaredicion: [result.operacion[0].solicitar_edicion,],
        fueraturno: [result.operacion[0].planificarFueraDeTurno],
        fuerataller: [result.operacion[0].subcontratado,],
        cambiomaquina: [this.cambiomaquina,],
        tiempocambiomaquina: [this.tiempoCambio,],
        fechainicio: this.fechainilimite,
        fechafin: this.fechafinlimite,
        tiemposParadasEst: ['',],
        ineficencia: [this.ineficencia,],
        procesocontinuo: [result.operacion[0].cortarAlFinalizarTurno],
        prioridad: [this.prioridad,],
        tiempoentremaquinas: [this.tiempoCambio,],
        piezasseguidas: [result.operacion[0].operarVariasPiezasAlMismoTiempo,],
        obligatoria: [result.operacion[0].obligatorio,],
        partido: [this.partido,],
        continuo: [result.operacion[0].cortarAlFinalizarTurno,],
        tipooperacion: [result.operacion[0].idOperacion_Tipo],
        tipooperacionValor: [false,],
        tipooperacionConfirmacion: [false,],
        tipooperacionValormaquina: [false,],
        tipooperacionInformativo: [false,],
        archivo: new FormControl(''),
       /* herramientas: new FormControl(this.selectedItems),*/
        calidadObservaciones: [result.operacion[0].calidadObservaciones],
        resetearFrecuencia:[result.operacion[0].resetearFrecuencia],
        idControlarCotasNoCriticas: [result.operacion[0].idControlarCotasNoCriticas],
        idControlarCotasCriticas: [result.operacion[0].idControlarCotasCriticas],
        primerasPiezasNum: [result.operacion[0].primerasPiezasNum],
        idObservacionPiezas: [result.operacion[0].idObservacionPiezas],
        inputarSoloDecimales: [result.operacion[0].inputarSoloDecimales],
        mostrarValoresNoOKOperario: [result.operacion[0].mostrarValoresNoOKOperario],
        todos:[-1]
      }
      );
      this.calidadActivo = result.operacion[0].calidad;
      if (this.calidadActivo) this.calidadActivo = (this.user.calidad) > 0;

      this.idMaquinas = result.operacion[0].idsMaquinas.split(",");
      this.estadoMaquinas = result.operacion[0].prioridadesMaquinas.split(",");


      this.valores = result.valores;
      
      this.operacionesPredefinidasService.getdatosControl().pipe().subscribe((resultControl) => {
        this.operacionesPredefinidasService.getdatosTolerancia().pipe().subscribe((resultTolerancia) => {
          this.datosControl = resultControl; //Este nunca va a cambiar, pero la tolerancia va a ser distinta para cada valor
          this.datosTolerancia = resultTolerancia; //Aqui guardamos la general y luego las filtramos
          //Para solventar error seleccion
          if (this.valores){
            this.valores.forEach(element => {
              if (element.tipoControl != -1)
                element.tipoControl = this.datosControl.filter(x => x.id == element.tipoControl)[0];
              else {
                element.tipoControl = { nombre: "", id: -1 };
              }
              if (element.tipoControl!=undefined && element.tipoControl.id != -1) {
                element.datosTolerancia = this.datosTolerancia.filter(x => x.idTolerancia == element.tipoControl.id);
                if (element.datosTolerancia.length == 0) {
                  element.datosTolerancia = [];
                  element.tipoTolerancia = { nombre: "", id: -1 };
                } else {
                  element.tipoTolerancia = element.datosTolerancia.filter(x => x.id == element.tipoTolerancia)[0];
                }
              } else {
                element.datosTolerancia = [];
                element.tipoTolerancia = { nombre: "", id: -1 };
              }
              element.idNuevo = element.id;
              element.ruta = (element.ruta==null || element.ruta==undefined)?"":element.ruta;
              //element.visible= Boolean(element.visible);
            });
            this.valoresFinales = this.valores;
            this.valoresHisto = [];
            this.valores.forEach(val => this.valoresHisto.push(Object.assign({}, val))); //Hacemos una copia del array
          }
        });
      });
     
      console.log("result herramientas", result.herramientas);
      console.log("this.herramientas", this.herramientas);

      if(this.herramientas){
        this.herramientas = result.herramientas;
        this.herramientasHisto = [];
        result.herramientas.forEach(element => { //lo hacemos así para crear una copia, pues si solo se asigna al cambiar un dato de uno se cambia el del otro
          this.herramientasHisto.push(element);
        });
        this.herramientas.forEach(herramienta => {
          herramienta.tiempoUso = this.myFunctions.secondsTo_HH_MM_SS(herramienta.tiempoUso);
          herramienta.porcenUso = herramienta.porcentajeUso;
          //herramienta.id = herramienta.id; //porque no es una inserción, ya existía
          herramienta.idOperacion = Number(this.idOperacion);
          herramienta.idNuevo = herramienta.id; //para solventar error insercion
        });
        this.herramientasFinales = this.herramientas;
        this.recargarGrid();
      }


      if(this.programas){
        this.programas = result.programas;
        this.programasHisto = [];
        result.programas.forEach(element => { //lo hacemos así para crear una copia, pues si solo se asigna al cambiar un dato de uno se cambia el del otro
          this.programasHisto.push(element);
        });
        this.programas.forEach(programa => {
          programa.tiempoUso = this.myFunctions.secondsTo_HH_MM_SS(programa.tiempoUso);
          programa.porcenUso = programa.porcentajeUso;
          //programa.id = programa.id; //porque no es una inserción, ya existía
          programa.idOperacion = Number(this.idOperacion);
          programa.idNuevo = programa.id; //para solventar error insercion
        });
        this.programasFinales = this.programas;
        this.recargarGridProgramas();
      }


      

      this.listaRecetas = result.recetas;
      
    }
    );
  }

  public reorderData(event: any){
    event.preventDefault(); //prevent submit
    //se reordena el json segun el nuevo orden
    this.valoresFinales = this.valoresFinales.sort(function(elem1, elem2){
      // Get data
      var keyA = elem1.orden, keyB = elem2.orden;
      // Compare the 2 elems
      if (keyA > keyB) return 1;
      if (keyA < keyB) return -1;
      return 0;
    });
    //se consolida el orden y se hace unico
    this.valoresFinales.forEach((elem: any, index) => {
      elem.orden = index + 1;
    }); 
    this.valores = this.valoresFinales.filter(x => x.id !== -1); 
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  initMaquinas() {
    try {
      this.idMaquinas.forEach(Element => {
        var mid;
        var value = '1';
        this.idMaquinas.forEach((element, index) => {
          if (element == Element) {
            value = this.estadoMaquinas[index];

          }
        });
        switch (parseInt(value)) {
          case 0:
            mid = "0-" + Element;
            var emptySpan = document.getElementById(mid);
            emptySpan.style.display = "block";
            break;
          case 25:
            mid = "25-" + Element;
            var emptySpan = document.getElementById(mid);
            emptySpan.style.display = "block";
            break;
          case 50:
            mid = "50-" + Element;
            var emptySpan = document.getElementById(mid);
            emptySpan.style.display = "block";
            break;
          case 75:
            mid = "75-" + Element;
            var emptySpan = document.getElementById(mid);
            emptySpan.style.display = "block";
            break;
          case 100:
            mid = "100-" + Element;
            var emptySpan = document.getElementById(mid);
            emptySpan.style.display = "block";
            break;
          default:
            break;
        }
      });
    }
    catch{ }
  }

  onClickPrioridadMaquina(e) {

    var spanseleccionado = e.srcElement.children[0];

    if (spanseleccionado == undefined) {
      spanseleccionado = e.srcElement;
      spanseleccionado.style.width = "20px";
      spanseleccionado.style.display = "";
      return;

    }
    this.percentajes.forEach(Element => {
      let id = Element + spanseleccionado.parentElement.parentElement.parentElement.firstChild.value;
      var emptySpan = document.getElementById(id);
      emptySpan.style.display = "";
    });
    var idmaquina = spanseleccionado.parentElement.parentElement.parentElement.firstChild.value;
    var span = document.getElementsByClassName("prioridad-seleccionada") as HTMLCollectionOf<HTMLElement>;;

    for (var i = 0; i < span.length; i++) {
      var idmaquinaOtros = span[i].parentElement.parentElement.parentElement.firstChild.nodeValue;
      if (span[i] != spanseleccionado && idmaquina == idmaquinaOtros)
        //span[i].style.display = "";
        span[i].setAttribute("style", "display: none;");

    }


    if (spanseleccionado == undefined)
      spanseleccionado = e.srcElement;

    spanseleccionado.style.width = "20px";
    var idmaquina = spanseleccionado.parentElement.parentElement.parentElement.firstChild.value;
    if (spanseleccionado.style.display == "") {
      var porcentaje = spanseleccionado.parentElement.dataset.porcentaje;
      spanseleccionado.style.display = "block";
      this.GuardarPorcentaje(idmaquina, porcentaje);
    }

    else {
      spanseleccionado.style.display = "";
      this.EliminarPorcentaje(idmaquina);
    }
  }

  GuardarPorcentaje(idmaquina: any, porcentaje: any) {
    // this.initMaquinas();
    var id = idmaquina.toString();
    var prioridad = porcentaje.toString();
    var changed = 0;
    if(this.idMaquinas !== undefined)
      this.idMaquinas.forEach((element, index) => {

        if (element == id) {
          this.estadoMaquinas[index] = prioridad;
          changed = 1;
        }

      });

    if (changed == 0) {
      this.idMaquinas?.push(id);
      this.estadoMaquinas?.push(prioridad);
    }
    /*this.maquinasService.guardarPrioridadMaquina(this.idOF, this.idPieza, this.idParte, this.idRuta, this.idOperacion, idmaquina, porcentaje).subscribe((result) => {
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });

      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }

    });*/
  }


  EliminarPorcentaje(idmaquina: any) {
    this.maquinasService.EliminarPrioridadMaquina(this.idOF, -1, -1, -1, -1, idmaquina).subscribe((result) => {
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });

      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }

    });
  }


  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public onClickNuevoValor({ sender }) {
    var maxId
    if(this.valoresFinales.length === 0)
      maxId = -1
    else
      maxId = Math.max.apply(Math, this.valoresFinales.map(value => value.idNuevo));
    var auxLength = (this.valoresFinales.filter(x => x.id !== -1)).length;

    var dt: any = { 
      //'visible': false,
      'activo': false,
      'imagen': false, 
      'conLimite': false,
      'eje': "",
      'esFuncion': false,
      'funcion': "",
      'id': 0,
      'idNuevo': maxId + 1,
      'idOperacion': Number(this.idOperacion),
      'toleranciaMax': 0.0,
      'toleranciaMin': 0.0,
      'nombre': "",
      'orden': auxLength + 1,
      //'referencia': "",
      'referenciada': "false",
      'unidades': "",
      'varmaquina': "",
      'vnominal': 0,
      'critico': false,
      'porDefecto': true,
      'cantidad': this.form.value.cantidad,
      'procesar': this.form.value.procesar,
      'resto': this.form.value.resto,
      //'descripcion': "",
      'tipoTolerancia': {nombre: "", id: -1},
      'tipoControl': {nombre: "", id: -1},
      'datosTolerancia': [],
      'frecuencia1_cant': undefined,
      'frecuencia1_porcen1': undefined,
      'frecuencia1_porcen2': undefined,
      'frecuencia2_cant': undefined,
      'frecuencia2_porcen1': undefined,
      'frecuencia2_porcen2': undefined,
      'frecuencia3_cant': undefined,
      'frecuencia3_porcen1': undefined,
      'frecuencia3_porcen2': undefined,
      'frecuencia4_cant': undefined,
      'frecuencia4_porcen1': undefined,
      'frecuencia4_porcen2': undefined,
      'frecuencia5_cant': undefined,
      'frecuencia5_porcen1': undefined,
      'frecuencia5_porcen2': undefined,
      'resto_cant': undefined,
      'resto_porcen1': undefined,
      'resto_porcen2': undefined,
      'cambioOperario_cant': undefined,
      'cambioOperario_porcen1': undefined,
      'cambioOperario_porcen2': undefined,
      'ruta': "",
      'idUtillaje': -1
    }
  
    
    this.valoresFinales.push(dt);
    this.valores = this.valoresFinales.filter(x => x.id !== -1);
    if(!this.isAddMode){
      //Como se est� metiendo un valor nuevo, es decir, que al principio de entrar a la vista no exist�a, hay que a�adirlo como un vac�o en el hist�rico de valores
     this.valoresHisto.push({
       //'visible': "sinAsignar",
       'imagen': "sinAsignar",
       'eje': "sinAsignar",
       'esFuncion': "sinAsignar",
       'funcion': "sinAsignar",
       'id': 0,
       'idNuevo': maxId + 1,
       'toleranciaMax': "sinAsignar",
       'toleranciaMin': "sinAsignar",
       'nombre': "sinAsignar",
       'orden': "sinAsignar",
       //'referencia': "sinAsignar",
       'unidades':  "sinAsignar",
       'vnominal':  "sinAsignar",
       'critico': "sinAsignar",
       'porDefecto': "sinAsignar",
       'cantidad': "sinAsignar",
       'procesar': "sinAsignar",
       'resto': "sinAsignar",
       //'descripcion': "sinAsignar",
       'tipoTolerancia': "sinAsignar",
       'tipoControl': "sinAsignar",
       'frecuencia1_cant': "sinAsignar",
       'frecuencia1_porcen1': "sinAsignar",
       'frecuencia1_porcen2': "sinAsignar",
       'frecuencia2_cant': "sinAsignar",
       'frecuencia2_porcen1': "sinAsignar",
       'frecuencia2_porcen2': "sinAsignar",
       'frecuencia3_cant': "sinAsignar",
       'frecuencia3_porcen1': "sinAsignar",
       'frecuencia3_porcen2': "sinAsignar",
       'frecuencia4_cant': "sinAsignar",
       'frecuencia4_porcen1': "sinAsignar",
       'frecuencia4_porcen2': "sinAsignar",
       'frecuencia5_cant': "sinAsignar",
       'frecuencia5_porcen1': "sinAsignar",
       'frecuencia5_porcen2': "sinAsignar",
       'resto_cant': "sinAsignar",
       'resto_porcen1': "sinAsignar",
       'resto_porcen2': "sinAsignar",
       'cambioOperario_cant': "sinAsignar",
       'cambioOperario_porcen1': "sinAsignar",
       'cambioOperario_porcen2': "sinAsignar",
       'ruta': "",
       'idUtillaje': "sinAsignar"
     });
   }
  }


  public saveHandler({ sender, rowIndex, formGroup, isNew }) {


    if (isNew) {
      this.discard = this.translate.instant('descartarcambios');
      formGroup.value.idOperacion = this.idOperacion;
      this.operacionesPredefinidasService.addValor(formGroup.value).subscribe((result) => {
        //this.modalReferenceloading.close();
        {

          if (result.error == false) {
            this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
            this.CargarDatos();

          }
          else {
            this.alertService.error(this.translate.instant('error'));
          }
        }
      })






    }
    else {
      this.discard = this.translate.instant('cancelar');

      this.operacionesPredefinidasService.editValor(formGroup.value).subscribe((result) => {
        //this.modalReferenceloading.close();
        {

          if (result.error == false) {
            this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
            this.CargarDatos();

          }
          else {
            this.alertService.error(this.translate.instant('error'));
          }
        }
      })


    }

    sender.closeRow(rowIndex);

  }


  onClickEliminarOperacion(content) {
    if (this.tituloPopUpPregunta != undefined) {
      this.tituloPopUpPregunta.innerText = this.translate.instant("popup.preguntaeliminarpopupdetallecompleto");
    }
    this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  addHandler(e, content) {

    this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

  }

  onSubmit(contentloading, e, option) {
    if (this.form.value.retroceder_data!=null && this.form.value.retroceder_data!=undefined)
      this.form.value.retroceder = this.form.value.retroceder_data.id;
    else{
      this.form.value.retroceder = -1;
    }
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    if(this.herramientas.length>0){//si hay herramientas mirar si alguna tiene un error 
      var hayErrores= false;
      this.herramientas.forEach(element => { 
        if(element.errorTiempoEstimado || element.errorTiempos){
          hayErrores= true;
        }
        //también aprovechamos para corregir datos para el guardado en la base de datos
        if(!Number(element.tiempoUso))
          element.tiempoUso = this.HMSToSeconds(element.tiempoUso);
        //Les ponemos cero porque para nuestro caso nos dan igual esos valores
        if(element.numContenido == "-"){
          element.numContenido = 0;
        }
        if(element.vidaUtil == "-"){
          element.vidaUtil = 0;
        }
        if(element.fiabilidad == "-"){
          element.fiabilidad = 0;
        }
        if(element.diametro == "-"){
          element.diametro = 0;
        }
        if(element.longitud == "-"){
          element.longitud = 0;
        }
        if(element.angulo == "-"){
          element.angulo = 0;
        }
        if(element.salto == "-"){
          element.salto = 0;
        }
        if(element.idERP == "-"){
          element.idERP = '0';
        }
        if(element.coste == "-"){
          element.coste = 0;
        }
      });
      if(hayErrores) //lo tiene, salir
      {
        return;
      }
        
    }



    if(this.programas.length>0){//si hay programas mirar si alguna tiene un error 
      var hayErrores= false;
      this.programas.forEach(element => { 
        if (element.Tmedio = ""){
          element.Tmedio = 0;
        }
        if (element.MejorT = ""){
          element.MejorT = 0;
        }
        if(!Number(element.Tmedio))
          element.Tmedio = this.HMSToSeconds(element.Tmedio);
          if(!Number(element.MejorT))
          element.MejorT = this.HMSToSeconds(element.MejorT);
      });
    }

    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

    /*if (this.isPred != undefined && this.isPred == 1) {
      //this.nuevoPredef();
    } else {
      if (this.isPred != undefined && this.isPred == 2) {
        //this.nuevoPredefaLinea();
      }
      else {*/
        if (this.isAddMode) {
          this.nuevo();
        } else {
          this.actualizar(option,contentloading);
        } 
    /*  }
    }*/

  }
  async actualizar(option, contentloading) {
    this.modalReference.close();
    //this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    //BERRIA
    /* if(this.herramientas.length>0){//si hay herramientas mirar si alguna tiene un error 
      var hayErrores= false;
      this.herramientas.forEach(element => { 
        if(element.errorTiempoEstimado || element.errorTiempos){
          hayErrores= true;
        }
        //también aprovechamos para corregir datos para el guardado en la base de datos
        if(!Number(element.tiempoUso))
          element.tiempoUso = this.HMSToSeconds(element.tiempoUso);
        //Les ponemos cero porque para nuestro caso nos dan igual esos valores
        if(element.numContenido == "-"){
          element.numContenido = 0;
        }
        if(element.vidaUtil == "-"){
          element.vidaUtil = 0;
        }
        if(element.fiabilidad == "-"){
          element.fiabilidad = 0;
        }
        if(element.diametro == "-"){
          element.diametro = 0;
        }
        if(element.longitud == "-"){
          element.longitud = 0;
        }
        if(element.angulo == "-"){
          element.angulo = 0;
        }
        if(element.salto == "-"){
          element.salto = 0;
        }
        if(element.idERP == "-"){
          element.idERP = 0;
        }
        if(element.coste == "-"){
          element.coste = 0;
        }
      });
      if(hayErrores) //lo tiene, salir
      {
        return;
      }
        
    } */
    //

    if (this.tipooperacion==1 || this.tipooperacion==3)
    {
      this.valores=[];
      this.valoresFinales=[];
    }

    var idsmaquinas = "";
    var estadosmaquinas = "";
    this.estadoMaquinas.forEach((element, index) => {
      if (element != "" && element != 1) {
        idsmaquinas = idsmaquinas + "," + this.idMaquinas[index];
        estadosmaquinas = estadosmaquinas + "," + element;
      }
    });
    //CAMBIOS PARA HISTORICO

    this.histCambiosAntes = "";
    this.histCambiosDespues = "";
    this.histCambiosCampos = "";
    
    if (this.herramientas != this.herramientasHisto) {
      this.histCambiosAntes = this.histCambiosAntes.concat("haHabidoCambios");
      this.histCambiosDespues = this.histCambiosDespues.concat("-");
      this.histCambiosCampos = this.histCambiosCampos.concat("herramientas");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.programas != this.programasHisto) {
      this.histCambiosAntes = this.histCambiosAntes.concat("haHabidoCambios");
      this.histCambiosDespues = this.histCambiosDespues.concat("-");
      this.histCambiosCampos = this.histCambiosCampos.concat("programas");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.nombreHisto != this.form.value.nombre) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.nombreHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.nombre.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("nombre");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.idErpHisto != this.form.value.iderp) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.idErpHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.iderp.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("idERP");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    var auxi;
    if(this.tipooperacionSuperiorHisto == undefined){
      auxi = "sinAsignar";
    }else{
      auxi = this.tipooperacionSuperiorHisto.toString();
    }

    var auxi2;
    if(this.form.value.tipo_operacion?.nombre == undefined){
      auxi2 = "sinAsignar";
    }else{
      auxi2 = this.form.value.tipo_operacion.nombre.toString();
    }

    if (auxi != auxi2) {
      this.histCambiosAntes = this.histCambiosAntes.concat(auxi);
      this.histCambiosDespues = this.histCambiosDespues.concat(auxi2);
      this.histCambiosCampos = this.histCambiosCampos.concat("tipooperacion");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.tipooperacionHistoric != this.tipooperacion) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.tipooperacionHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.tipooperacion.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("idOperacion_Tipo");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.ordenHistoric != this.form.value.orden) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.ordenHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.orden.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("orden");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.subordenHistoric != this.form.value.suborden) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.subordenHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.suborden.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("suborden");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.secuenciaHisto != this.form.value.secuencia) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.secuenciaHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.secuencia.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("secuencia");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.edicionHistoric != this.form.value.solicitaredicion) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.edicionHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.solicitaredicion.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("edicion");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.nSerieHistoric != this.form.value.solicitarNSerie) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.nSerieHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.solicitarNSerie.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("nserie");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (Boolean(this.calidadHistoric) != Boolean(this.calidadActivo)) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.calidadActivo.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("calidad");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.coladaHistoric != this.form.value.solicitarColada) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.coladaHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.solicitarColada.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("pedircolada");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.loteHistoric != this.form.value.solicitarLote) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.loteHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.solicitarLote.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("lote");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.obligatoriaHistoric != this.form.value.obligatoria) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.obligatoriaHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.obligatoria.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("operacionobligatoria");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.piezasseguidasHistoric != this.form.value.piezasseguidas) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.piezasseguidasHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.piezasseguidas.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("piezasseguidas");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.ladoHisto != this.form.value.lado) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.ladoHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.lado.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("lado");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.tiempoEstimadoHisto != this.form.value.tiempoestimado) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.tiempoEstimadoHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.tiempoestimado.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("tiempoEstimado");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.tiempoEstimadoPreparacionHisto != this.form.value.tiempoEstimadoPreparacion) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.tiempoEstimadoPreparacionHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.tiempoEstimadoPreparacion.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("tiempoEstimadoPreparacion");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.costeHisto != this.form.value.coste) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.costeHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.coste.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("coste");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.observacionesHisto != this.form.value.observaciones) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.observacionesHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.observaciones.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("observacion");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.calidadCantidadHisto != this.form.value.cantidad) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadCantidadHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.cantidad.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("calidad_cantidad2");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.calidadProcesarHisto != this.form.value.procesar) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadProcesarHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.procesar.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("calidad_procesar");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.calidadRestoHisto != this.form.value.resto) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadRestoHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.resto.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("calidad_resto");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    } 

    if (this.calidadObservacionesHisto != this.form.value.calidadObservaciones) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadObservacionesHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.calidadObservaciones.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("calidad_observacion");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    } 

    if (this.confirmacionMensajeHisto != this.form.value.mensaje) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.confirmacionMensajeHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.mensaje.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("confirmacion_mensaje");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    } 
    var auxi;
    if(this.form.value.retroceder_data?.nombre == undefined){
      auxi = "sinAsignar";
    }else{
      auxi = this.form.value.retroceder_data.nombre.toString();
    }

    if (this.confirmacionRetrocederHisto != auxi) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.confirmacionRetrocederHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.retroceder_data.nombre.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("confirmacion_retroceder");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    } 

    if (this.limitesPrioridadHisto != this.form.value.prioridad) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.limitesPrioridadHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("limites_prioridad");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    } 

    if (Boolean(this.limitesProcesaFueraTallerHisto) != Boolean(this.form.value.fuerataller)) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.limitesProcesaFueraTallerHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.fuerataller.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("limites_fuerataller");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (Boolean(this.limitesProcesarFueraTurnoHisto) != Boolean(this.form.value.fueraturno)) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.limitesProcesarFueraTurnoHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.fueraturno.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("limites_fueraturno");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (Boolean(this.limitesInterrumpirHisto) != Boolean(this.form.value.continuo)) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.limitesInterrumpirHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.continuo.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("limites_procesocontinuo");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if(this.form.value.resetearFrecuencia== undefined || this.form.value.resetearFrecuencia== null){
      this.form.value.resetearFrecuencia = false;
    }

    if(this.calidadResetearFrecuenciaHisto== undefined || this.calidadResetearFrecuenciaHisto== null){
      this.calidadResetearFrecuenciaHisto = false;
    }

    if (this.calidadResetearFrecuenciaHisto != this.form.value.resetearFrecuencia) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadResetearFrecuenciaHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.resetearFrecuencia.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("resetearFrecuencia");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if(this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto==undefined || this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto<=0){
      this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto = {id:-1, nombre:'sinAsignar'}
    }else if(this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto.id==undefined){
      this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto = this.tiposControladoresCotasNoCriticas.filter(x=>x.id==this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto)[0];
    }

    if(this.form.value.idControlarCotasNoCriticas==undefined || this.form.value.idControlarCotasNoCriticas<=0){
      this.form.value.idControlarCotasNoCriticas = {id:-1, nombre:'sinAsignar'}
      this.ddControladorCotasNoCriticasChanged=true;
    }else if(this.form.value.idControlarCotasNoCriticas.id==undefined){
      this.form.value.idControlarCotasNoCriticas = this.tiposControladoresCotasNoCriticas.filter(x=>x.id==this.form.value.idControlarCotasNoCriticas)[0];
      this.ddControladorCotasNoCriticasChanged=true;
    }

    if (this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto.nombre != this.form.value.idControlarCotasNoCriticas.nombre) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto.nombre.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.idControlarCotasNoCriticas.nombre.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("controlarCotasDesde0NoCriticas");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if(this.calidadSolicitarCotaPiezaNoOkCriticasHisto==undefined || this.calidadSolicitarCotaPiezaNoOkCriticasHisto<=0){
      this.calidadSolicitarCotaPiezaNoOkCriticasHisto = {id:-1, nombre:'sinAsignar'}
    }else if(this.calidadSolicitarCotaPiezaNoOkCriticasHisto.id==undefined){
      this.calidadSolicitarCotaPiezaNoOkCriticasHisto = this.tiposControladoresCotasCriticas.filter(x=>x.id==this.calidadSolicitarCotaPiezaNoOkCriticasHisto)[0];
    }

    if(this.form.value.idControlarCotasCriticas==undefined || this.form.value.idControlarCotasCriticas<=0){
      this.form.value.idControlarCotasCriticas = {id:-1, nombre:'sinAsignar'}
      this.ddControladorCotasCriticasChanged=true;
    }else if(this.form.value.idControlarCotasCriticas.id==undefined){
      this.form.value.idControlarCotasCriticas = this.tiposControladoresCotasCriticas.filter(x=>x.id==this.form.value.idControlarCotasCriticas)[0];
      this.ddControladorCotasCriticasChanged=true;
    }

    if (this.calidadSolicitarCotaPiezaNoOkCriticasHisto.nombre != this.form.value.idControlarCotasCriticas.nombre) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadSolicitarCotaPiezaNoOkCriticasHisto.nombre.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.idControlarCotasCriticas.nombre.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("controlarCotasDesde0Criticas");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if(this.calidadSolicitarObservacionHisto==undefined || this.calidadSolicitarObservacionHisto<=0){
      this.calidadSolicitarObservacionHisto = {id:-1, nombre:'sinAsignar'}
    }else if(this.calidadSolicitarObservacionHisto.id==undefined){
      this.calidadSolicitarObservacionHisto = this.tiposObservacionPiezas.filter(x=>x.id==this.calidadSolicitarObservacionHisto)[0];
    }

    if(this.form.value.idObservacionPiezas==undefined || this.form.value.idObservacionPiezas<=0){
      this.form.value.idObservacionPiezas = {id:-1, nombre:'sinAsignar'}
      this.ddObservacionPiezasChanged=true;
    }else if(this.form.value.idObservacionPiezas.id==undefined){
      this.form.value.idObservacionPiezas = this.tiposObservacionPiezas.filter(x=>x.id==this.form.value.idObservacionPiezas)[0];
      this.ddObservacionPiezasChanged=true;
    }

    if (this.calidadSolicitarObservacionHisto.nombre != this.form.value.idObservacionPiezas.nombre) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadSolicitarObservacionHisto.nombre.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.idObservacionPiezas.nombre.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("observacionPiezas");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if(this.calidadNumPrimerasPiezasOkHisto==undefined || this.calidadNumPrimerasPiezasOkHisto== null){
      this.calidadNumPrimerasPiezasOkHisto=0;
    }
    if(this.form.value.primerasPiezasNum==undefined || this.form.value.primerasPiezasNum== null){
      this.form.value.primerasPiezasNum=0;
    }

    if (this.calidadNumPrimerasPiezasOkHisto != this.form.value.primerasPiezasNum) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadNumPrimerasPiezasOkHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.primerasPiezasNum.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("primerasPiezasNum");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if(this.calidadSoloDecimalesHisto==undefined || this.calidadSoloDecimalesHisto== null){
      this.calidadSoloDecimalesHisto=false;
    }
    if(this.form.value.inputarSoloDecimales==undefined || this.form.value.inputarSoloDecimales== null){
      this.form.value.inputarSoloDecimales=false;
    }

    if (this.calidadSoloDecimalesHisto != this.form.value.inputarSoloDecimales) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadSoloDecimalesHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.inputarSoloDecimales.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("inputarSoloDecimales");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if(this.mostrarValoresNoOKOperarioHisto==undefined || this.mostrarValoresNoOKOperarioHisto== null){
      this.mostrarValoresNoOKOperarioHisto=false;
    }
    if(this.form.value.mostrarValoresNoOKOperario==undefined || this.form.value.mostrarValoresNoOKOperario== null){
      this.form.value.mostrarValoresNoOKOperario=false;
    }

    if (this.mostrarValoresNoOKOperarioHisto != this.form.value.mostrarValoresNoOKOperario) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.mostrarValoresNoOKOperarioHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.mostrarValoresNoOKOperario.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("mostrarValoresNoOKOperario");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    //Sus length deberian ser iguales, aunque el contenido pueda ser distinto
    if(this.valoresFinales.length > 0 && this.valoresHisto.length == this.valores.length && this.valoresHisto !== this.valores){
      var elementoNuevo;
      this.valoresHisto.forEach(elemento => {
        elementoNuevo = this.valores.filter(x => x.idNuevo == elemento.idNuevo)[0]; //Si existe en uno deber�a existir en el otro
          //agregar historico por cada cambio
          if (elemento.orden != elementoNuevo.orden) {
            this.histCambiosAntes = this.histCambiosAntes.concat(elemento.orden.toString());
            this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.orden.toString());
            this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_orden_actualizado");
            this.histCambiosAntes = this.histCambiosAntes.concat(',');
            this.histCambiosDespues = this.histCambiosDespues.concat(',');
            this.histCambiosCampos = this.histCambiosCampos.concat(',');
          } 
          /* if (elemento.visible != elementoNuevo.visible) {
            this.histCambiosAntes = this.histCambiosAntes.concat(elemento.visible.toString());
            this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.visible.toString());
            this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_visible_actualizado");
            this.histCambiosAntes = this.histCambiosAntes.concat(',');
            this.histCambiosDespues = this.histCambiosDespues.concat(',');
            this.histCambiosCampos = this.histCambiosCampos.concat(',');
          } 
          if (elemento.referencia != elementoNuevo.referencia) {
            this.histCambiosAntes = this.histCambiosAntes.concat(elemento.referencia.toString());
            this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.referencia.toString());
            this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_referencia_actualizado");
            this.histCambiosAntes = this.histCambiosAntes.concat(',');
            this.histCambiosDespues = this.histCambiosDespues.concat(',');
            this.histCambiosCampos = this.histCambiosCampos.concat(',');
          }  */
          if (elemento.nombre != elementoNuevo.nombre) {
            this.histCambiosAntes = this.histCambiosAntes.concat(elemento.nombre.toString());
            this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.nombre.toString());
            this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_nombre_actualizado");
            this.histCambiosAntes = this.histCambiosAntes.concat(',');
            this.histCambiosDespues = this.histCambiosDespues.concat(',');
            this.histCambiosCampos = this.histCambiosCampos.concat(',');
          } 
          if (elemento.eje != elementoNuevo.eje) {
            this.histCambiosAntes = this.histCambiosAntes.concat(elemento.eje.toString());
            this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.eje.toString());
            this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_eje_actualizado");
            this.histCambiosAntes = this.histCambiosAntes.concat(',');
            this.histCambiosDespues = this.histCambiosDespues.concat(',');
            this.histCambiosCampos = this.histCambiosCampos.concat(',');
          } 
          if (elemento.vnominal != elementoNuevo.vnominal) {
            this.histCambiosAntes = this.histCambiosAntes.concat(elemento.vnominal.toString());
            this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.vnominal.toString());
            this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_valornominal_actualizado");
            this.histCambiosAntes = this.histCambiosAntes.concat(',');
            this.histCambiosDespues = this.histCambiosDespues.concat(',');
            this.histCambiosCampos = this.histCambiosCampos.concat(',');
          } 
          if (elemento.toleranciaMax != elementoNuevo.toleranciaMax) {
            this.histCambiosAntes = this.histCambiosAntes.concat(elemento.toleranciaMax.toString());
            this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.toleranciaMax.toString());
            this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_maxTolerancia_actualizado");
            this.histCambiosAntes = this.histCambiosAntes.concat(',');
            this.histCambiosDespues = this.histCambiosDespues.concat(',');
            this.histCambiosCampos = this.histCambiosCampos.concat(',');
          } 
          if (elemento.toleranciaMin != elementoNuevo.toleranciaMin) {
            this.histCambiosAntes = this.histCambiosAntes.concat(elemento.toleranciaMin.toString());
            this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.toleranciaMin.toString());
            this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_minTolerancia_actualizado");
            this.histCambiosAntes = this.histCambiosAntes.concat(',');
            this.histCambiosDespues = this.histCambiosDespues.concat(',');
            this.histCambiosCampos = this.histCambiosCampos.concat(',');
          } 
          if (elemento.unidades != elementoNuevo.unidades) {
            this.histCambiosAntes = this.histCambiosAntes.concat(elemento.unidades.toString());
            this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.unidades.toString());
            this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_unidad_actualizado");
            this.histCambiosAntes = this.histCambiosAntes.concat(',');
            this.histCambiosDespues = this.histCambiosDespues.concat(',');
            this.histCambiosCampos = this.histCambiosCampos.concat(',');
          } 
          if (elemento.esFuncion != elementoNuevo.esFuncion) {
            this.histCambiosAntes = this.histCambiosAntes.concat(elemento.esFuncion.toString());
            this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.esFuncion.toString());
            this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_esfuncion_actualizado");
            this.histCambiosAntes = this.histCambiosAntes.concat(',');
            this.histCambiosDespues = this.histCambiosDespues.concat(',');
            this.histCambiosCampos = this.histCambiosCampos.concat(',');
          } 
          if (elemento.funcion != elementoNuevo.funcion) {
            this.histCambiosAntes = this.histCambiosAntes.concat(elemento.funcion.toString());
            this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.funcion.toString());
            this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_funcion_actualizado");
            this.histCambiosAntes = this.histCambiosAntes.concat(',');
            this.histCambiosDespues = this.histCambiosDespues.concat(',');
            this.histCambiosCampos = this.histCambiosCampos.concat(',');
          } 

          if (elemento.ruta != elementoNuevo.ruta) {
            this.histCambiosAntes = this.histCambiosAntes.concat(elemento.ruta.toString());
            this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.ruta.toString());
            this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_ruta_actualizado");
            this.histCambiosAntes = this.histCambiosAntes.concat(',');
            this.histCambiosDespues = this.histCambiosDespues.concat(',');
            this.histCambiosCampos = this.histCambiosCampos.concat(',');
          } 

          if (elemento.imagen != elementoNuevo.imagen) {
            this.histCambiosAntes = this.histCambiosAntes.concat(elemento.imagen.toString());
            this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.imagen.toString());
            this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_imagen_actualizado");
            this.histCambiosAntes = this.histCambiosAntes.concat(',');
            this.histCambiosDespues = this.histCambiosDespues.concat(',');
            this.histCambiosCampos = this.histCambiosCampos.concat(',');
          } 

           //Cuando comiencen a usarse ambos serán null al comienzo, por lo que los editamos
           if (elemento.critico == null || elemento.critico == undefined) 
           elemento.critico = false;
         if (elementoNuevo.critico == null || elementoNuevo.critico == undefined) 
           elementoNuevo.critico = false;

         if (elemento.critico != elementoNuevo.critico) {
           this.histCambiosAntes = this.histCambiosAntes.concat(elemento.critico.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.critico.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_critico_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 
         //Cuando comiencen a usarse ambos serán null al comienzo, por lo que los editamos
        /*  if (elemento.porDefecto == null || elemento.porDefecto == undefined) 
           elemento.porDefecto = false;
         if (elementoNuevo.porDefecto == null || elementoNuevo.porDefecto == undefined) 
           elementoNuevo.porDefecto = false;

         if (elemento.porDefecto != elementoNuevo.porDefecto) {
           this.histCambiosAntes = this.histCambiosAntes.concat(elemento.porDefecto.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.porDefecto.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_porDefecto_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 
         //Cuando comiencen a usarse ambos serán null al comienzo, por lo que los editamos
         if (elemento.cantidad == null || elemento.cantidad == undefined) 
           elemento.cantidad = -1;
         if (elementoNuevo.cantidad == null || elementoNuevo.cantidad == undefined) 
           elementoNuevo.cantidad = -1;

         if (elemento.cantidad != elementoNuevo.cantidad) {
           this.histCambiosAntes = this.histCambiosAntes.concat(elemento.cantidad.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.cantidad.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_cantidad_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 
         //Cuando comiencen a usarse ambos serán null al comienzo, por lo que los editamos
         if (elemento.procesar == null || elemento.procesar == undefined) 
           elemento.procesar = -1;
         if (elementoNuevo.procesar == null || elementoNuevo.procesar == undefined) 
           elementoNuevo.procesar = -1;

         if (elemento.procesar != elementoNuevo.procesar) {
           this.histCambiosAntes = this.histCambiosAntes.concat(elemento.procesar.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.procesar.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_procesar_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 
         //Cuando comiencen a usarse ambos serán null al comienzo, por lo que los editamos
         if (elemento.resto == null || elemento.resto == undefined) 
           elemento.resto = -1;
         if (elementoNuevo.resto == null || elementoNuevo.resto == undefined) 
           elementoNuevo.resto = -1;

         if (elemento.resto != elementoNuevo.resto) {
           this.histCambiosAntes = this.histCambiosAntes.concat(elemento.resto.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.resto.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_resto_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         }  */

         //Cuando comiencen a usarse ambos serán null al comienzo, por lo que los editamos
         /* if (elementoNuevo.descripcion == null || elementoNuevo.descripcion == undefined) 
           elementoNuevo.descripcion = "";

         if (elemento.descripcion != elementoNuevo.descripcion) {
           this.histCambiosAntes = this.histCambiosAntes.concat(elemento.descripcion.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.descripcion.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_descripcion_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         }  */
         //Cuando comiencen a usarse ambos serán null al comienzo, por lo que los editamos
         if (elemento.tipoTolerancia == null || elemento.tipoTolerancia == undefined)
          elemento.tipoTolerancia = -1;
        if (elementoNuevo.tipoTolerancia == null || elementoNuevo.tipoTolerancia == undefined)
          elementoNuevo.tipoTolerancia = -1;

         if (elemento.tipoTolerancia != elementoNuevo.tipoTolerancia) {
           this.histCambiosAntes = this.histCambiosAntes.concat(elemento.tipoTolerancia.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.tipoTolerancia.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_tipoTolerancia_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 
         if (elemento.tipoControl == null || elemento.tipoControl == undefined)
          elemento.tipoControl = { nombre: "", id: -1 };

        if (elementoNuevo.tipoControl == null || elementoNuevo.tipoControl == undefined)
          elementoNuevo.tipoControl = { nombre: "", id: -1 };

         if (elemento.tipoControl != elementoNuevo.tipoControl) {
           this.histCambiosAntes = this.histCambiosAntes.concat(elemento.tipoControl.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.tipoControl.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_tipoControl_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 

         var auxi2;
         if(elemento.frecuencia1_cant == undefined || elemento.frecuencia1_cant == null){
           auxi2 = "sinAsignar";
         }else{
           auxi2 = elemento.frecuencia1_cant;
         }
         if(elementoNuevo.frecuencia1_cant == undefined || elementoNuevo.frecuencia1_cant == null){
           elementoNuevo.frecuencia1_cant = "sinAsignar";
         }

         if (auxi2 != elementoNuevo.frecuencia1_cant) {
           this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia1_cant.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_frecuencia1__cantidad2_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 

         var auxi2;
         if(elemento.frecuencia1_porcen1 == undefined || elemento.frecuencia1_porcen1 == null){
           auxi2 = "sinAsignar";
         }else{
           auxi2 = elemento.frecuencia1_porcen1;
         }

         var auxi;
         if(elemento.frecuencia1_porcen2 == undefined || elemento.frecuencia1_porcen2 == null){
           auxi = "sinAsignar";
         }else{
           auxi = elemento.frecuencia1_porcen2;
         }

         if(elementoNuevo.frecuencia1_porcen1 == undefined || elementoNuevo.frecuencia1_porcen1 == null){
           elementoNuevo.frecuencia1_porcen1 = "sinAsignar";
         }

         if(elementoNuevo.frecuencia1_porcen2 == undefined || elementoNuevo.frecuencia1_porcen2 == null){
           elementoNuevo.frecuencia1_porcen2 = "sinAsignar";
         }

         if (auxi2 != elementoNuevo.frecuencia1_porcen1 || auxi != elementoNuevo.frecuencia1_porcen2) {
           this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString() + "/" + auxi.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia1_porcen1.toString() + "/" + elementoNuevo.frecuencia1_porcen1.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_frecuencia1__porcentaje_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 

         var auxi2;
         if(elemento.frecuencia2_cant == undefined || elemento.frecuencia2_cant == null){
           auxi2 = "sinAsignar";
         }else{
           auxi2 = elemento.frecuencia2_cant;
         }

         if(elementoNuevo.frecuencia2_cant == undefined || elementoNuevo.frecuencia2_cant == null){
           elementoNuevo.frecuencia2_cant = "sinAsignar";
         }

         if (auxi2 != elementoNuevo.frecuencia2_cant) {
           this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia2_cant.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_frecuencia2__cantidad2_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 

         var auxi2;
         if(elemento.frecuencia2_porcen1 == undefined || elemento.frecuencia2_porcen1 == null){
           auxi2 = "sinAsignar";
         }else{
           auxi2 = elemento.frecuencia2_porcen1;
         }

         var auxi;
         if(elemento.frecuencia2_porcen2 == undefined || elemento.frecuencia2_porcen2 == null){
           auxi = "sinAsignar";
         }else{
           auxi = elemento.frecuencia2_porcen2;
         }

         if(elementoNuevo.frecuencia2_porcen1 == undefined || elementoNuevo.frecuencia2_porcen1 == null){
           elementoNuevo.frecuencia2_porcen1 = "sinAsignar";
         }

         if(elementoNuevo.frecuencia2_porcen2 == undefined || elementoNuevo.frecuencia2_porcen2 == null){
           elementoNuevo.frecuencia2_porcen2 = "sinAsignar";
         }

         if (auxi2 != elementoNuevo.frecuencia2_porcen1 || auxi != elementoNuevo.frecuencia2_porcen2) {
           this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString() + "/" + auxi.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia2_porcen1.toString() + "/" + elementoNuevo.frecuencia2_porcen2.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_frecuencia2__porcentaje_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 

         var auxi2;
         if(elemento.frecuencia3_cant == undefined || elemento.frecuencia3_cant == null){
           auxi2 = "sinAsignar";
         }else{
           auxi2 = elemento.frecuencia3_cant;
         }

         if(elementoNuevo.frecuencia3_cant == undefined || elementoNuevo.frecuencia3_cant == null){
           elementoNuevo.frecuencia3_cant = "sinAsignar";
         }


         if (auxi2 != elementoNuevo.frecuencia3_cant) {
           this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia3_cant.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_frecuencia3__cantidad2_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 

         var auxi2;
         if(elemento.frecuencia3_porcen1 == undefined || elemento.frecuencia3_porcen1 == null){
           auxi2 = "sinAsignar";
         }else{
           auxi2 = elemento.frecuencia3_porcen1;
         }

         var auxi;
         if(elemento.frecuencia3_porcen2 == undefined || elemento.frecuencia3_porcen2 == null){
           auxi = "sinAsignar";
         }else{
           auxi = elemento.frecuencia3_porcen2;
         }

         if(elementoNuevo.frecuencia3_porcen1 == undefined || elementoNuevo.frecuencia3_porcen1 == null){
           elementoNuevo.frecuencia3_porcen1 = "sinAsignar";
         }
         if(elementoNuevo.frecuencia3_porcen2 == undefined || elementoNuevo.frecuencia3_porcen2 == null){
           elementoNuevo.frecuencia3_porcen2 = "sinAsignar";
         }

         if (auxi2 != elementoNuevo.frecuencia3_porcen1 || auxi != elementoNuevo.frecuencia3_porcen2) {
           this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString() + "/" + auxi.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia3_porcen1.toString() + "/" + elementoNuevo.frecuencia3_porcen2.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_frecuencia3__porcentaje_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 

         var auxi2;
         if(elemento.frecuencia4_cant == undefined || elemento.frecuencia4_cant == null){
           auxi2 = "sinAsignar";
         }else{
           auxi2 = elemento.frecuencia4_cant;
         }

         if(elementoNuevo.frecuencia4_cant == undefined || elementoNuevo.frecuencia4_cant == null){
           elementoNuevo.frecuencia4_cant = "sinAsignar";
         }

         if (auxi2 != elementoNuevo.frecuencia4_cant) {
           this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia4_cant.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_frecuencia4__cantidad2_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 

         var auxi2;
         if(elemento.frecuencia4_porcen1 == undefined || elemento.frecuencia4_porcen1 == null){
           auxi2 = "sinAsignar";
         }else{
           auxi2 = elemento.frecuencia4_porcen1;
         }

         var auxi;
         if(elemento.frecuencia4_porcen2 == undefined || elemento.frecuencia4_porcen2 == null){
           auxi = "sinAsignar";
         }else{
           auxi = elemento.frecuencia4_porcen2;
         }

         if(elementoNuevo.frecuencia4_porcen1 == undefined || elementoNuevo.frecuencia4_porcen1 == null){
           elementoNuevo.frecuencia4_porcen1 = "sinAsignar";
         }

         if(elementoNuevo.frecuencia4_porcen2 == undefined || elementoNuevo.frecuencia4_porcen2 == null){
           elementoNuevo.frecuencia4_porcen2 = "sinAsignar";
         }

         if (auxi2 != elementoNuevo.frecuencia4_porcen1 || auxi != elementoNuevo.frecuencia4_porcen2) {
           this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString() + "/" + auxi.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia4_porcen1.toString() + "/" + elementoNuevo.frecuencia4_porcen2.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_frecuencia4__porcentaje_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 

         var auxi2;
         if(elemento.frecuencia5_cant == undefined || elemento.frecuencia5_cant == null){
           auxi2 = "sinAsignar";
         }else{
           auxi2 = elemento.frecuencia5_cant;
         }

         if(elementoNuevo.frecuencia5_cant == undefined || elementoNuevo.frecuencia5_cant == null){
           elementoNuevo.frecuencia5_cant = "sinAsignar";
         }

         if (auxi2 != elementoNuevo.frecuencia5_cant) {
           this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia5_cant.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_frecuencia5__cantidad2_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 

         var auxi2;
         if(elemento.frecuencia5_porcen1 == undefined || elemento.frecuencia5_porcen1 == null){
           auxi2 = "sinAsignar";
         }else{
           auxi2 = elemento.frecuencia5_porcen1;
         }

         var auxi;
         if(elemento.frecuencia5_porcen2 == undefined || elemento.frecuencia5_porcen2 == null){
           auxi = "sinAsignar";
         }else{
           auxi = elemento.frecuencia5_porcen2;
         }

         if(elementoNuevo.frecuencia5_porcen1 == undefined || elementoNuevo.frecuencia5_porcen1 == null){
           elementoNuevo.frecuencia5_porcen1 = "sinAsignar";
         }

         if(elementoNuevo.frecuencia5_porcen2 == undefined || elementoNuevo.frecuencia5_porcen2 == null){
           elementoNuevo.frecuencia5_porcen2 = "sinAsignar";
         }

         if ((auxi2 != elementoNuevo.frecuencia5_porcen1) || (auxi != elementoNuevo.frecuencia5_porcen2)) {
           this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString() + "/" + auxi.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia5_porcen1.toString() + "/" + elementoNuevo.frecuencia5_porcen2.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_frecuencia5__porcentaje_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 

         var auxi2;
         if(elemento.resto_cant == undefined || elemento.resto_cant == null){
           auxi2 = "sinAsignar";
         }else{
           auxi2 = elemento.resto_cant;
         }

         if(elementoNuevo.resto_cant == undefined || elementoNuevo.resto_cant == null){
           elementoNuevo.resto_cant = "sinAsignar";
         }

         if (auxi2 != elementoNuevo.resto_cant) {
           this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.resto_cant.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_resto__cantidad2_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 

         var auxi2;
         if(elemento.resto_porcen1 == undefined || elemento.resto_porcen1 == null){
           auxi2 = "sinAsignar";
         }else{
           auxi2 = elemento.resto_porcen1;
         }

         var auxi;
         if(elemento.resto_porcen2 == undefined || elemento.resto_porcen2 == null){
           auxi = "sinAsignar";
         }else{
           auxi = elemento.resto_porcen2;
         }

         if(elementoNuevo.resto_porcen1 == undefined || elementoNuevo.resto_porcen1 == null){
           elementoNuevo.resto_porcen1 = "sinAsignar";
         }

         if(elementoNuevo.resto_porcen2 == undefined || elementoNuevo.resto_porcen2 == null){
           elementoNuevo.resto_porcen2 = "sinAsignar";
         }

         if ((auxi2 != elementoNuevo.resto_porcen1) || (auxi != elementoNuevo.resto_porcen2)) {
           this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString() + "/" + auxi.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.resto_porcen1.toString() + "/" + elementoNuevo.resto_porcen2.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_resto_porcentaje_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 

         var auxi2;
         if(elemento.cambioOperario_cant == undefined || elemento.cambioOperario_cant == null){
           auxi2 = "sinAsignar";
         }else{
           auxi2 = elemento.cambioOperario_cant;
         }

         if(elementoNuevo.cambioOperario_cant == undefined || elementoNuevo.cambioOperario_cant == null){
           elementoNuevo.cambioOperario_cant = "sinAsignar";
         }

         if (auxi2 != elementoNuevo.cambioOperario_cant) {
           this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.cambioOperario_cant.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_cambioCritico__cantidad2_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 

         var auxi2;
         if(elemento.cambioOperario_porcen1 == undefined || elemento.cambioOperario_porcen1 == null){
           auxi2 = "sinAsignar";
         }else{
           auxi2 = elemento.cambioOperario_porcen1;
         }

         var auxi;
         if(elemento.cambioOperario_porcen2 == undefined || elemento.cambioOperario_porcen2 == null){
           auxi = "sinAsignar";
         }else{
           auxi = elemento.cambioOperario_porcen2;
         }

         if(elementoNuevo.cambioOperario_porcen1 == undefined || elementoNuevo.cambioOperario_porcen1 == null){
           elementoNuevo.cambioOperario_porcen1 = "sinAsignar";
         }

         if(elementoNuevo.cambioOperario_porcen2 == undefined || elementoNuevo.cambioOperario_porcen2 == null){
           elementoNuevo.cambioOperario_porcen2 = "sinAsignar";
         }

         if ((auxi2 != elementoNuevo.cambioOperario_porcen1) || (auxi != elementoNuevo.cambioOperario_porcen2)) {
           this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString() + "/" + auxi.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.cambioOperario_porcen1.toString() + "/" + elementoNuevo.cambioOperario_porcen2.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_cambioCritico_porcentaje_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 
      });
    }
    var filename = "";

    this.form.value.tipooperacion = this.tipooperacion;
    if ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != "")) {
      try {
        var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
        filename = this.form.value.archivo[0].name;
      } catch (error) {
        var archivoBase64: any = "";
      }

    } else {
      var archivoBase64: any = "";
    }
    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.guardando");
    }
    this.form.value.id = this.idOperacion;
    this.form.value.idRuta = this.idRuta;
    this.form.value.calidad = this.calidadActivo;

     //Necesitamos hacer estos cambios aquí por el historico de cambios
    if(this.herramientas.length>0){
      //Agregamos un dato al inicio del array que luego no se usará, solo es para al parsearlo a json que sepa qué formato van a tener los datos
      //(que no tome los decimals por ints)
      //Como no se puede crear una copia del elemento del array, pues esto modifica también el propio elemento aparte del copiado,
      //lo creamos a mano
      var auxiElement = {
        angulo: 0.1,
        coste: 10.1,
        //descripcion: "",
        diametro: 15.1,
        errorTiempoEstimado: false,
        errorTiempos: false,
        fiabilidad: 0.1,
        fiabilidadConsumible: 20.1,
        id: 5,
        idConsumible: 8,
        idConsumibles_fabricantes: 12,
        idConsumibles_tipos: 2,
        idERP: '',
        idNuevo: 5,
        idOperacion: 2029,
        longitud: 10.1,
        nombre: "Broca 15",
        numContenido: 5,
        orden: 1,
        vc: 0.1,
        f: 0.1,
        ap_ini: 0.1,
        ap: 0.1,
        d_min: 0.1,
        porcenUso: 0.1,
        porcentajeUso: 0.1,
        salto: 0.1,
        tiempoUso: 480,
        tieneNserie: true,
        vidaUtil: 3600 
      }
      this.herramientas.unshift(auxiElement); //Lo agregamos al inicio del array 
    }

    if(this.programas.length>0){
      //Agregamos un dato al inicio del array que luego no se usará, solo es para al parsearlo a json que sepa qué formato van a tener los datos
      //(que no tome los decimals por ints)
      //Como no se puede crear una copia del elemento del array, pues esto modifica también el propio elemento aparte del copiado,
      //lo creamos a mano
      var auxiElement3 = {
        version: 1,
        fecha: '14/11/2023',
        tipo: 1,
        pieza: 'PIEZA 1',
        operacion: '',
        cliente: '',
        of: 234,
        operario: 'Fran Perez',
        maquina: 'Reductora',
        tMedio: 535,
        tMejor: 746,
        cantidad: 3,
        percenok: 65.3
      }
      this.programas.unshift(auxiElement3); //Lo agregamos al inicio del array 
    }


    var valoresNoCompletados= false;
    if(this.valoresFinales.length>0){
      for(var n=0; n<this.valoresFinales.length;n++)
      {
        var element = this.valoresFinales[n];
        if(element.tipoTolerancia==-1){
          element.tipoTolerancia = {nombre:'', id:-1};
        }
        if(element.tipoControl==-1){
          element.tipoControl = {nombre:'', id:-1};
        }
        //Y ahora comprobamos los obligatorios
        if (element.id != -1 && ((element.nombre == null || element.nombre == "") || (element.tipoControl!=undefined && element.tipoControl.id == -1) ||
        (element.datosTolerancia.length > 0 && 
          (element.tipoTolerancia!=undefined && element.tipoTolerancia.id == -1)))) {
          valoresNoCompletados = true;
          //break;
        }

        if(element.funcion!=null && element.funcion!="" && element.funcion!=undefined){
          element.esFuncion = true;
        }else{
          element.esFuncion = false;
        }

        //Y corregimos los sinAsignar
        if(element.frecuencia1_cant=="sinAsignar")
          element.frecuencia1_cant = null;
        if(element.frecuencia1_porcen1=="sinAsignar")
          element.frecuencia1_porcen1 = null;
        if (element.frecuencia1_porcen2 == "sinAsignar")
          element.frecuencia1_porcen2 = null;

        if (element.frecuencia2_cant == "sinAsignar")
          element.frecuencia2_cant = null;
        if (element.frecuencia2_porcen1 == "sinAsignar")
          element.frecuencia2_porcen1 = null;
        if (element.frecuencia2_porcen2 == "sinAsignar")
          element.frecuencia2_porcen2 = null;

        if (element.frecuencia3_cant == "sinAsignar")
          element.frecuencia3_cant = null;
        if (element.frecuencia3_porcen1 == "sinAsignar")
          element.frecuencia3_porcen1 = null;
        if (element.frecuencia3_porcen2 == "sinAsignar")
          element.frecuencia3_porcen2 = null;

        if (element.frecuencia4_cant == "sinAsignar")
          element.frecuencia4_cant = null;
        if (element.frecuencia4_porcen1 == "sinAsignar")
          element.frecuencia4_porcen1 = null;
        if (element.frecuencia4_porcen2 == "sinAsignar")
          element.frecuencia4_porcen2 = null;
        
        if (element.frecuencia5_cant == "sinAsignar")
          element.frecuencia5_cant = null;
        if (element.frecuencia5_porcen1 == "sinAsignar")
          element.frecuencia5_porcen1 = null;
        if (element.frecuencia5_porcen2 == "sinAsignar")
          element.frecuencia5_porcen2 = null;

        if (element.resto_cant == "sinAsignar")
          element.resto_cant = null;
        if (element.resto_porcen1 == "sinAsignar")
          element.resto_porcen1 = null;
        if (element.resto_porcen2 == "sinAsignar")
          element.resto_porcen2 = null;

        if (element.cambioOperario_cant == "sinAsignar")
          element.cambioOperario_cant = null;
        if (element.cambioOperario_porcen1 == "sinAsignar")
          element.cambioOperario_porcen1 = null;
        if (element.cambioOperario_porcen2 == "sinAsignar")
          element.cambioOperario_porcen2 = null;

      }
      if(valoresNoCompletados){
        this.alertService.error(this.translate.instant('valoresObligatorios'));
        this.modalReferenceloading.close();
        return;
      }
      //Necesitamos crear un valor auxiliar que insertaremos al inicio y que especificará qué datos son decimals, igual que sucede en herramientas
      var auxiElement2 = { //le damos valores aleatorios, pues no lo vamos a procesar
        activo: false,
        conLimite: false,
        eje: "A",
        esFuncion: true,
        funcion: "",
        id: -1,
        idNuevo: -1,
        idOperacion: 2029,
        imagen: false,
        maximo: 0.1,
        minimo: 0.1,
        nombre: "",
        orden: 1,
        //referencia: "Ref",
        referenciada: "false",
        toleranciaMax: 0.1,
        toleranciaMin: 0.1,
        unidades: "mm",
        varmaquina: "",
        //visible: true,
        vnominal: 0.1,
        critico: false,
        porDefecto: true,
        cantidad: 0,
        procesar: 0,
        resto: 0,
        //descripcion: "des",
        tipoTolerancia: {nombre: "", id: -1},
        tipoControl: {nombre: "", id: -1},
        datosTolerancia: [],
        frecuencia1_cant: 1,
        frecuencia1_porcen1: 1,
        frecuencia1_porcen2: 1,
        frecuencia2_cant:1,
        frecuencia2_porcen1:1,
        frecuencia2_porcen2:1,
        frecuencia3_cant:1,
        frecuencia3_porcen1:1,
        frecuencia3_porcen2:1,
        frecuencia4_cant:1,
        frecuencia4_porcen1:1,
        frecuencia4_porcen2:1,
        frecuencia5_cant:1,
        frecuencia5_porcen1:1,
        frecuencia5_porcen2:1,
        resto_cant:1,
        resto_porcen1:1,
        resto_porcen2:1,
        cambioOperario_cant:1,
        cambioOperario_porcen1:1,
        cambioOperario_porcen2:1,
        ruta: "",
        idUtillaje: 1
      }
      this.valoresFinales.unshift(auxiElement2); //Lo agregamos al inicio del array
      this.valoresFinales.forEach(element => {
        element.datosTolerancia = ""; //Así evitamos errores al guardar, ya que no necesitamos este elemento al guardar
        element.actualizarTodos = option;
      });
    }

    if (this.ddControladorCotasNoCriticasChanged) this.form.value.idControlarCotasNoCriticas= this.form.value.idControlarCotasNoCriticas.id;
    if (this.ddControladorCotasCriticasChanged) this.form.value.idControlarCotasCriticas= this.form.value.idControlarCotasCriticas.id;
    if (this.ddObservacionPiezasChanged) this.form.value.idObservacionPiezas= this.form.value.idObservacionPiezas.id;
    this.form.value.todos = option;
    this.operacionesPredefinidasService.update(this.form.value, filename, archivoBase64, idsmaquinas, estadosmaquinas, this.histCambiosAntes, this.histCambiosDespues, this.histCambiosCampos)
      .subscribe((result) => {
        this.herramientas.forEach(element => {
          console.log(element.tiempoUso.toString().includes(':'));
          if(element.tiempoUso.toString().includes(':')){
            element.tiempoUso = this.HMSToSeconds(element.tiempoUso);
            console.log(element.tiempoUso);
          }
        });
        this.programas.forEach(element => {
          if (element.tMedio != undefined){
            console.log(element.tMedio.toString().includes(':'));
            if(element.tMedio.toString().includes(':')){
              element.tMedio = this.HMSToSeconds(element.tMedio);
              console.log(element.tMedio);
            }
            if(element.tMejor.toString().includes(':')){
              element.tMejor = this.HMSToSeconds(element.tMejor);
              console.log(element.tMejor);
            }
          }
        });
        this.herramientas.todos = option;
        this.operacionesPredefinidasService.update_herramientas(this.herramientas).subscribe();
        this.operacionesPredefinidasService.update_valores(this.valoresFinales).subscribe();
        this.operacionesPredefinidasService.update_programas(this.programas).subscribe();
        this.modalReferenceloading.close();
        if (result.error == false) {
          this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
          var idgenerado = result.id;
          if (this.idRuta != undefined || this.idRuta > 0) {
            this.Atras();
          }
          else {
            this.router.navigate(['operacionespredefinidas']);
          }
        }
        else {
          this.alertService.error(this.translate.instant('error'));
        }
        this.histCambiosAntes = "";
        this.histCambiosDespues = "";
        this.histCambiosCampos = "";
      });
    this.form.value.archivo = null;
    this.Atras();
  }

  public onCalidadValueChange(value: any): void{
    if (this.calidadActivo) {
      this.calidadActivo = false;
    } else {
      this.calidadActivo = true;
      //Hay que marcar la configuracion
      this.configuracionService.get_configuracion_calidad().subscribe(result => {
        var dtConfiguracionCalidad = result[0];  
        this.form.controls['resetearFrecuencia'].setValue(dtConfiguracionCalidad.resetearFrecuencia);
        this.form.controls['idControlarCotasNoCriticas'].setValue(dtConfiguracionCalidad.idControlarCotasNoCriticas);
        this.form.controls['idControlarCotasCriticas'].setValue(dtConfiguracionCalidad.idControlarCotasCriticas);
        this.form.controls['primerasPiezasNum'].setValue(dtConfiguracionCalidad.primerasPiezasNum);
        this.form.controls['idObservacionPiezas'].setValue(dtConfiguracionCalidad.idObservacionPiezas);
        this.form.controls['mostrarValoresNoOKOperario'].setValue(dtConfiguracionCalidad.mostrarValoresNoOKOperario);
        this.form.controls['inputarSoloDecimales'].setValue(dtConfiguracionCalidad.inputarSoloDecimales);
        this.ddControladorCotasNoCriticasChanged=true;
        this.ddControladorCotasCriticasChanged=true;
        this.ddObservacionPiezasChanged=true;
        this.form.controls['tipooperacion'].setValue(this.tipooperacionCalidadLista[0]);
        this.tipooperacion = 1; //Para que ponga una por defecto
      });
    }
    this.tabstrip.selectTab(0);
  }

 /*  async nuevoPredefaLinea() { //REVISAR NO SE UTILIZA


    this.form.value.tipooperacion = this.tipooperacion;
    var filename = "";

    if ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != "")) {
      var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
      filename = this.form.value.archivo[0].name;
    } else {
      var archivoBase64: any = "";
    }

    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.guardando");
    }
    if (this.idRuta > 0) {
      this.operacionesPredefinidasService.enlazar(this.form.value, filename, archivoBase64, this.idRuta, this.idParte, this.idPieza)
        .subscribe((result) => {
          this.modalReferenceloading.close();
          {

            if (result.error == false) {
              this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
              var idgenerado = result.id;
              this.router.navigate(['piezas/editar/' + this.idOF + "/" + this.idPieza + "/" + this.idParte]);
            }
            else {
              this.alertService.error(this.translate.instant('error'));
            }
          }
        }
        );

    } else {
      this.operacionesPredefinidasService.create(this.form.value, filename, archivoBase64, idsmaquinas, estadosmaquinas, this.histCambiosAntes, this.histCambiosDespues, this.histCambiosCampos)
        .subscribe((result) => {
          this.modalReferenceloading.close();
          {
            if (result.error == false) {
              this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
              var idgenerado = result.id;
              this.router.navigate(['operacionespredefinidas/editar/' + idgenerado]);
            }
            else {
              this.alertService.error(this.translate.instant('error'));
            }
          }
        }
        );
    }
  } */
 /*  async nuevoPredef() { //REVISAR NO SE UTILIZA

    this.form.value.tipooperacion = this.tipooperacion;
    var filename = "";

    if ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != "")) {
      var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
      filename = this.form.value.archivo[0].name;
    } else {
      var archivoBase64: any = "";
    }

    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.guardando");
    }
    if (this.idRuta > 0) {
      this.operacionesPredefinidasService.createruta(this.form.value, filename, archivoBase64, this.idRuta, this.idParte, this.idPieza)
        .subscribe((result) => {
          this.modalReferenceloading.close();
          {

            if (result.error == false) {
              this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
              var idgenerado = result.id;
              this.router.navigate(['piezaspredefinidas/editar/' + this.idParte]);
            }
            else {
              this.alertService.error(this.translate.instant('error'));
            }
          }
        }
        );

    } else {
      this.operacionesPredefinidasService.create(this.form.value, filename, archivoBase64)
        .subscribe((result) => {
          this.modalReferenceloading.close();
          {

            if (result.error == false) {
              this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
              var idgenerado = result.id;
              this.router.navigate(['operacionespredefinidas/editar/' + idgenerado]);
            }
            else {
              this.alertService.error(this.translate.instant('error'));
            }
          }
        }
        );
    }
  } */


  async nuevo() {
    this.modalReference.close();
    var idsmaquinas = "";
    var estadosmaquinas = "";
    this.estadoMaquinas.forEach((element, index) => {
      if (element != "" && element != 1) {
        idsmaquinas = idsmaquinas + "," + this.idMaquinas[index];
        estadosmaquinas = estadosmaquinas + "," + element;
      }
    }); 
    this.form.value.tipooperacion = this.tipooperacion;
    this.form.value.calidad = this.calidadActivo;
    var filename = "";

    if ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != "")) {
      var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
      filename = this.form.value.archivo[0].name;
    } else {
      var archivoBase64: any = "";
    }

    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.guardando");
    }

     //Necesitamos hacer estos cambios aquí por el historico de cambios
    if(this.herramientas.length>0){
      //Agregamos un dato al inicio del array que luego no se usará, solo es para al parsearlo a json que sepa qué formato van a tener los datos
      //(que no tome los decimals por ints)
      //Como no se puede crear una copia del elemento del array, pues esto modifica también el propio elemento aparte del copiado,
      //lo creamos a mano
      var auxiElement = {
        angulo: 0.1,
        coste: 10.1,
        //descripcion: "",
        diametro: 15.1,
        errorTiempoEstimado: false,
        errorTiempos: false,
        fiabilidad: 0.1,
        fiabilidadConsumible: 20.1,
        id: 5,
        idConsumible: 8,
        idConsumibles_fabricantes: 12,
        idConsumibles_tipos: 2,
        idERP: "",
        idNuevo: 5,
        idOperacion: 2029,
        longitud: 10.1,
        nombre: "Broca 15",
        numContenido: 5,
        orden: 1,
        porcenUso: 0.1,
        porcentajeUso: 0.1,
        salto: 0.1,
        tiempoUso: 480,
        tieneNserie: true,
        vidaUtil: 3600
      }
      this.herramientas.unshift(auxiElement); //Lo agregamos al inicio del array 
    }



    if(this.programas.length>0){
      //Agregamos un dato al inicio del array que luego no se usará, solo es para al parsearlo a json que sepa qué formato van a tener los datos
      //(que no tome los decimals por ints)
      //Como no se puede crear una copia del elemento del array, pues esto modifica también el propio elemento aparte del copiado,
      //lo creamos a mano
      var auxiElement3 = {
        nombrePrograma: "0123",
        id: 1,
        version: 1,
        fechaVersion : '2023-11-14 13:33:21.973',
        tipo: 1,
        fechaIni : '2023-11-14 13:33:21.973',
        fechaFin : '2023-11-14 13:33:21.973',
        treal : 433.16,
        nombreMaquina : 'TORNO HEIDENHAIN',
        operario: 'XABI PRIETO',
        operarioSiglas: 'XP',
        operarioColor: '#fa5600',
        cliente: 'ULMA',
        numeroOF: 12345,
        pieza: 'PIEZA 1',
        parte: '',
        nombreOperacion : 'OP-1',
        ordenVersiones: 2,
        tMedio: 433.16,
        tMejor: 388.96,
        cantidad: 20,
        rutaPrograma: 'C:\IEEP\IEEP\IEEP',
        identificador : 'X0243&327&42',
        observaciones: '-',
        idControl: 1,
        idPiezaPredefinida: 90,
        idOperacion: 400,
        tiempoEstimadoEjecucion: 1000,
        porcenCalidad: 90.4
        // errorTiempoEstimado: false,
        // errorTiempos: false,
      }
      this.programas.unshift(auxiElement3); //Lo agregamos al inicio del array 
    }

    var valoresNoCompletados= false;
    if(this.valoresFinales.length>0){
      for(var n=0; n<this.valoresFinales.length;n++)
      {
        var element = this.valoresFinales[n];
        //Y ahora comprobamos los obligatorios
        if((element.nombre== null || element.nombre=="") || (element.tipoControl.id==-1) || 
        (element.datosTolerancia.length>0 && element.tipoTolerancia.id==-1)){
          valoresNoCompletados = true;
          //break;
        }

        if(element.funcion!= null && element.funcion!="" && element.funcion!=undefined){
          element.esFuncion = true;
        }
        else{
          element.esFuncion=false;
        }

        //Y corregimos los sinAsignar
        if(element.frecuencia1_cant=="sinAsignar")
          element.frecuencia1_cant = null;
        if(element.frecuencia1_porcen1=="sinAsignar")
          element.frecuencia1_porcen1 = null;
        if (element.frecuencia1_porcen2 == "sinAsignar")
          element.frecuencia1_porcen2 = null;

        if (element.frecuencia2_cant == "sinAsignar")
          element.frecuencia2_cant = null;
        if (element.frecuencia2_porcen1 == "sinAsignar")
          element.frecuencia2_porcen1 = null;
        if (element.frecuencia2_porcen2 == "sinAsignar")
          element.frecuencia2_porcen2 = null;

        if (element.frecuencia3_cant == "sinAsignar")
          element.frecuencia3_cant = null;
        if (element.frecuencia3_porcen1 == "sinAsignar")
          element.frecuencia3_porcen1 = null;
        if (element.frecuencia3_porcen2 == "sinAsignar")
          element.frecuencia3_porcen2 = null;

        if (element.frecuencia4_cant == "sinAsignar")
          element.frecuencia4_cant = null;
        if (element.frecuencia4_porcen1 == "sinAsignar")
          element.frecuencia4_porcen1 = null;
        if (element.frecuencia4_porcen2 == "sinAsignar")
          element.frecuencia4_porcen2 = null;
        
        if (element.frecuencia5_cant == "sinAsignar")
          element.frecuencia5_cant = null;
        if (element.frecuencia5_porcen1 == "sinAsignar")
          element.frecuencia5_porcen1 = null;
        if (element.frecuencia5_porcen2 == "sinAsignar")
          element.frecuencia5_porcen2 = null;

        if (element.resto_cant == "sinAsignar")
          element.resto_cant = null;
        if (element.resto_porcen1 == "sinAsignar")
          element.resto_porcen1 = null;
        if (element.resto_porcen2 == "sinAsignar")
          element.resto_porcen2 = null;

        if (element.cambioOperario_cant == "sinAsignar")
          element.cambioOperario_cant = null;
        if (element.cambioOperario_porcen1 == "sinAsignar")
          element.cambioOperario_porcen1 = null;
        if (element.cambioOperario_porcen2 == "sinAsignar")
          element.cambioOperario_porcen2 = null;

      }
      if(valoresNoCompletados){
        this.alertService.error(this.translate.instant('valoresObligatorios'));
        this.modalReferenceloading.close();
        return;
      }
      //Necesitamos crear un valor auxiliar que insertaremos al inicio y que especificará qué datos son decimals, igual que sucede en herramientas
      var auxiElement2 = { //le damos valores aleatorios, pues no lo vamos a procesar
        activo: false,
        conLimite: false,
        eje: "A",
        esFuncion: true,
        funcion: "",
        id: -1,
        idNuevo: -1,
        idOperacion: 2029,
        imagen: false,
        maximo: 0.1,
        minimo: 0.1,
        nombre: "",
        orden: 1,
        //referencia: "Ref",
        referenciada: "false",
        toleranciaMax: 0.1,
        toleranciaMin: 0.1,
        unidades: "mm",
        varmaquina: "",
        //visible: true,
        vnominal: 0.1,
        critico: false,
        porDefecto: true,
        cantidad: 0,
        procesar: 0,
        resto: 0,
        //descripcion: "des",
        tipoTolerancia: -1,
        tipoControl: {nombre: "", id: -1},
        datosTolerancia: [],
        frecuencia1_cant: 1,
        frecuencia1_porcen1: 1,
        frecuencia1_porcen2: 1,
        frecuencia2_cant:1,
        frecuencia2_porcen1:1,
        frecuencia2_porcen2:1,
        frecuencia3_cant:1,
        frecuencia3_porcen1:1,
        frecuencia3_porcen2:1,
        frecuencia4_cant:1,
        frecuencia4_porcen1:1,
        frecuencia4_porcen2:1,
        frecuencia5_cant:1,
        frecuencia5_porcen1:1,
        frecuencia5_porcen2:1,
        resto_cant:1,
        resto_porcen1:1,
        resto_porcen2:1,
        cambioOperario_cant:1,
        cambioOperario_porcen1:1,
        cambioOperario_porcen2:1,
        ruta: "",
        idUtillaje: 1
      }
      this.valoresFinales.unshift(auxiElement2); //Lo agregamos al inicio del array
      this.valoresFinales.forEach(element => {
        element.datosTolerancia = ""; //Así evitamos errores al guardar, ya que no necesitamos este elemento al guardar
      });
    }

    if (this.ddControladorCotasNoCriticasChanged) this.form.value.idControlarCotasNoCriticas= this.form.value.idControlarCotasNoCriticas.id;
    if (this.ddControladorCotasCriticasChanged) this.form.value.idControlarCotasCriticas= this.form.value.idControlarCotasCriticas.id;
    if (this.ddObservacionPiezasChanged) this.form.value.idObservacionPiezas= this.form.value.idObservacionPiezas.id;

    if (this.idRuta > 0) {
      this.operacionesPredefinidasService.createruta(this.form.value, filename, archivoBase64, idsmaquinas,estadosmaquinas, this.idRuta, this.idParte, this.idPieza)
        .subscribe((result) => {
          this.modalReferenceloading.close();
          {
            if (result.error == false) {
              this.valoresFinales.forEach(element => {
                element.idOperacion = result.id;
              });
              this.herramientas.forEach(herramienta => {
                herramienta.idOperacion = result.id;
              });
              this.programas.forEach(programa => {
                programa.idOperacion = result.id;
              })
              this.operacionesPredefinidasService.update_herramientas(this.herramientas).subscribe();
              this.operacionesPredefinidasService.update_programas(this.programas).subscribe();
              this.operacionesPredefinidasService.update_valores(this.valoresFinales).subscribe();
              this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
              this.Atras();
            }
            else {
              this.alertService.error(this.translate.instant('error'));
            }
          }
        }
        );

    } else {
      this.operacionesPredefinidasService.create(this.form.value, filename, archivoBase64, idsmaquinas, estadosmaquinas, this.histCambiosAntes, this.histCambiosDespues, this.histCambiosCampos)
        .subscribe((result) => {
          this.modalReferenceloading.close();
          {
            if (result.error == false) {
              this.valoresFinales.forEach(element => {
                element.idOperacion = result.id;
              });
              this.herramientas.forEach(herramienta => {
                herramienta.idOperacion = result.id;
              });
              this.programas.forEach(programa => {
                programa.idOperacion = result.id;
              });
              this.operacionesPredefinidasService.update_herramientas(this.herramientas).subscribe();
              this.operacionesPredefinidasService.update_programas(this.programas).subscribe();
              this.operacionesPredefinidasService.update_valores(this.valoresFinales).subscribe();
              this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
              this.router.navigate(['operacionespredefinidas']);
            }
            else {
              this.alertService.error(this.translate.instant('error'));
            }
          }
        });
    }
  }

  // convenience getter for easy access to form fields
  get f() {   
    return this.form.controls; 
  }
  onClickDuplicarOperacion(contentloading, e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    this.form.value.id = this.idOperacion;
    this.form.value.idRuta = this.idRuta;
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.operacionesPredefinidasService.duplicar(this.form.value).subscribe((result) => {
      this.modalReferenceloading.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
        //this.Atras();
        // Despues de duplicar la operacion entrar en la operacion duplicada y dar la opcion de editarlo
        this.EditarDuplicado(result.id);
      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }
      if (result.error == false){
        
      }

    });
  }

  Atras() {
    if (this.idRuta != undefined || this.idRuta > 0) {
      if(this.idVista == "1"){
        this.router.navigate(['piezaspredefinidas/editar/' +  this.idParte]);
      }else if(this.idVista == "0"){
        this.router.navigate(['rutaspredefinidas/editar/' + this.idPieza + "/" + this.idParte + "/" + this.idRuta]);
      }
    }
    else {
      this.router.navigate(['operacionespredefinidas/']);
      }
  }

  EditarDuplicado(id){
    this.router.navigate(['operacionespredefinidas/editar/' + id]).then(() => {
      window.location.reload();
    });
  }



  //Codigo para popup guardar cambios en las pieza relacionadas a la pieza predefinida
  onClickGuardar(content, e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }






  public onContinuoValueChange(value: any): void {
    this.continuo = value;
  }
  public onIneficenciaValueChange(value: any): void {
    this.ineficencia = value;
  }
  public onCambiomaquinaValueChange(value: any): void {
    this.cambiomaquina = value;
  }

  private Eliminar(contentloading) {

    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.eliminando");
    }
    this.operacionesPredefinidasService.delete({ idoperacion: this.idOperacion }).subscribe(

      (data) => {
        this.modalReferenceloading.close();


        if (data.error == false) {
          this.operacionesPredefinidasService.updateOrdenes(this.idRuta).subscribe();
          this.alertService.success(this.translate.instant("ok"), { keepAfterRouteChange: true });

        }
        else {

          this.alertService.success(this.translate.instant("error"), { keepAfterRouteChange: true });
        }
        this.Atras();


      }

    );

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

  }

  cambioTipoOperacionCalidad(cambio) {
    if(cambio==undefined){
      //Si no encaja con ninguno, ponemos el que estuviese antes
      //this.tipooperacion no se cambia porque se mantiene 
      this.form.controls['tipooperacion'].setValue(this.tipooperacionCalidadLista.filter(x=>x.id==this.tipooperacion)[0]);
    }else{
      if (cambio.id==1) this.tipooperacion = 1;
      else if (cambio.id==2) this.tipooperacion = 2;
      else if (cambio.id==3) this.tipooperacion = 3;
      else if (cambio.id==4) this.tipooperacion = 4; 
    }
    
  }

 /*  public onInformativoValueChange(value: any): void {
    if(value)
      this.tipooperacion = 1;
    else{
      this.tipooperacion = 5; //no hay ninguna seleccionada, as� que ponemos un 5 que no es el de ninguna
    }
    this.valores = [];
    this.valoresFinales = [];

  }
  public onValorValueChange(value: any): void {
    if(value)
      this.tipooperacion = 2;
    else{
      this.tipooperacion = 5; //no hay ninguna seleccionada, as� que ponemos un 5 que no es el de ninguna
    }
    this.valores = [];
    this.valoresFinales = [];

  }
  public onConfirmacionValueChange(value: any): void {
    if(value)
      this.tipooperacion = 3;
    else{
      this.tipooperacion = 5; //no hay ninguna seleccionada, as� que ponemos un 5 que no es el de ninguna
    }
    this.valores = [];
    this.valoresFinales = [];

  }
  public onValormaquinaValueChange(value: any): void {
    if(value)
      this.tipooperacion = 4;
    else{
      this.tipooperacion = 5; //no hay ninguna seleccionada, as� que ponemos un 5 que no es el de ninguna
    }
    this.valores = [];
    this.valoresFinales = [];

  } */

  public tipo_operacion_valueChange(value: any): void {
    if (value == undefined) this.form.controls['tipo_operacion_id'].setValue(undefined);
    else this.form.controls['tipo_operacion_id'].setValue(value.id);
  }

  public myFiles = [];

  imageToShow: any;
  isImageLoading: boolean;

  createImageFromBase64(image) {
    let objectURL = 'data:image/jpeg;base64,' + image;
    this.imageToShow = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }
  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  public onRemove(ev): void {
    this.myFiles = [];
    this.imageToShow = "";
    this.logo = "";
  }
  //#endregion
  public async selectEventHandler(e: SelectEvent) {
    const that = this;
    var file = e.files[0];
    e.files.forEach((file) => {

      if (!file.validationErrors) {
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid
          };

          that.imagePreviews.unshift(image);
        };

        reader.readAsDataURL(file.rawFile);
      }
    });

    this.imagePreviews.forEach(element => { that.imageToShow = element.src; });
    this.imagePreviews = [];
  }

  private log(event: string): void {
    this.events.unshift(`${event}`);
  }
  //Herramientas
  /*public herramientas_onValueChange(value: any): void {
    this.herramientasComboSelected = "";

    for (let turno of value) {
      this.herramientasComboSelected += turno.id + ", ";
    }

    this.herramientasComboSelected = this.herramientasComboSelected.substring(0, this.herramientasComboSelected.length - 2);

    if (this.herramientasComboSelected == "") {
      this.herramientasComboSelected = "";
    }

  }*/
  selectionChange(e) {

    if (e == undefined || e.id == undefined) {

      this.isAddMode == true;
      return;
    }

    var id = e.id;


    var idparte = e.idparte;

    this.operacionesPredefinidasService.GetByIDSelectedCombo({ idoperacion: id }).subscribe((result) => {
      if (result.operacion[0].imagenBase64 != "" && result.operacion[0].imagenBase64 != null) {
        this.imageToShow = result.operacion[0].imagenBase64;
      }
      if (result.operacion[0].prioridad == null) {
        this.prioridad = 0;
      } else {
        this.prioridad = result.operacion[0].prioridad;
      }
      this.partido = false;
      this.fueraturno = false;
      this.fuerataller = false;
      this.cambiomaquina = false;

      this.ineficencia = false;
      this.continuo = false;
      this.tiempoCambio = 0;
      this.fechainilimite = null;
      this.fechafinlimite = null;
      // this.fechafinlimite.setMonth(this.fechafinlimite.getMonth() + 1);
      if (result.operacion[0].fechaIni != undefined)
        this.fechainilimite = new Date(result.operacion[0].fechaIni);
      if (result.operacion[0].fechaFin != undefined)
        this.fechafinlimite = new Date(result.operacion[0].fechaFin);
        this.nombreHisto = result.operacion[0].operacion;
        this.idErpHisto = result.operacion[0].idERP;
        this.ordenHistoric = result.operacion[0].orden;
        this.subordenHistoric = result.operacion[0].subOrden;
        this.secuenciaHisto = result.operacion[0].secuencia;
      if (result.operacion[0].limitado != null) {
        this.limitado = (result.operacion[0].limitado == 1);
      }

      this.tipooperacionHistoric = this.tipooperacion;
      this.obligatoriaHistoric = result.operacion[0].obligatorio;
      this.edicionHistoric = result.operacion[0].solicitar_edicion;
      this.loteHistoric = result.operacion[0].solicitar_Lote;
      this.nSerieHistoric = result.operacion[0].solicitar_N_Serie;
      this.coladaHistoric = result.operacion[0].solicitar_Colada;
      this.calidadHistoric = result.operacion[0].calidad;
      this.piezasseguidasHistoric = result.operacion[0].operarVariasPiezasAlMismoTiempo;
      this.ladoHisto = result.operacion[0].lado;
      this.tiempoEstimadoHisto = this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoS);
      this.tiempoEstimadoPreparacionHisto = this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoPreparacionS);
      this.costeHisto = result.operacion[0].coste;
      this.limitesPrioridadHisto = this.prioridad;
      this.limitesProcesaFueraTallerHisto = result.operacion[0].subcontratado;
      this.limitesProcesarFueraTurnoHisto = result.operacion[0].planificarFueraDeTurno;
      this.limitesInterrumpirHisto = result.operacion[0].cortarAlFinalizarTurno;
      this.imagenHisto = result.operacion[0].imagen;
      this.observacionesHisto = result.operacion[0].observaciones;
      this.calidadCantidadHisto = result.operacion[0].calidadCantidad;
      this.calidadProcesarHisto = result.operacion[0].calidadProcesar;
      this.calidadRestoHisto = result.operacion[0].calidadResto;
      this.calidadObservacionesHisto = result.operacion[0].calidadObservaciones;
      this.confirmacionMensajeHisto = result.operacion[0].no_Correcto_Mensaje;
      if(retroceder_data.nombre != undefined)
        this.confirmacionRetrocederHisto = retroceder_data.nombre;
      else{
        this.confirmacionRetrocederHisto = "sinAsignar";
      }
      this.tipooperacion = result.operacion[0].idOperacion_Tipo;
      this.tipooperacionHistoric = this.tipooperacion;
      this.obligatoriaHistoric = result.operacion[0].obligatorio;

      this.piezasseguidasHistoric = result.operacion[0].operarVariasPiezasAlMismoTiempo;

      var tipo_operacion = this.tipoOperacionLista.find(x => x.id === result.operacion[0].tipo_operacion);
      if (tipo_operacion == undefined) tipo_operacion = this.tipoOperacionLista[0];
      this.tipooperacionSuperiorHisto = tipo_operacion.nombre;

      var retroceder_data = this.operacionesHermanas.find(x => x.id === result.operacion[0].no_Correcto_Retroceder_a);
      if (retroceder_data == undefined) retroceder_data = {};

      this.form = this.formBuilder.group({
        idDb: this.user.idDb,
        idruta: this.idRuta,
        idparte: this.idParte,
        idpieza: this.idPieza,
        idof: this.idOF,
        idReferencia: [result.operacion[0].operacion,],
        nombre: [result.operacion[0].operacion, Validators.required],
        tipo_operacion: [tipo_operacion,],
        tipo_operacion_id: [tipo_operacion.id,],
        orden: [result.operacion[0].orden,],
        lado: [result.operacion[0].lado,],
        suborden: [result.operacion[0].subOrden,],
        tiempoestimado: [this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoS), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
        tiempoEstimadoPreparacion: [this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoPreparacionS), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
        coste: [result.operacion[0].coste, Validators.required],
        observaciones: [result.operacion[0].observaciones,],
        iderp: [result.operacion[0].idERP,],
        informativo: ['',],
        valor: ['',],
        confirmacion: ['',],
        solicitar: ['',],
        cantidad: [result.operacion[0].cantidad,],
        procesar: [result.operacion[0].calidadProcesar,],
        resto: [result.operacion[0].calidadResto,],
        mensaje: [result.operacion[0].no_Correcto_Mensaje,],
        retroceder_data: [retroceder_data],
        retroceder: [result.operacion[0].no_Correcto_Retroceder_a,],
        escandallo: [result.operacion[0].idBOM,],
        cantidadescandallo: [result.operacion[0].cantidadBOM,],
        solicitarNSerie: [result.operacion[0].solicitar_N_Serie,],
        solicitarColada: [result.operacion[0].solicitar_Colada,],
        solicitarLote: [result.operacion[0].solicitar_Lote,],
        solicitaredicion: [result.operacion[0].solicitar_edicion,],
        fueraturno: [result.operacion[0].planificarFueraDeTurno],
        fuerataller: [result.operacion[0].subcontratado,],
        cambiomaquina: [this.cambiomaquina,],
        tiempocambiomaquina: [this.tiempoCambio,],
        fechainicio: this.fechainilimite,
        fechafin: this.fechafinlimite,
        tiemposParadasEst: ['',],
        ineficencia: [this.ineficencia,],
        procesocontinuo: [result.operacion[0].cortarAlFinalizarTurno],
        prioridad: [this.prioridad,],
        tiempoentremaquinas: [this.tiempoCambio,],
        piezasseguidas: [result.operacion[0].operarVariasPiezasAlMismoTiempo,],
        obligatoria: [result.operacion[0].obligatorio,],
        partido: [this.partido,],
        continuo: [result.operacion[0].cortarAlFinalizarTurno,],
        tipooperacion: [result.operacion[0].idOperacion_Tipo],
        tipooperacionValor: [false,],
        tipooperacionConfirmacion: [false,],
        tipooperacionValormaquina: [false,],
        tipooperacionInformativo: [false,],
        archivo: new FormControl(''),
       /* herramientas: new FormControl(this.selectedItems)*/
      }
      );
      this.idMaquinas = result.operacion[0].idsMaquinas.split(",");
      this.estadoMaquinas = result.operacion[0].prioridadesMaquinas.split(",");

      this.tipooperacion = result.operacion[0].idOperacion_Tipo;
      this.calidadActivo = result.operacion[0].calidad;
      if (this.calidadActivo) this.calidadActivo = (this.user.calidad > 0);


      this.valores = result.valores;
      
      this.operacionesPredefinidasService.getdatosControl().pipe().subscribe((resultControl) => {
        this.operacionesPredefinidasService.getdatosTolerancia().pipe().subscribe((resultTolerancia) => {
          this.datosControl = resultControl; //Este nunca va a cambiar, pero la tolerancia va a ser distinta para cada valor
          this.datosTolerancia = resultTolerancia; //Aqui guardamos la general y luego las filtramos
          //Para solventar error seleccion
          if (this.valores){
            this.valores.forEach(element => {
              if(element.tipoControl!=-1)
                element.tipoControl = this.datosControl.filter(x=>x.id == element.tipoControl)[0];
              else{
                element.tipoControl = {nombre: "", id: -1};
              }
              if(element.tipoControl.id!=-1){
                element.datosTolerancia = this.datosTolerancia.filter(x=>x.idTolerancia == element.tipoControl.id);
                if(element.datosTolerancia.length==0){
                  element.datosTolerancia = [];
                  element.tipoTolerancia = {nombre: "", id: -1};
                }else{
                  element.tipoTolerancia = element.datosTolerancia.filter(x=>x.id==element.tipoTolerancia)[0];
                }
              }else{
                element.datosTolerancia = [];
                element.tipoTolerancia = {nombre: "", id: -1};
              }
              element.idNuevo = element.id;
              //element.visible= Boolean(element.visible);
            });
            this.valoresFinales = this.valores;
            this.valoresHisto = [];
            this.valores.forEach(val => this.valoresHisto.push(Object.assign({}, val))); //Hacemos una copia del array
          }
        });
      });
    });
  }

  //Funciones herramientas
  agregarHerramienta(e){
    e.preventDefault(); //prevent submit
    this.herramientasTotalesSeleccionadas = []; //quitamos las que estaban seleccionadas antes, por si ya hemos agregado alguna.
    //Cargar datos herramientas antes de desplegar el modal (se vuelven a cargar, aunque ya hubiese datos previamente por si acaso se ha insertado alguna nueva por detrás)
    this.consumiblesService.Get_consumibles_grid().subscribe((result: any) => {
      this.herramientasTotales = result;
    });
    this.modalReferencepopupAddHerramienta= this.modalService.open(this.popupAddHerramienta, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
  }

  eliminarHerramienta(event){
    event.preventDefault(); //prevent submit
    var herramientas = this.herramientas.filter(x=>this.herramientasSelected.includes(x.idNuevo));
    var herramientasFinales= []
    herramientas.forEach(element => {
      if (element.id != 0){
        element.id= -1; //Era una insercion, ahora es eliminacion
        herramientasFinales.push(element);
      }
    
    });
    herramientas = this.herramientas.filter(x=>!this.herramientasSelected.includes(x.idNuevo));
    herramientas.forEach(element => { //insertamos los que no fueron seleccionados
        herramientasFinales.push(element);
    });
    this.herramientas = herramientasFinales;
    this.herramientasFinales = this.herramientas.filter(x=>x.id!=-1);
    this.recargarGrid();
  }

  agregarHerramientaModal(){
    //Herramientas a agregar a la operacion, se agrega solo el id enlazado, así que con esto nos vale
    //en operacion deberíamos tener un atributo herramientas donde insertar los ids de las herramientas nuevas que se le han agregado
    if(this.herramientas == undefined){
      this.herramientas = [];
    }
    this.herramientasTotalesSeleccionadas.forEach(element => {
      var maxId;
      if (this.herramientasFinales.length === 0)
        maxId = -1;
      else
        maxId = Math.max.apply(Math, this.herramientasFinales.map(value => value.idNuevo));
      var maxOrden;
      if (this.herramientasFinales.length === 0)
        maxOrden = -1;
      else
        maxOrden = Math.max.apply(Math, this.herramientasFinales.map(value => value.orden));
      //Primero se va a calcular el porcentaje que reste y el tiempo estimado, para asignarselo a la nueva herramienta
      var tiempoUsoTotal = 0.0;
      var porcentajeTotal = 0.0;
      this.herramientas.forEach(element => {
        tiempoUsoTotal+= this.HMSToSeconds(element.tiempoUso);
        porcentajeTotal+=element.porcenUso;
      });
      var herramienta = this.herramientasTotales.filter(x=>x.id== element)[0];
     // herramienta.tiempoUso = this.myFunctions.secondsTo_HH_MM_SS(this.HMSToSeconds(this.form.value.tiempoestimado)-tiempoUsoTotal); //Aquí hay que asignarle el que reste
      herramienta.tiempoUso = this.myFunctions.secondsToHms(this.HMSToSeconds(this.form.value.tiempoestimado)-tiempoUsoTotal); //Aquí hay que asignarle el que reste
      herramienta.porcenUso = 100.0-porcentajeTotal; //Aquí hay que asignarle el que reste
      herramienta.idConsumible = herramienta.id;
      herramienta.id = 0; //porque es una inserción
      herramienta.idOperacion = Number(this.idOperacion);
      herramienta.orden = maxOrden + 1;
      herramienta.idNuevo = maxId + 1;
      herramienta.errorTiempos = false; //al principio el error siempre será falso
      console.log(herramienta)
      this.herramientas.push(herramienta);
    });
    this.herramientasFinales = this.herramientas.filter(x=>x.id!=-1);
    this.recargarGrid();
    this.modalReferencepopupAddHerramienta.close();
  }

  cancelarSeleccionHerramienta(){
    this.modalReferencepopupAddHerramienta.close();
    this.herramientasTotalesSeleccionadas = []; //lo vaciamos porque no se ha aceptado
  }

  updateDatosHerramienta(dataItem, cambio, porcen){
    //aquí hay que revisar que si se cambia el tiempoUso también se debe cambiar el de los demás y lo mismo con el porcentaje
    //también comprobar que no se pasen del tope entre todos los objetos
    if(porcen==0){//ha cambiado el tiempo
      var pattern = new RegExp('^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$');
      var result=pattern.test(cambio);
      if(result== false){
        dataItem.errorTiempoEstimado = true;
      }
      else{
        //No hay error en el formato
        var nuevoPorcen = 100.0*(this.HMSToSeconds(cambio)/this.HMSToSeconds(this.form.value.tiempoestimado));
        dataItem.porcenUso = nuevoPorcen;
        dataItem.errorTiempoEstimado = false;
        var tiempoUsoTotal = 0.0;
        var porcentajeTotal = 0.0;
        this.herramientas.forEach(element => { //las recorremos para hacer la suma y luego para darles los errores, pues si se hace en el mismo el primero no pillará
          tiempoUsoTotal+= this.HMSToSeconds(element.tiempoUso);
          porcentajeTotal+=element.porcenUso;
        }); 
        this.herramientas.forEach(element => { //las recorremos para hacer la suma y luego para darles los errores, pues si se hace en el mismo el primero no pillará
          if(porcentajeTotal>100.0 || tiempoUsoTotal> this.HMSToSeconds(this.form.value.tiempoestimado)){
            element.errorTiempos = true;
          }else{
            element.errorTiempos = false;
          }
        });
      }
    }else{
      //ha cambiado el porcentaje
      var nuevoTiempo = this.myFunctions.secondsTo_HH_MM_SS((this.HMSToSeconds(this.form.value.tiempoestimado))*(cambio/100.0));
      dataItem.tiempoUso = nuevoTiempo;
      dataItem.errorTiempoEstimado = false; //se autocorrige al cambiar el porcentaje
      //Revisamos tras aggrer los cambios que ninguno se pase
      var tiempoUsoTotal = 0.0;
      var porcentajeTotal = 0.0;
      this.herramientas.forEach(element => { //las recorremos para hacer la suma y luego para darles los errores, pues si se hace en el mismo el primero no pillará
        tiempoUsoTotal+= this.HMSToSeconds(element.tiempoUso);
        porcentajeTotal+=element.porcenUso;
      }); 
      this.herramientas.forEach(element => { //las recorremos para hacer la suma y luego para darles los errores, pues si se hace en el mismo el primero no pillará
        if(porcentajeTotal>100.0 || tiempoUsoTotal> this.HMSToSeconds(this.form.value.tiempoestimado)){
          element.errorTiempos = true;
        }else{
          element.errorTiempos = false;
        }
      });
    }
    this.herramientasFinales = this.herramientas.filter(x=>x.id!=-1);
    this.recargarGrid();

  }

  //Funciones recetas
  recetaClick(event) {
    this.router.navigate(['operacionespredefinidas/editarReceta/' + this.idOperacion + '/' + event.dataItem.id]);
  }

  agregarReceta(event) {
    this.router.navigate(['operacionespredefinidas/crearReceta/' + this.idOperacion]);
  }

  eliminarReceta(contentRecetas) {
    if (this.recetasSelected.length > 0) {
      this.modalReference = this.modalService.open(contentRecetas, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  eliminarRecetaConfirmar() {
    this.recetasService.delete(this.recetasSelected.join(",")).subscribe(
      (data: any) => {
        this.CargarDatos();
        this.modalReference.close();
      }
    );
    this.recetasSelected = [];
  }

  HMSToSeconds(tiempoEstimado) {
    if(tiempoEstimado.toString().includes(":")){
      var horas, minutos, segundos;
      horas = Number(tiempoEstimado.split(":")[0]);
      minutos = Number(tiempoEstimado.split(":")[1]);
      segundos = Number(tiempoEstimado.split(":")[2]);
      return horas *3600 + minutos * 60 + segundos;
    }else return tiempoEstimado;
  }


  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }

  //Drag and drop del grid de herramientas
  public rowCallback(context: RowClassArgs) {
    context.dataItem.orden = context.index + 1;
    return {
      dragging: context.dataItem.dragging
    };
  }

  private handleDragAndDrop(): Subscription {

    const sub = new Subscription(() => { });
    let draggedItemIndex;
    const tableRows = Array.from(document.querySelectorAll('.grid-herramientas.k-grid tr'));
    tableRows.forEach(item => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(dragStart.pipe(
        tap(({ dataTransfer }) => {
          try {
            const dragImgEl = document.createElement('span');
            dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
            document.body.appendChild(dragImgEl);
            dataTransfer.setDragImage(dragImgEl, 0, 0);
          } catch (err) {
            // IE doesn't support setDragImage
          }
        })
      ).subscribe(({ target }) => {
        const row: HTMLTableRowElement = <HTMLTableRowElement>target;
        draggedItemIndex = row.rowIndex;
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = true;
      }));

      sub.add(dragOver.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
        const dropIndex = closest(e.target, tableRow).rowIndex;
        const dropItem = this.gridData.data[dropIndex];

        draggedItemIndex = dropIndex;
        this.zone.run(() =>
          this.gridData.data.splice(dropIndex, 0, dataItem)
        );
      }));

      sub.add(dragEnd.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = false;
      }));
    });

    return sub;
  }

  recargarGrid() {
    this.gridData = process(this.herramientasFinales, this.state);
    this.currentSubscription.unsubscribe();
    this.zone.onStable.pipe(take(1)).subscribe(() => this.currentSubscription = this.handleDragAndDrop());
  }

  recargarGridProgramas(){
    this.gridProgramas = process(this.programasFinales, this.state);
    this.currentSubscription.unsubscribe();
    this.zone.onStable.pipe(take(1)).subscribe(() => this.currentSubscription = this.handleDragAndDrop());
  }

  public ngAfterViewInit(): void {
    this.currentSubscription = this.handleDragAndDrop();
  }

  public ngOnDestroy(): void {
    this.currentSubscription.unsubscribe();
  }

  onChangePorDefecto(dataItem, value){
    if(value==true){
      dataItem.cantidad = this.form.value.cantidad;
      dataItem.procesar = this.form.value.procesar;
      dataItem.resto = this.form.value.resto;
    }
  }

  cambioTolerancia(elemento, cambio){
    elemento.datosTolerancia = this.datosTolerancia.filter(x=>x.idTolerancia == cambio.id);
    elemento.tipoTolerancia = {'nombre': '', id: -1}
  }

  abrirModalFrecuencias(elemento, e) {
    e.preventDefault();
      this.elementoSeleccionado_modal = elemento;
      //Cargamos los datos de todo primero
      /* this.esFuncion_modal = this.elementoSeleccionado_modal.esFuncion;
      this.funcion_modal = this.elementoSeleccionado_modal.funcion;
      this.imagen_modal = this.elementoSeleccionado_modal.imagen;
      this.ruta_modal = this.elementoSeleccionado_modal.ruta;
      if (this.utillajeModal.length > 0) {
        this.selectedUtillaje = this.utillajeModal.filter(x => x.id == this.elementoSeleccionado_modal.idUtillaje)[0];
      } */
      this.valores_modal = [{
        idNuevo: 1,
        nombre: this.translate.instant("frecuencia1"), 
        cantidad: this.elementoSeleccionado_modal.frecuencia1_cant,
        porcen1: this.elementoSeleccionado_modal.frecuencia1_porcen1, 
        porcen2: this.elementoSeleccionado_modal.frecuencia1_porcen2,
        porcen3: this.porcentaje(this.elementoSeleccionado_modal.frecuencia1_porcen1, this.elementoSeleccionado_modal.frecuencia1_porcen2),
        disabled: false
      },
      {
        idNuevo: 2,
        nombre: this.translate.instant("frecuencia2"), 
        cantidad: this.elementoSeleccionado_modal.frecuencia2_cant,
        porcen1: this.elementoSeleccionado_modal.frecuencia2_porcen1, 
        porcen2: this.elementoSeleccionado_modal.frecuencia2_porcen2,
        porcen3: this.porcentaje(this.elementoSeleccionado_modal.frecuencia2_porcen1, this.elementoSeleccionado_modal.frecuencia2_porcen2),
        disabled: this.elementoSeleccionado_modal.frecuencia1_cant > 0 ? false : true
      },
      {
        idNuevo: 3,
        nombre: this.translate.instant("frecuencia3"), 
        cantidad: this.elementoSeleccionado_modal.frecuencia3_cant,
        porcen1: this.elementoSeleccionado_modal.frecuencia3_porcen1, 
        porcen2: this.elementoSeleccionado_modal.frecuencia3_porcen2,
        porcen3: this.porcentaje(this.elementoSeleccionado_modal.frecuencia3_porcen1, this.elementoSeleccionado_modal.frecuencia3_porcen2),
        disabled: this.elementoSeleccionado_modal.frecuencia2_cant > 0 ? false : true
      },
      {
        idNuevo: 4,
        nombre: this.translate.instant("frecuencia4"), 
        cantidad: this.elementoSeleccionado_modal.frecuencia4_cant,
        porcen1: this.elementoSeleccionado_modal.frecuencia4_porcen1, 
        porcen2: this.elementoSeleccionado_modal.frecuencia4_porcen2,
        porcen3: this.porcentaje(this.elementoSeleccionado_modal.frecuencia4_porcen1, this.elementoSeleccionado_modal.frecuencia4_porcen2),
        disabled: this.elementoSeleccionado_modal.frecuencia3_cant > 0 ? false : true
      },
      {
        idNuevo: 5,
        nombre: this.translate.instant("frecuencia5"), 
        cantidad: this.elementoSeleccionado_modal.frecuencia5_cant,
        porcen1: this.elementoSeleccionado_modal.frecuencia5_porcen1, 
        porcen2: this.elementoSeleccionado_modal.frecuencia5_porcen2,
        porcen3: this.porcentaje(this.elementoSeleccionado_modal.frecuencia5_porcen1, this.elementoSeleccionado_modal.frecuencia5_porcen2),
        disabled: this.elementoSeleccionado_modal.frecuencia4_cant > 0 ? false : true
      },
      {
        idNuevo: 6,
        nombre: this.translate.instant("resto"), 
        cantidad: this.elementoSeleccionado_modal.resto_cant,
        porcen1: this.elementoSeleccionado_modal.resto_porcen1, 
        porcen2: this.elementoSeleccionado_modal.resto_porcen2,
        porcen3: this.porcentaje(this.elementoSeleccionado_modal.resto_porcen1, this.elementoSeleccionado_modal.resto_porcen2),
        disabled: false
      },
      {
        idNuevo: 7,
        nombre: this.translate.instant("cambioCritico"), 
        cantidad: this.elementoSeleccionado_modal.cambioOperario_cant,
        porcen1: this.elementoSeleccionado_modal.cambioOperario_porcen1, 
        porcen2: this.elementoSeleccionado_modal.cambioOperario_porcen2,
        porcen3: this.porcentaje(this.elementoSeleccionado_modal.cambioOperario_porcen1, this.elementoSeleccionado_modal.cambioOperario_porcen2),
        disabled: false
      }];
      this.modalReferencepopupFrecuencias = this.modalService.open(this.popupFrecuencias, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }


  cantidadChange(dataItem, cambio){
    switch(dataItem.nombre){
      case this.translate.instant("frecuencia1"):{
        if(dataItem.cantidad!=undefined && dataItem.cantidad!="" && dataItem.cantidad>0){
           this.valores_modal[1].disabled=false;
        }
        else{
          this.valores_modal[1].disabled=true;
        }
        break;
      }
      case this.translate.instant("frecuencia2"):{
        if(dataItem.cantidad!=undefined && dataItem.cantidad!="" && dataItem.cantidad>0){
          this.valores_modal[2].disabled=false;
       }
       else{
         this.valores_modal[2].disabled=true;
       }
        break;
      }
      case this.translate.instant("frecuencia3"):{
        if(dataItem.cantidad!=undefined && dataItem.cantidad!="" && dataItem.cantidad>0){
          this.valores_modal[3].disabled=false;
       }
       else{
         this.valores_modal[3].disabled=true;
       }
        break;
      }
      case this.translate.instant("frecuencia4"):{
        if(dataItem.cantidad!=undefined && dataItem.cantidad!="" && dataItem.cantidad>0){
          this.valores_modal[4].disabled=false;
       }
       else{
         this.valores_modal[4].disabled=true;
       }
        break;
      }
        
    }
    var cantidadTotal = 0;
    if(dataItem.nombre!=this.translate.instant("resto") && dataItem.nombre!= this.translate.instant("cambioCritico")){
      var cantidadFrecuencias = 0;
      this.valores_modal.forEach(element => {
        if(element.nombre!=this.translate.instant("resto") && element.nombre!= this.translate.instant("cambioCritico")
        && element.cantidad!=undefined){
          cantidadFrecuencias+=element.cantidad;
        }
      });
      var cantidadesResto = cantidadTotal-cantidadFrecuencias;
      if(cantidadesResto<0)
        cantidadesResto =0;
      this.valores_modal.filter(x=>x.nombre == this.translate.instant("resto"))[0].cantidad = cantidadesResto;
    }
  }

  aceptarGuardadoFrecuencias(e){
    e.preventDefault();
    for(var i=0; i<this.valores_modal.length;i++) {
      var dataItem = this.valores_modal[i];
        if((dataItem.cantidad!=undefined || dataItem.cantidad!=null) && ((dataItem.porcen1==undefined || dataItem.porcen1==null)
      || (dataItem.porcen2==undefined || dataItem.porcen2==null))){
        this.alertService.error(this.translate.instant('valoresObligatorios'));
        return;
      }
    };
   
    var auxi = this.valores.filter(x=>x.idNuevo == this.elementoSeleccionado_modal.idNuevo)[0];
    /* auxi.imagen = this.imagen_modal;
    auxi.esFuncion = this.esFuncion_modal;
    auxi.funcion = this.funcion_modal;
    auxi.ruta = this.ruta_modal;
    auxi.idUtillaje = this.selectedUtillaje; */
    //Ahora metemos lo del array, que siempre estará en el mismo orden, así que no tenemos problema
    auxi.frecuencia1_cant = this.valores_modal[0].cantidad;
    auxi.frecuencia1_porcen1 = this.valores_modal[0].porcen1;
    auxi.frecuencia1_porcen2 = this.valores_modal[0].porcen2;
    auxi.frecuencia2_cant = this.valores_modal[1].cantidad;
    auxi.frecuencia2_porcen1 = this.valores_modal[1].porcen1;
    auxi.frecuencia2_porcen2 = this.valores_modal[1].porcen2;
    auxi.frecuencia3_cant = this.valores_modal[2].cantidad;
    auxi.frecuencia3_porcen1 = this.valores_modal[2].porcen1;
    auxi.frecuencia3_porcen2 = this.valores_modal[2].porcen2;
    auxi.frecuencia4_cant = this.valores_modal[3].cantidad;
    auxi.frecuencia4_porcen1 = this.valores_modal[3].porcen1;
    auxi.frecuencia4_porcen2 = this.valores_modal[3].porcen2;
    auxi.frecuencia5_cant = this.valores_modal[4].cantidad;
    auxi.frecuencia5_porcen1 = this.valores_modal[4].porcen1;
    auxi.frecuencia5_porcen2 = this.valores_modal[4].porcen2;
    auxi.resto_cant = this.valores_modal[5].cantidad;
    auxi.resto_porcen1 = this.valores_modal[5].porcen1;
    auxi.resto_porcen2 = this.valores_modal[5].porcen2;
    auxi.cambioOperario_cant = this.valores_modal[6].cantidad;
    auxi.cambioOperario_porcen1 = this.valores_modal[6].porcen1;
    auxi.cambioOperario_porcen2 = this.valores_modal[6].porcen2;
    this.modalReferencepopupFrecuencias.close();
  }

  onUploadButtonClick(e: UploadEvent, content) {
    e.preventDefault();

    this._kendoFiles = e.files;
    this.myFiles2 = [];
    var f: File = this._kendoFiles[0].rawFile;

    const formData: FormData = new FormData();
    formData.append('file', f, f.name);
    formData.append('idOperacion', this.idOperacion.toString());
    formData.append('cantidad', this.form.value.cantidad.toString());

    this.modalReferenceloadingupload = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    this.operacionesPredefinidasService.importarCotas(formData).subscribe((result)=>{
      this.modalReferenceloadingupload.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
      } else {
        this.alertService.error(this.translate.instant('error'));
      }
      this.myFiles2 = [];
      this.CargarDatosValores();
    }); 
  }

  CargarDatosValores(){
    this.operacionesPredefinidasService.GetByID(this.idOperacion).pipe().subscribe((result) => {
      this.valores = result.valores;
      
      this.operacionesPredefinidasService.getdatosControl().pipe().subscribe((resultControl) => {
        this.operacionesPredefinidasService.getdatosTolerancia().pipe().subscribe((resultTolerancia) => {
          this.datosControl = resultControl; //Este nunca va a cambiar, pero la tolerancia va a ser distinta para cada valor
          this.datosTolerancia = resultTolerancia; //Aqui guardamos la general y luego las filtramos
          //Para solventar error seleccion
          if (this.valores){
            this.valores.forEach(element => {
              if (element.tipoControl != -1)
                element.tipoControl = this.datosControl.filter(x => x.id == element.tipoControl)[0];
              else {
                element.tipoControl = { nombre: "", id: -1 };
              }
              if (element.tipoControl!=undefined && element.tipoControl.id != -1) {
                element.datosTolerancia = this.datosTolerancia.filter(x => x.idTolerancia == element.tipoControl.id);
                if (element.datosTolerancia.length == 0) {
                  element.datosTolerancia = [];
                  element.tipoTolerancia = { nombre: "", id: -1 };
                } else {
                  element.tipoTolerancia = element.datosTolerancia.filter(x => x.id == element.tipoTolerancia)[0];
                }
              } else {
                element.datosTolerancia = [];
                element.tipoTolerancia = { nombre: "", id: -1 };
              }
              element.idNuevo = element.id;
              element.ruta = (element.ruta==null || element.ruta==undefined)?"":element.ruta;
              //element.visible= Boolean(element.visible);
            });
            this.valoresFinales = this.valores;
            this.valoresHisto = [];
            this.valores.forEach(val => this.valoresHisto.push(Object.assign({}, val))); //Hacemos una copia del array
          }
        });
      });
      
    });
  }

  clickModalRuta(dataItem, e){
    e.preventDefault();
    this.submitted2=false;
    this.ruta_modal = dataItem.ruta;
    this.elementoSeleccionado_modal = dataItem;
    this.modalReferencepopupDocumentoCalidad = this.modalService.open(this.popupDocumentoCalidad, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  aceptarGuardadoDocumento(e){
    e.preventDefault();
    this.submitted2=true;
    if(this.ruta_modal==null || this.ruta_modal==undefined || this.ruta_modal==""){
      this.errorRutaModal=true;
      return;
    }else{
      this.errorRutaModal=false;
    }
    this.elementoSeleccionado_modal.ruta = this.ruta_modal;
    this.modalReferencepopupDocumentoCalidad.close();
  }

  public idControlarCotasNoCriticas_selectionChange(value: any): void {
    this.ddControladorCotasNoCriticasChanged = true;
  }

  public idControlarCotasCriticas_selectionChange(value: any): void {
    this.ddControladorCotasCriticasChanged = true;
  }

  public idObservacionPiezas_selectionChange(value: any): void {
    this.ddObservacionPiezasChanged = true;
  }

  public recalcularFracciones(numElem, dataItem,  e, idElemento)
  {
    //let pc = Number(Number(dataItem.porcen1)*100/Number(dataItem.porcen2)).toFixed(0);
    
    switch (parseInt(numElem)) {
      case 1:
          {

            this.valores_modal.filter(x => x.idNuevo==idElemento)[0].porcen1=e;
            //this.valores_modal.filter(x => x.idNuevo==idElemento)[0].porcen2=dataItem.porcen2;
            this.valores_modal.filter(x => x.idNuevo==idElemento)[0].porcen3=Number(Number(e)*100/Number(dataItem.porcen2));
            break;
          }
      case 2:
          {
            //this.valores_modal.filter(x => x.idNuevo==idElemento)[0].porcen1=dataItem.porcen1;
            this.valores_modal.filter(x => x.idNuevo==idElemento)[0].porcen2=e;
            this.valores_modal.filter(x => x.idNuevo==idElemento)[0].porcen3=Number(Number(dataItem.porcen1)*100/Number(e));
            break;
          }
      case 3:
          {
            let pc1 = Number(e);
            let pc2= 100;
            let mcd = this.EuclidesMCD(pc1, pc2);
            this.valores_modal.filter(x => x.idNuevo==idElemento)[0].porcen1=pc1/mcd;
            this.valores_modal.filter(x => x.idNuevo==idElemento)[0].porcen2=pc2/mcd;
            this.valores_modal.filter(x => x.idNuevo==idElemento)[0].porcen3=e;
            break;
          }
    }
  }

  public EuclidesMCD(a, b) {
    var iaux; //auxiliar
    a = Math.abs(a); //tomamos valor absoluto
    b = Math.abs(b);
    var i1 = Math.max(a, b); //i1 = el más grande
    var i2 = Math.min(a, b); //i2 = el más pequeño
  
    do {
      iaux = i2; //guardar divisor
      i2 = i1 % i2; //resto pasa a divisor
      i1 = iaux; //divisor pasa a dividendo
    } while (i2 !== 0);
    return i1; //ultimo resto no nulo
  }

  public porcentaje(a, b) {
    if (b==null) return 0;
    if  (b>0)
      return a*100/b;
    else return 0
  }

}
