import { Component } from '@angular/core';
import { MyFunctions } from '@app/_helpers';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FileRestrictions, SelectEvent, UploadEvent } from '@progress/kendo-angular-upload';
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UsuariosService, AlertService, BomService, GestionDocumentalService, SeriesService, MenuService, RutasService, MaquinasService, OperacionesPredefinidasService, InformeProyectosService, AtributosService } from '@app/_services';
import { PiezasService } from '../_services/piezas.service';
import { OperacionesService } from '../_services/operaciones.service';
import { ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import * as moment from 'moment';
import { LayoutModule, TabStripComponent } from '@progress/kendo-angular-layout';
import { preserveWhitespacesDefault } from '@angular/compiler';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { ListButton } from '@progress/kendo-angular-buttons';

@Component({
  selector: 'app-piezadetalle-data',
  templateUrl: 'piezaDetalle.html'
})

export class PiezaDetalleComponent {

  public loadingGraficoTresEjes: any = false;
  public idOF: number;
  public idParte: number;
  public decimals: number;
  public tipoPiezaLista: any = [];
  public operaciones: [];
  public historicocambios: any = [];
  public bom: [];
  public gestiondocumental: any = [];
  public series: any[] = [];
  public referencias: [];
  public rutas: any = [];
  public imagePreviews: any[] = [];
  public events: string[] = [];
  public idOriginal: number; s
  //Minimos y maximos de los numericos
  public isMultiple: boolean;
  public minNum = 0;
  public maxNum = 999999;
  public restrictions: FileRestrictions = {
    allowedExtensions: ['.png'],
    maxFileSize: 1048576
  };
  public idPieza: number;
  cantidadAgrupada: number;
  textoVersion: String;
  nombre: String;
  numplano: string;
  refpieza: string;
  iderppieza: number;
  espieza: Boolean = true;//para no mostrar las variables de pieza al entrar en una parte
  limitado: Boolean;
  cantidad: number;
  hechos: number;
  coste: number;
  dureza: number;
  rutasmultiples: Boolean;
  showDetails: Boolean;
  terminado: Boolean;
  descripcion: String;
  refparte: String;
  parte: String;
  orden: number;
  tienerutas: Boolean;
  idERPparte: number;
  referencia: any;
  public botonesActivados: Boolean;
  public idRuta: number;
  tipoPieza: any;
  coladaF: String;
  loteF: String;
  cantidadF: number;
  numeroserieF1: String;
  numeroserieF2: String;
  numeroserieclienteF1: String;
  numeroserieclienteF2: String;
  cantidadDigitosNSF: number;
  cantidadDigitosNSCF: number;


  public autoCorrect: boolean = true;

  public operacionesseleccionados: number[] = [];
  public rutasseleccionados: any = [];
  user = this.userService.userValue;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  modalReferenceloadingupload: NgbModalRef;
  isAddMode: boolean;
  public read: String = "";

  form: FormGroup;
  formAutogenerar: FormGroup;
  public checkBoxNumeroSerieCliente: boolean = false;
  // public limitesstr: String = '';
  // public gestiondocumentalstr: String = '';
  // public operacionessstr: String = '';
  // public historicocambiosstr: String = '';
  // public rutasstr: String = '';
  // public seriestr: String = '';
  // public bomstr: String = '';


  closeResult = '';


  fechaHoyStr: string;
  tituloPopUp: any;
  tituloPopUpPregunta: any;
  tituloPopUpAutogenerar: any;
  _kendoFiles: any;

  maquinas: any[];

  public activerutas: String;
  public activeoperaciones: String;

  navigationSubscription;
  fueraturno: any;
  fuerataller: any;
  cambiomaquina: any;
  ineficencia: any;
  continuo: any;
  prioridad: any;
  partido: any;
  fechainilimite: Date;
  fechafinlimite: Date;

  @ViewChild('anadirOperacionPred') anadirOperacionPred: NgbModalRef;

  public operacionesPred: any = []; //Tiene el listado de operacionesPredefinidas con sus datos
  public operacionesPredSelected: any = []; //Tiene las operacionesPredefinidas seleccionadas

  public unicaRutaPrincipal: boolean = true; //Indica si la parte tiene una única ruta, que sería la principal, pues entonces no puede ser eliminada

  public submitted = false;

  //Para comprobar el form de parte
  public errorReferenciaParte: boolean = false;
  public errorNombreParte: boolean = false;
  public errorOrdenParte: boolean = false;

  public tenemosSeries: boolean = false;
  public tenemosBOM: boolean = false;

  @ViewChild('popupBorrarDocumentos') popupBorrarDocumentos: NgbModalRef;
  @ViewChild('popupBorrarSeries') popupBorrarSeries: NgbModalRef;
  @ViewChild('popupAutogenerarSeries') popupAutogenerarSeries: NgbModalRef;
  @ViewChild('terminarPiezaOperacion') terminarPiezaOperacion: NgbModalRef;
  @ViewChild('catidadHechasOperacion') catidadHechasOperacion: NgbModalRef;
  @ViewChild('popupTerminarOperacion') popupTerminarOperacion: NgbModalRef;

  @ViewChild('tabstrip') tabstrip: TabStripComponent;

  mySelectionGestionDocumental: any = [];
  loadingGestionDocumental = true;
  isDeletingGestionDocumental;
  selectedTab: number;

  mySelectionSerie: any = [];
  loadingSerie = true;
  isDeletingSerie;

  public terminadoInicial: boolean = false;

  //DEPENDENCIAS
  public dependenciasPieza = [];
  public comboPartes = [];

  public atributos: any;
  public groupedAtributo: GroupResult[];
  public atributosSeleccionados: any[] = [];
  
  constructor(

    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    public myFunctions: MyFunctions,
    private translate: TranslateService,
    private piezasService: PiezasService,
    private bomService: BomService,
    private gestionDocumentalService: GestionDocumentalService,
    private seriesService: SeriesService,
    private operacionesService: OperacionesService,
    private operacionesPredefinidasService: OperacionesPredefinidasService,
    private modalService: NgbModal,
    private menuService: MenuService,
    private maquinasService: MaquinasService,
    private rutasService: RutasService,
    protected alertService: AlertService,
    private informeProyectosService: InformeProyectosService,
    private atributosService: AtributosService) {
    this.isMultiple = false;
    this.decimals = 2;
    var nav = this.router.getCurrentNavigation();
    this.menuService.titulo = this.translate.instant('piezas').toUpperCase();
  }

  ngOnInit() {

    this.showDetails = true;
    this.idPieza = this.route.snapshot.params.idpieza;
    this.idOF = this.route.snapshot.params.idof;
    this.idParte = this.route.snapshot.params.idparte;
    this.referencias = [];
    this.tituloPopUp = document.getElementById("text_loading_popup");
    this.tituloPopUpPregunta = document.getElementById("titulo_modal_pregunta");
    this.tituloPopUpAutogenerar	 = document.getElementById("modal-title");
    this.botonesActivados = false;
    this.cantidadAgrupada = 1;
    // this.limitesstr = this.translate.instant('limites');
    // this.gestiondocumentalstr = this.translate.instant('gestiondocumental');
    // this.operacionessstr = this.translate.instant('operaciones');
    // this.historicocambiosstr = this.translate.instant('historicocambios');
    // this.rutasstr = this.translate.instant('rutas');
    // this.seriestr = this.translate.instant('series');
    // this.bomstr = "BOM";
    this.isMultiple = false;
    this.idOriginal = 0;

    this.isAddMode = !(this.idPieza > 0);

    this.form = this.formBuilder.group({
      id: [''],
      idDb: this.user.idDb,
      textoVersion: [''],
      nombre: ['', Validators.required],
      numplano: ['', Validators.required],
      referencia: ['', Validators.required],
      idERPpieza: [''],
      tipo_pieza: [undefined],
      tipo_pieza_id: [undefined],
      espieza: [true,],
      limitado: [false,],
      cantidad: [1,],
      hechos: [0,],
      coste: [0.00,],
      dureza: [0,],
      rutasmultiples: ['',],
      cantidadAgrupada: [1,],
      terminado: [false,],
      descripcion: ['',],
      refparte: ['',],
      parte: ['',],
      orden: [0,],
      archivo: new FormControl(''),
      tienerutas: [false,],
      usaLotes: [false,],
      idERPparte: ['',],
      idOF: [this.idOF],
      prioridad: [0,],
      partido: [''],
      fueraturno: [''],
      fuerataller: [''],
      cambiomaquina: [false,],
      fechainilimite: [''],
      fechafinlimite: [''],
      ineficencia: ['',],
      contino: ['',],
      fechainicio: new FormControl(this.myFunctions.getDateNow()),
      fechafin: new FormControl(this.myFunctions.getDateNow()),
      continuo: ['',],
      tiempocambiomaquina: ['',],
      tadescripcion: ['',],
      idpieza: ['',],
      cantidadLote: [0,],
      atributos: [[]],
      peso: [0.00,],
    });

    this.formAutogenerar = this.formBuilder.group({
      coladaF: [''],
      loteF: [''],
      cantidadF: [1,],
      numeroserieF1: ['*'],
      numeroserieclienteF1: ['*'],
      checkboxNumeroSerieClienteF: false,
      cantidadDigitosNSF: [1,],
      cantidadDigitosNSCF: [1,],
    });




    this.tipoPieza = undefined;

    var r1, r2: boolean = false;

    this.piezasService.GetReferencias(this.idOF).pipe().subscribe((result) => {
      this.referencias = result.piezas;
      r1 = true;
      if (r1 && r2) {
        if (this.idPieza > 0) this.CargarDatos();
        else this.espieza = true;
      }
    });

    this.piezasService.Get_TipoPieza_Combo().pipe().subscribe((result) => {
      this.tipoPiezaLista = result;
      r2 = true;
      if (r1 && r2) {
        if (this.idPieza > 0) this.CargarDatos();
        else this.espieza = true;
      }
    });

    this.atributosService.getComboAtributos().subscribe(json => {
      this.atributos = json;
      if (this.atributos.length == 1)
        this.form.controls['atributos'].setValue(this.atributos);
      var an: any = this.atributos;
      this.groupedAtributo = groupBy(an, [{ field: 'atributo' }]);
    });

  }

  CargarDatos() {

    this.loadingGraficoTresEjes = true;
    this.piezasService.GetByID({ idOF: this.idOF, idpieza: this.idPieza, idparte: this.idParte }).pipe().subscribe(
      (result) => {
        // de esta forma nos quedamos con la parte que se está buscando y su asociada, para saber si es la principal
        var auxParte = result.pieza.filter(x => x.id == this.idParte)[0];
        console.log("this.idOF: " + this.idOF)
        console.log("this.idParte: " + this.idParte)
        console.log("this.idPieza: " + this.idPieza)
        console.log("auxParte: " + auxParte)
        // si es una pieza tiene que mostrar el control de dependencias entre partes
        if (auxParte.esPieza) {
          if (result.dependencias.length == 0){
            this.dependenciasPieza = [
              {
                id: -1
                , idPieza: this.idPieza
                , orden: 1
                , padre: { id: -1, nombre: this.translate.instant('seleccioneParte') }
                , hijo: { id: -1, nombre: this.translate.instant('seleccioneSubparte') }
                , cantidad: 1
              }]
          }
          else {
            this.dependenciasPieza = [];
            result.dependencias.forEach(
              row => {
                var padre = result.partes.filter(f => f.id == row.idPartePadre);
                var hijo = result.partes.filter(f => f.id == row.idParteHijo);
                if (padre.length > 0 && hijo.length > 0) {
                  var rowDep = {};
                  rowDep["id"] = row.id;
                  rowDep["idPieza"] = this.idPieza;
                  rowDep["orden"] = row.orden;
                  rowDep["padre"] = { id: padre[0].id, nombre: padre[0].nombre };
                  rowDep["hijo"] = { id: hijo[0].id, nombre: hijo[0].nombre };
                  rowDep["cantidad"] = row.cantidad;

                  this.dependenciasPieza.push(rowDep);
                }
              });
            this.dependenciasPieza.push(
              {
                id: -1
                , idPieza: this.idPieza
                , orden: 1
                , padre: { id: -1, nombre: this.translate.instant('seleccioneParte') }
                , hijo: { id: -1, nombre: this.translate.instant('seleccioneSubparte') }
                , cantidad: 1
              });
          }
          console.log(this.comboPartes)
          this.comboPartes = result.partes;
        }
        else {
          this.dependenciasPieza = undefined;
          this.comboPartes = undefined;
        }
        this.isMultiple = auxParte.tieneRutas;
        //DATOS
        if (auxParte.imagenBase64 != "" && auxParte.imagenBase64 != null) {
          this.imageToShow = auxParte.imagenBase64;
        }
        this.tienerutas = auxParte.tieneRutas;
        this.fechainilimite = null;
        this.fechafinlimite = null;
        this.prioridad = 0;
        this.cantidadAgrupada = 1;
        if (auxParte.fechaIni != undefined)
          this.fechainilimite = new Date(auxParte.fechaIni);
        if (auxParte.fechaFin != undefined)
          this.fechafinlimite = new Date(auxParte.fechaFin);
        if (auxParte.prioridad != undefined)
          this.prioridad = auxParte.prioridad;
        if (auxParte.cantidadAgrupada != undefined) {
          this.cantidadAgrupada = auxParte.cantidadAgrupada;
        }
        this.partido = false;
        this.fueraturno = false;
        this.fuerataller = false;
        this.cambiomaquina = false;

        this.ineficencia = false;
        this.continuo = false;

        var tipo_pieza = this.tipoPiezaLista.find(x => x.id === auxParte.tipo_pieza);
        if (tipo_pieza == undefined) tipo_pieza = { id: undefined };
        this.tipoPieza = tipo_pieza;
        var atributosCargados = [];

        if (result.atributos != undefined) {
          result.atributos.forEach(a => {
            atributosCargados.push(this.atributos.find(x => x.id == a.idAtributo));
          });
        }
        //Terminado gorde gero konparatzeko
        this.terminadoInicial = auxParte.terminado;
        this.form = this.formBuilder.group({
          id: this.idPieza,
          textoVersion: [auxParte.textoVersion],
          nombre: [auxParte.nombre, Validators.required],
          numplano: [auxParte.plano, Validators.required],
          referencia: [auxParte.refPieza, Validators.required],
          idERPpieza: [auxParte.idERPPieza,],
          tipo_pieza: [tipo_pieza,],
          tipo_pieza_id: [tipo_pieza.id,],
          espieza: [(auxParte.esPieza == true),],
          limitado: [(auxParte.limitado == "True"),],
          cantidad: [auxParte.cantidad,],
          cantidadAgrupada: [this.cantidadAgrupada,],
          hechos: [auxParte.hechos,],
          coste: [auxParte.coste,],
          dureza: [auxParte.dureza,],
          rutasmultiples: [auxParte.tieneRutas,],
          terminado: [auxParte.terminado,],
          descripcion: [auxParte.descripcion,],
          refparte: [auxParte.refParte,],
          parte: [auxParte.parte,],
          orden: [auxParte.orden,],
          tienerutas: [auxParte.tieneRutas,],
          usaLotes: [auxParte.usaLotes,],
          idERPparte: [auxParte.idERPParte,],
          idOF: [auxParte.idOF,],
          prioridad: [this.prioridad,],
          partido: [this.partido,],
          fueraturno: [this.fueraturno],
          fuerataller: [this.fuerataller,],
          cambiomaquina: [this.cambiomaquina,],
          fechainicio: this.fechainilimite,
          fechafin: this.fechafinlimite,
          ineficencia: [this.ineficencia,],
          continuo: [this.continuo,],
          idpieza: [this.idPieza,],
          idparte: [this.idParte,],
          tiempocambiomaquina: [0,],
          tadescripcion: [auxParte.descripcion,],
          archivo: new FormControl(auxParte.imagen),
          cantidadLote: [auxParte.cantidadLote],
          atributos: new FormControl(atributosCargados),
          peso: [auxParte.peso,],
        });

        this.referencia = auxParte.refPieza;
        this.loadingGraficoTresEjes = false;
        this.limitado = (auxParte.limitado == "True");

        if (this.tienerutas == true) {
          this.activerutas = "active show";
          this.activeoperaciones = "";
          this.idRuta = result.rutas[0].id;
          var found = false;
          if (result.rutas != undefined) {
            var maxIndex = result.rutas.length;
            var index = 0;
            while (!found && index < maxIndex) {
              if (result.rutas[index].principal == 1) {
                this.idRuta = result.rutas[index].id;
                found = true;
              }
              index++;
            }
          }
        } else {
          this.activerutas = "";
          this.activeoperaciones = "active";
          this.idRuta = result.rutas[0].id;

          var found = false;
          if (result.rutas != undefined) {
            var maxIndex = result.rutas.length;
            var index = 0;
            while (!found && index < maxIndex) {
              if (result.rutas[index].principal == 1) {
                this.idRuta = result.rutas[index].id;
                found = true;
              }
              index++;
            }
          }
        }
        this.espieza = this.form.controls["espieza"].value;

        //GRIDS
        var dict: any = {};

        if (result.imagenesMaquinas.length > 0) {
          //Tenemos las imagenes, creamos el diccionario
          result.imagenesMaquinas.forEach(element => {
            dict[element.imagen] = element.imagenBASE64;
          });
        }
        result.operaciones.forEach(function (o) {
          //TIEMPOS
          o.tiempoEstimado = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoS);
          o.tiempoEstimadoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoPreparacionS);
          o.tiempoEstimadoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoTotalS);
          o.tiempoPredictivo = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoS);
          o.tiempoPredictivoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoPreparacionS);
          o.tiempoPredictivoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoTotalS);
          //FIN TIEMPOS
          if (o.idsMaquinas) {
            var idsMaquinas: any = o.idsMaquinas.split(',');

            var maquinas = [];
            var maquinasImagenes = [];
            var prioridadesSeparadas = o.prioridadesMaquinas.split(',');
            var prioridadesFinal = [];
            idsMaquinas.forEach((idm, index) => {
              if (idm != "" && idm != "-1" && idm != "0") {
                prioridadesFinal.push(prioridadesSeparadas[index]);
                var m = result.nombresMaquinas.find(x => x.id == idm);
                if (m != undefined) {
                  maquinas.push(m.nombre);
                  maquinasImagenes.push(m.urlImagen);
                }
              }
            });
            var auxDictPrioridades = {}
            prioridadesFinal.forEach((prioridad, index) => {
              auxDictPrioridades[maquinas[index]] = prioridad;
            });
            o.prioridadMaquinasAux = auxDictPrioridades;
            o.maquina = maquinas.join(', ');
            //Ahora seguimos con las maquinas
            var maquinasAuxi = [];
            var maquinasAuxi2 = [];
            maquinas.sort((a, b) =>
              (Number(auxDictPrioridades[a]) > Number(auxDictPrioridades[b])) ? -1 :
                ((Number(auxDictPrioridades[b]) > Number(auxDictPrioridades[a])) ? 1 : 0)
            );
            maquinas.forEach(maquina => {
              if (maquina == this.translate.instant("desconocido")) {
                maquinasAuxi2.push("undefined");
                maquinasAuxi.push("undefined");
              } else {
                var nombre = maquina.trim().substring(0, 1).toUpperCase();
                var apellido = maquina.trim().substring(1, 2).toUpperCase();
                maquinasAuxi2.push(maquina);
                maquinasAuxi.push(nombre + apellido);
              }
            });
            o.maquinasAuxi2 = maquinasAuxi2.join(";");
            o.maquinasAuxi = maquinasAuxi.join(",");
            //Ahora hay que corregir las imagenes de las maquinas
            var imagenes = maquinasImagenes;
            var auxiImagenes = "";
            imagenes.forEach(imagen => {
              auxiImagenes += dict[imagen] + ";and;";
            });
            o.maquinasIm = auxiImagenes;
          }
          else {
            o.maquina = null;
          }
        }, this);
        console.log(result.operaciones);
        this.operaciones = result.operaciones;
        this.historicocambios = result.historicoCambios;
        this.historicocambios.forEach(element => {
          element.fecha = this.myFunctions.sqlToJsDate(element.fecha.replace("T", " "));
          if (element.valorAnterior instanceof Date) {
            element.valorAnterior = this.myFunctions.sqlToJsDate(element.fecha.replace("T", " "));
            element.valorAnteriorTrad = "fecha";
          } else if (element.valorAnterior == "sinAsignar" || element.valorAnterior == "true" || element.valorAnterior == "false" || element.valorAnterior == "haHabidoCambios") {
            element.valorAnteriorTrad = "traducir"
          }
          if (element.valorNuevo instanceof Date) {
            element.valorNuevo = this.myFunctions.sqlToJsDate(element.fecha.replace("T", " "));
            element.valorNuevoTrad = "fecha";
          } else if (element.valorNuevo == "sinAsignar" || element.valorNuevo == "true" || element.valorNuevo == "false") {
            element.valorNuevoTrad = "traducir"
          }
        });
        this.bom = result.bom;
        this.tenemosBOM = result.bom.length > 0;
        this.series = result.series;
        this.tenemosSeries = result.series.length > 0;
        this.botonesActivados = result.operaciones.length > 0;

        if (result.operaciones[0] != undefined) {
          this.operaciones = result.operaciones;
        }
        this.rutas = result.rutas;
        if (this.rutas != undefined && this.rutas.length == 1 && this.rutas[0].principal)
          this.unicaRutaPrincipal = true;
        else {
          this.unicaRutaPrincipal = false;
        }
        this.isAddMode = false;
        this.gestionDocumentalService.getAllDocumentos(this.idRuta).pipe().subscribe((result: any) => {
          this.gestiondocumental = result;
          this.loadingGestionDocumental = false;
        });
        var that = this;
        setTimeout(function () {
          that.tabstrip.selectTab(0);
          that.selectedTab = 0;
        }, 10);
        // if (this.form.value.tienerutas) {
        //   this.tabstrip.selectTab(2);
        //   this.selectedTab = 2;
        //   //y deshabilitar los necesarios
        //   this.tabstrip.tablist.nativeElement.children[2].classList.remove("k-state-disabled");
        //   this.tabstrip.tablist.nativeElement.children[0].classList.add("k-state-disabled");
        //   this.tabstrip.tablist.nativeElement.children[1].classList.add("k-state-disabled");
        //   this.tabstrip.tablist.nativeElement.children[4].classList.add("k-state-disabled");
        // } else {
        //   this.tabstrip.selectTab(0);
        //   this.selectedTab = 0;
        //   //y deshabilitar los necesarios
        //   this.tabstrip.tablist.nativeElement.children[2].classList.add("k-state-disabled");
        //   this.tabstrip.tablist.nativeElement.children[0].classList.remove("k-state-disabled");
        //   this.tabstrip.tablist.nativeElement.children[1].classList.remove("k-state-disabled");
        //   this.tabstrip.tablist.nativeElement.children[4].classList.remove("k-state-disabled");
        // }
      }
    );
    
  }


  onUploadButtonClick(e: UploadEvent, content) {
    e.preventDefault();

    this._kendoFiles = e.files;
    this.myFiles = [];
    var f: File = this._kendoFiles[0].rawFile;

    const formData: FormData = new FormData();
    formData.append('file', f, f.name);
    formData.append('idPieza', this.idPieza.toString());
    formData.append('cantidad', this.form.value.cantidad.toString());


    this.modalReferenceloadingupload = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    this.piezasService.importarCotas(formData).subscribe((result) => {
      this.modalReferenceloadingupload.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
      } else if (result.batzuk == true) {
        var enCuales = "";
        result.zeintzuk.forEach(element => {
          if (enCuales == "") enCuales = element;
          else enCuales += ", " + element;
        });
        this.alertService.error(this.translate.instant('errorBatzuk') + " | " + this.translate.instant('filas') + ": " + enCuales);
      } else {
        this.alertService.error(this.translate.instant('error'));
      }
      this.myFiles = [];
    });

  }


  public tipo_pieza_valueChange(value: any): void {
    if (value == undefined) this.form.controls['tipo_pieza_id'].setValue(undefined);
    else this.form.controls['tipo_pieza_id'].setValue(value.id);
  }

  btnParte() {

    this.espieza = false;
  }

  btnPieza() {

    this.espieza = true;
  }
  selectionChange(e) {


    if (e == undefined || e.id == undefined) {
      this.espieza = true;
      this.isAddMode == true;
      return;
    }

    var id = e.id;

    this.form.controls['idpieza'].setValue(id);

    this.piezasService.GetByIDSelectedCombo({ idOF: this.idOF, idpieza: id }).subscribe((result) => {
      var data = result.pieza[0];
      var tipo_pieza = this.tipoPiezaLista.find(x => x.id === result.pieza[0].tipo_pieza);
      if (tipo_pieza == undefined) tipo_pieza = { id: undefined };
      this.form.patchValue({
        referecia: data.referencia,
        nombre: data.nombre,
        numplano: data.plano,
        idERPpieza: data.idERPPieza
      });
      this.form.value.tipo_pieza_id = tipo_pieza.id;
      this.form.value.tipo_pieza = tipo_pieza;
      this.tipoPieza = tipo_pieza;
      this.espieza = false;
      this.form.controls['espieza'].setValue(this.espieza);
      this.read = "readonly";
    });
  }



  onClickEliminarOperacion(content, e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    if (this.tituloPopUpPregunta != undefined) {
      this.tituloPopUpPregunta.innerText = this.translate.instant("preguntaeliminarpopup");
    }
    if (this.operacionesseleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  onClickEliminarRuta(content, e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    var auxRuta = this.rutas.filter(x => x.id == this.rutasseleccionados[0])[0];
    if (this.rutasseleccionados.length == 1 && auxRuta?.principal) { //solo tenemos seleccionada para eliminar la principal
      this.alertService.error(this.translate.instant('errorRuta'));
    } else {
      if (this.tituloPopUpPregunta != undefined) {
        this.tituloPopUpPregunta.innerText = this.translate.instant("preguntaeliminarpopup");
      }
      if (this.rutasseleccionados.length > 0) {
        this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      }
    }
  }

  onClickEliminarPieza(content) {
    if (this.tituloPopUpPregunta != undefined) {
      this.tituloPopUpPregunta.innerText = this.translate.instant("popup.preguntaeliminarpopupdetallecompleto");
    }

    this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  onClickDuplicarPieza(contentloading, e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    this.piezasService.duplicar(this.form.value).subscribe((result) => {
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
        this.Atras();
      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }

    });
  }


  cellClick(e) {
    this.idRuta = e.dataItem.idRuta;
    this.idParte = e.dataItem.idParte;
    this.idPieza = e.dataItem.idPieza;
    if (e.columnIndex > 0) {
      this.router.navigate(['operaciones/editar/' + this.idOF + "/" + this.idPieza + "/" + this.idParte + "/" + this.idRuta + "/" + e.dataItem.id + "/1"]);
    }

  }

  cellClickRutas(e) {
    this.idParte = e.dataItem.idParte;
    this.idPieza = e.dataItem.idPieza;
    this.router.navigate(['rutas/editar/' + this.idOF + "/" + this.idPieza + "/" + this.idParte + "/" + e.dataItem.id]);
  }

  onClickEditarOperacion(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    if (this.operacionesseleccionados[0] > 0) {
      this.router.navigate(['operaciones/editar/' + this.idOF + "/" + this.idPieza + "/" + this.idParte + "/" + this.idRuta + "/" + this.operacionesseleccionados[0] + "/1"]);
    }
  }

  onClickTerminarOperacion(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    if (this.operacionesseleccionados[0] > 0) {
      console.log(this.operacionesseleccionados);
      this.modalReference = this.modalService.open(this.popupTerminarOperacion, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  onClickEditarRuta(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    if (this.rutasseleccionados[0] > 0) {
      this.router.navigate(['rutas/editar/' + this.idOF + "/" + this.idPieza + "/" + this.idParte + "/" + this.rutasseleccionados[0]]);
    }
  }

  onClickNuevaOperacion() {
    this.router.navigate(['operaciones/crear/' + this.idOF + "/" + this.idPieza + "/" + this.idParte + "/" + this.idRuta + "/1"]);
  }
  onClickNuevaOperacionPredefinida() {
    this.operacionesPredSelected = [];
    this.modalReference = this.modalService.open(this.anadirOperacionPred, { backdrop: 'static', size: 'xl', keyboard: false, centered: true });
    this.operacionesPredefinidasService.GetAll().subscribe((result: any) => {
      var dict: any = {};

      if (result.imagenesMaquinas.length > 0) {
        //Tenemos las imagenes, creamos el diccionario
        result.imagenesMaquinas.forEach(element => {
          dict[element.imagen] = element.imagenBASE64;
        });
      }

      result.operaciones.forEach(function (o) {
        //TIEMPOS
        o.tiempoEstimado = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoS);
        o.tiempoEstimadoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoPreparacionS);
        o.tiempoEstimadoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoTotalS);
        o.tiempoPredictivo = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoS);
        o.tiempoPredictivoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoPreparacionS);
        o.tiempoPredictivoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoTotalS);
        //FIN TIEMPOS

        if (o.idsMaquinas) {
          var idsMaquinas: any = o.idsMaquinas.split(',');

          var maquinas = [];
          var maquinasImagenes = [];
          var prioridadesSeparadas = o.prioridadesMaquinas.split(',');
          var prioridadesFinal = [];
          idsMaquinas.forEach((idm, index) => {
            if (idm != "" && idm != "-1" && idm != "0") {
              prioridadesFinal.push(prioridadesSeparadas[index]);
              var m = result.nombresMaquinas.find(x => x.id == idm);
              if (m != undefined) {
                maquinas.push(m.nombre);
                maquinasImagenes.push(m.urlImagen);
              }
            }
          });
          var auxDictPrioridades = {}
          prioridadesFinal.forEach((prioridad, index) => {
            auxDictPrioridades[maquinas[index]] = prioridad;
          });
          o.prioridadMaquinasAux = auxDictPrioridades;
          o.maquina = maquinas.join(', ');
          //Ahora seguimos con las maquinas
          var maquinasAuxi = [];
          var maquinasAuxi2 = [];
          maquinas.sort((a, b) =>
            (Number(auxDictPrioridades[a]) > Number(auxDictPrioridades[b])) ? -1 :
              ((Number(auxDictPrioridades[b]) > Number(auxDictPrioridades[a])) ? 1 : 0)
          );
          maquinas.forEach(maquina => {
            if (maquina == this.translate.instant("desconocido")) {
              maquinasAuxi2.push("undefined");
              maquinasAuxi.push("undefined");
            } else {
              var nombre = maquina.trim().substring(0, 1).toUpperCase();
              var apellido = maquina.trim().substring(1, 2).toUpperCase();
              maquinasAuxi2.push(maquina);
              maquinasAuxi.push(nombre + apellido);
            }
          });
          o.maquinasAuxi2 = maquinasAuxi2.join(";");
          o.maquinasAuxi = maquinasAuxi.join(",");
          //Ahora hay que corregir las imagenes de las maquinas
          var imagenes = maquinasImagenes;
          var auxiImagenes = "";
          imagenes.forEach(imagen => {
            auxiImagenes += dict[imagen] + ";and;";
          });
          o.maquinasIm = auxiImagenes;
        }
        else {
          o.maquina = null;
        }
      }, this);
      this.operacionesPred = result.operaciones;
    });
  }

  onClickAceptarAnadirPred() {
    var auxOperacionPred = this.operacionesPred.filter(x => this.operacionesPredSelected.includes(x.id));
    auxOperacionPred.forEach(element => {
      this.operacionesService.creardepredefinido(element.id, this.idRuta).subscribe((result) => {
        this.modalReference.close();
        if (result.error == false) {
          this.CargarDatos();
        } else {
          this.alertService.error(this.translate.instant('error'));
        }
      });
    });
  }

  onClickCancelar() {
    this.operacionesPredSelected = [];
  }

  onClickNuevaRuta() {
    this.router.navigate(['rutas/crear/' + this.idOF + "/" + this.idPieza + "/" + this.idParte]);
  }

  onUploadBOMButtonClick(e, content) {

    this.modalReferenceloadingupload = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    this._kendoFiles = e.files;
    var f: File = this._kendoFiles[0].rawFile;

    const formData: FormData = new FormData();
    formData.append('file', f, f.name);
    formData.append("idruta", this.idRuta.toString());
    this.bomService.importar(formData).subscribe((result) => {
      this.modalReferenceloadingupload.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
        this.CargarDatos();

      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }

    });
  }





  onUploadSeriesButtonClick(e, content) {
    this.modalReferenceloadingupload = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    this._kendoFiles = e.files;
    var f: File = this._kendoFiles[0].rawFile;

    const formData: FormData = new FormData();
    formData.append('idparte', this.idParte.toString());
    formData.append('idruta', this.idRuta.toString());
    formData.append('file', f, f.name);


    this.seriesService.importar(formData).subscribe((result) => {

      this.modalReferenceloadingupload.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
        this.CargarDatos();
      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }
    });

  }



  public onTieneRutasClick() {



  }


  onUploadImagenButtonClick(e: UploadEvent, content) {



    this._kendoFiles = e.files;
    var f: File = this._kendoFiles[0].rawFile;

    const formData: FormData = new FormData();
    formData.append('idparte', this.idParte.toString());
    formData.append('file', f, f.name);

    this.modalReferenceloadingupload = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    this.piezasService.uploadImage(formData).subscribe((result) => {
      this.modalReferenceloadingupload.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });

      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }
    }
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  onSubmit(contentloading, e) {
    this.submitted = true;
    var frogaa = this.user.ocultarParte;
    if (!this.espieza) {
      if (this.form.value.refParte == "") {
        this.errorReferenciaParte = true;
      } else {
        this.errorReferenciaParte = false;
      }
      if (this.form.value.parte == "") {
        this.errorNombreParte = true;
      } else {
        this.errorNombreParte = false;
      }
      if (this.errorReferenciaParte || this.errorNombreParte) {
        return;
      }
    }

    // stop here if form is invalid
    if (this.form.invalid) {
      this.alertService.error(this.translate.instant('error'));
      return;
    }
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    if (this.isAddMode) {
      this.nuevo();
    } else {
      if (this.form.value.terminado && (this.terminadoInicial != this.form.value.terminado)) {
        this.modalReference = this.modalService.open(this.terminarPiezaOperacion, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
        this.modalReferenceloading.close();
      } else {
        this.actualizar(-1);
      }
    }
  }

  async actualizar(valor: number) {
    var filename = "";

    if ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != "")) {
      try {
        var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
        filename = this.form.value.archivo[0].name;
      } catch (error) {
        var archivoBase64: any = "";
      }
    } else {
      var archivoBase64: any = "";
    }

    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.guardando");
    }
    this.form.value.idOF = this.idOF;
    this.piezasService.update(this.form.value, filename, archivoBase64)
      .subscribe((result) => {
        this.modalReferenceloading.close();
        if (result.error == false) {
          var idgenerado = result.id;


          if (this.dependenciasPieza !== undefined){
            //Se preparan las dependencias para poder borrarlas y volver a insertarlas (mirar como se tratan en la API)
            var dtDependencias = [];
            this.dependenciasPieza.forEach(
              dep => {
                if (dep.padre.id != -1 && dep.padre.id != -1) {
                  var rowDep = {};
                  rowDep["id"] = dep.id;
                  rowDep["idPieza"] = dep.idPieza;
                  rowDep["orden"] = dep.orden;
                  rowDep["idPartePadre"] = dep.padre.id;
                  rowDep["idParteHijo"] = dep.hijo.id;
                  rowDep["cantidad"] = dep.cantidad;
  
                  dtDependencias.push(rowDep);
                }
              });
            if (dtDependencias.length == 0) {
              console.log("GO!")
              var rowDep = {};
              rowDep["id"] = -1;
              rowDep["idPieza"] = this.idPieza;
              rowDep["orden"] = 0;
              rowDep["idPartePadre"] = 0;
              rowDep["idParteHijo"] = 0;
              rowDep["cantidad"] = 0;
  
              dtDependencias.push(rowDep);
            }
  
            this.piezasService.update_dependencias(dtDependencias).subscribe(
              (result) => {
                if (this.form.value.terminado && (this.terminadoInicial != this.form.value.terminado)) {
                  console.log("Valor actualizar: " + valor);
                  this.piezasService.cerrarPartesOperaciones(this.idPieza, valor).subscribe((data) => {
                    this.modalReference.close();
                    this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
                    this.CargarDatos();
                    this.router.navigateByUrl('piezas/editar/' + this.idOF + "/" + this.idPieza + "/" + this.idParte);
                    this.isAddMode = false;
                  });
                } else {
                  this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
                  this.CargarDatos();
                  this.router.navigateByUrl('piezas/editar/' + this.idOF + "/" + this.idPieza + "/" + this.idParte);
                  this.isAddMode = false;
                }
              });
          }
          

          if (this.series !== undefined){
            var dtSeries = [];

            this.series.forEach(
              dep => {
                if (dep.idParte != -1) {
                  var rowDep = {};
                  rowDep["id"] = dep.id;
                  rowDep["idParte"] = dep.idParte;
                  rowDep["numeroSerieCliente"] = dep.numeroSerieCliente;
                  rowDep["numeroSerie"] = dep.numeroSerie;
                  rowDep["idEstado"] = dep.idEstado;
                  rowDep["numero"] = dep.numero;
                  rowDep["idRuta"] = dep.idRuta;
                  rowDep["colada"] = dep.colada;
                  rowDep["lote"] = dep.lote;

                  dtSeries.push(rowDep);
                }
              });
            // if (dtSeries.length == 0) {
            //   console.log("GO!")
            //   var rowDep = {};
            //   rowDep["id"] = -1;
            //   rowDep["idPieza"] = this.idPieza;
            //   rowDep["orden"] = 0;
            //   rowDep["idPartePadre"] = 0;
            //   rowDep["idParteHijo"] = 0;
            //   rowDep["cantidad"] = 0;

            //   dtSeries.push(rowDep);
            // }
            
            this.seriesService.update_series(dtSeries).subscribe(
              (result: any)=>{
                if (result.error === false){
                  if (this.form.value.terminado && (this.terminadoInicial != this.form.value.terminado)) {
                    console.log("Valor actualizar: " + valor);
                  } else {
                    this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
                    this.CargarDatos();
                    this.router.navigateByUrl('piezas/editar/' + this.idOF + "/" + this.idPieza + "/" + this.idParte);
                    this.isAddMode = false;
                  }
                } else {
                  this.alertService.error(this.translate.instant('error') + "   " + this.translate.instant('numero-s') + " " + result.idsError);
                }
              }
            );
          }
          else {
            this.alertService.error(this.translate.instant('error'));
          }
        }
          
      });




  }


  async nuevo() {

    var filename = "";

    if ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != "")) {
      var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
      filename = this.form.value.archivo[0].name;
    } else {
      var archivoBase64: any = "";
    }
    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.guardando");
    }
    this.piezasService.create(this.form.value, filename, archivoBase64)
      .subscribe((result) => {
        this.modalReferenceloading.close();
        {
          if (result.error == false) {
            this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
            var idpieza = result.idpieza;
            var idparte = result.idparte;
            this.router.navigateByUrl('piezas/editar/' + this.idOF + "/" + idpieza + "/" + idparte);
            this.isAddMode = false;
          }
          else {
            this.alertService.error(this.translate.instant('error'));
          }
        }
      }
      );
  }

  Atras() {
    this.router.navigate(['OF/editar/' + this.idOF]);
  }

  closeElements() {
    this.modalReference.close();
    this.modalReference = this.modalService.open(this.catidadHechasOperacion, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
    
  }
  closeElementsHechas(valor: number){
    this.modalReference.close();
    console.log("Valor: " + valor);
    this.actualizar(valor);
  }

  private EliminarOperacion(contentloading) {
    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.eliminando");
    }
    this.operacionesService.delete({ ids: this.operacionesseleccionados }).subscribe(

      (data) => {
        this.modalReferenceloading.close();
        if (data.error == false) {
          this.alertService.success(this.translate.instant("ok"), { keepAfterRouteChange: true });
          this.CargarDatos();
        }
        else {
          this.alertService.error(this.translate.instant("error"), { keepAfterRouteChange: true });
        }
      }
    );

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

  }

  private EliminarPieza(contentloading) {

    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.eliminando");
    }

    this.piezasService.delete(this.idParte).subscribe(

      (result) => {
        this.modalReferenceloading.close();
        if (result.error == false) {
          this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
          this.Atras();
        }
        else {
          this.alertService.error(this.translate.instant('error'));
        }


      }

    );

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

  }

  public EliminarRuta(contentloading, e) {
    //buscamos ruta principal y borramos todas menos esa
    this.rutasseleccionados.forEach(element => {
      if (!this.rutas.filter(x => x.id == element)[0].principal) {
        this.rutasService.delete({ idruta: element }).subscribe(
          (result) => {
            this.modalReferenceloading.close();
            if (result.error == false) {
              this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
              this.CargarDatos();
            }
            else {
              this.alertService.error(this.translate.instant('error'));
            }
          });
      }
    });

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

  }

  private EliminarSerie(contentloading) {
    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.eliminando");
    }
    this.seriesService.delete({ ids: this.mySelectionSerie }).subscribe(

      (data) => {
        this.modalReferenceloading.close();
        if (data.error == false) {
          this.alertService.success(this.translate.instant("ok"), { keepAfterRouteChange: true });
          // this.CargarDatos();
          this.series = this.series.filter((element) => !data.listaIds.includes(element.id));
          var numeroNuevo = 1;
          for (let i=0;i<this.series.length;i++){
              this.series[i].numero = numeroNuevo;
              numeroNuevo = numeroNuevo+1;
          }
          var dtSeries = [];

          this.series.forEach(
            dep => {
              if (dep.idParte != -1) {
                var rowDep = {};
                rowDep["id"] = dep.id;
                rowDep["idParte"] = dep.idParte;
                rowDep["numeroSerieCliente"] = dep.numeroSerieCliente;
                rowDep["numeroSerie"] = dep.numeroSerie;
                rowDep["idEstado"] = dep.idEstado;
                rowDep["numero"] = dep.numero;
                rowDep["idRuta"] = dep.idRuta;
                rowDep["colada"] = dep.colada;
                rowDep["lote"] = dep.lote;

                dtSeries.push(rowDep);
              }
            });
            this.seriesService.update_series(dtSeries).subscribe(
              (result: any)=>{
              }
            );
        }
        else {
          this.alertService.error(this.translate.instant("error"), { keepAfterRouteChange: true });
        }
      }
    );

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

  }

  //#region IMAGEN
  public myFiles = [];

  imageToShow: any;
  isImageLoading: boolean;
  createImageFromBase64(image) {
    let objectURL = 'data:image/jpeg;base64,' + image;
    this.imageToShow = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }
  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  //#endregion
  public async selectEventHandler(e: SelectEvent) {
    const that = this;
    var file = e.files[0];
    e.files.forEach((file) => {

      if (!file.validationErrors) {
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid
          };

          that.imagePreviews.unshift(image);
        };

        reader.readAsDataURL(file.rawFile);
      }
    });

    this.imagePreviews.forEach(element => { that.imageToShow = element.src; });
    this.imagePreviews = [];
  }

  /* private log(event: string): void {
    this.events.unshift(`${event}`);
  } */
  public onCantidadChange(value: number) {
    var cantidad = this.form.controls["cantidad"].value;
    var agrupada = this.form.controls["cantidadAgrupada"].value;
    if (cantidad < agrupada) {

      this.form.controls["cantidadAgrupada"].setValue(cantidad);
    }
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;


  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  //BOTONES GESTIÓN DOCUMENTAL
  onCellClickGestionDocumental(e: any) {
    if (e.columnIndex > 0) {
      this.router.navigate(['/piezas/documento/' + this.idOF + '/' + this.idPieza + '/' + this.idParte + '/' + this.idRuta + '/' + e.dataItem.id + "/1"]);
    }
  }
  onClickEditarGestionDocumental(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    if (this.mySelectionGestionDocumental.length > 0) {
      this.router.navigate(['/piezas/documento/' + this.idOF + '/' + this.idPieza + '/' + this.idParte + '/' + this.idRuta + '/' + this.mySelectionGestionDocumental[0] + "/1"]);
    }
  }
  onClickNuevoGestionDocumental(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    this.router.navigate(['/piezas/documento/' + this.idOF + '/' + this.idPieza + '/' + this.idParte + '/' + this.idRuta + '/-1/1']);
  }
  onClickEliminarGestionDocumental(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    this.modalReference = this.modalService.open(this.popupBorrarDocumentos, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  //BOTONES SERIES
  onCellClickSerie(e: any) {
    console.log("cellclick");
    if (e.columnIndex > 0) { //Para que no lo haga en el checkbox
      console.log("id: " + e.columnIndex)
    //   this.router.navigate(['/piezas/documento/' + this.idOF + '/' + this.idPieza + '/' + this.idParte + '/' + this.idRuta + '/' + e.dataItem.id + "/1"]);
    }
  }
  onClickEditarSerie(e) {
    console.log("clickEditar");
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    // if (this.mySelectionSerie.length > 0) {
    //   this.router.navigate(['/piezas/documento/' + this.idOF + '/' + this.idPieza + '/' + this.idParte + '/' + this.idRuta + '/' + this.mySelectionSerie[0] + "/1"]);
    // }
  }
  onClickNuevoSerie(e) {
    console.log("clickNuevo");
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    if (this.series.length < this.form.value.cantidad){
      this.seriesService.insert(this.idParte, this.idRuta).subscribe(
        (data: any) => {
            this.alertService.success(this.translate.instant("ok"), { keepAfterRouteChange: true });
            console.log(data);
            // this.CargarDatos();
            this.seriesService.getById(data.id).subscribe(
                (data2: any) => {
                    if (data2.error != true){
                      const nuevaFila = {
                        id: data.id,
                        idParte: this.idParte,
                        numeroSerieCliente: null,
                        numeroSerie: null,
                        idEstado: 1,
                        numero: data2.numero,
                        idRuta: this.idRuta,
                        colada: '',
                        lote: ''
                      }
                      this.series.push(nuevaFila);
                    }
                    else
                    {
  
                    }
                }
            );
        }
      );
    } else {
      this.alertService.error(this.translate.instant("errorCantidadNSerie"));
    }
    
    // this.router.navigate(['/piezas/documento/' + this.idOF + '/' + this.idPieza + '/' + this.idParte + '/' + this.idRuta + '/-1/1']);
  }
  onClickEliminarSerie(popupBorrarSerie, e) {
    console.log("clickEliminar");
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    
    if (this.tituloPopUpPregunta != undefined) {
      this.tituloPopUpPregunta.innerText = this.translate.instant("preguntaeliminarpopup");
    }
    if (this.mySelectionSerie.length > 0) {
      this.modalReference = this.modalService.open(popupBorrarSerie, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  onClickAutoGenerarSeries(popupAutogenerarSeries,e) {
    e.preventDefault(); //prevent submit
    console.log("ClickAutogenerarSeries BEGIN");
    if (this.tituloPopUpAutogenerar != undefined) {
      this.tituloPopUpAutogenerar.innerText = this.translate.instant("autogenerar");
    }
    this.hasieratuFormAutogenerarSeries();
    this.modalReference = this.modalService.open(popupAutogenerarSeries, { backdrop: 'static', size: 'l', keyboard: false, centered: true });
    console.log("ClickAutogenerarSeries OK");
  }

  autogenerarSeries(){
    console.log("AutogenerarSeries BEGIN");
    if (this.formAutogenerar.value.cantidadF + this.series.length > this.form.value.cantidad){
      this.alertService.error(this.translate.instant("errorCantidadNSerie"));
    } else {
      var todoBien = 0;
      var listaMalos = [];
      var numero = 1;
      for (let i = 0; i< this.series.length; i++){
        numero = numero + 1;
      }
      var primerNumeroConEseNumeroSerie: number; 
      this.conseguirPrimerNumeroConPatron().subscribe(numeroNuevo => {
        primerNumeroConEseNumeroSerie = numeroNuevo;
        for (let i=primerNumeroConEseNumeroSerie; i<= primerNumeroConEseNumeroSerie + this.formAutogenerar.value.cantidadF - 1; i++){
          var numeroSerieAGuardarEnString = i.toString().padStart(this.formAutogenerar.value.cantidadDigitosNSF,'0');
          var numeroSerieNuevo = this.formAutogenerar.value.numeroserieF1.replace('*',numeroSerieAGuardarEnString);
          var numeroSerieClienteNuevo = '';
          if (this.checkBoxNumeroSerieCliente){
            var numeroSerieClienteAGuardarEnString = i.toString().padStart(this.formAutogenerar.value.cantidadDigitosNSF,'0');
            numeroSerieClienteNuevo = this.formAutogenerar.value.numeroserieclienteF1.replace('*',numeroSerieClienteAGuardarEnString);
          }
          this.seriesService.insertConDatos(this.idParte, this.idRuta, this.formAutogenerar.value.coladaF, this.formAutogenerar.value.loteF, numeroSerieNuevo,numeroSerieClienteNuevo, numero, this.formAutogenerar.value.numeroserieF1, this.formAutogenerar.value.numeroserieclienteF1).subscribe(
            (data: any) => {
                console.log(data);
                // this.CargarDatos();
                this.seriesService.getById(data.id).subscribe(
                    (data2: any) => {
                        if (data2.error != true){
                          const nuevaFila = {
                            id: data.id,
                            idParte: this.idParte,
                            numeroSerieCliente: data.numeroSerieCliente,
                            numeroSerie: data.numeroSerie,
                            idEstado: 1,
                            numero: data2.numero,
                            idRuta: this.idRuta,
                            colada: this.formAutogenerar.value.coladaF,
                            lote: this.formAutogenerar.value.loteF
                          }
                          this.series.push(nuevaFila);
                          this.series.sort((a, b) => a.numero - b.numero);
                        }
                        else
                        {
                          todoBien = -1;
                          listaMalos.push(i);
                        }
                    }
                );
            }
          );
          numero = numero + 1;
        }
        if (todoBien < 0){
          this.alertService.error(this.translate.instant("error"), { keepAfterRouteChange: true });
        } else {
          this.modalReference.close();
          this.alertService.success(this.translate.instant("ok"), { keepAfterRouteChange: true });
        }
      });
    }
    console.log("Colada: " + this.formAutogenerar.value.coladaF);
    console.log("Lote: " + this.formAutogenerar.value.loteF);
    console.log("Cantidad: " + this.formAutogenerar.value.cantidadF);
    console.log("AutogenerarSeries OK");
  }

  conseguirPrimerNumeroConPatron(){
    return this.seriesService.conseguirNumeroConPatron(this.formAutogenerar.value.numeroserieF1, this.idParte)
  }

  hasieratuFormAutogenerarSeries(){
    this.formAutogenerar = this.formBuilder.group({
      coladaF: [''],
      loteF: [''],
      cantidadF: [1,],
      numeroserieF1: ['*'],
      numeroserieclienteF1: ['*'],
      checkboxNumeroSerieClienteF: false,
      cantidadDigitosNSF: [1,],
      cantidadDigitosNSCF: [1,],
    });
  }

  preventBackspace(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement; // Obtiene el elemento de entrada
    const char = event.key; // Obtiene el caracter ingresado
    const currentText = input.value; // Obtiene el texto actual del input
    const afterKey = currentText + char; // Obtiene el texto después de ingresar el caracter

    if (char === '*') {
      event.preventDefault(); // Evita que se añada el caracter '*'
    } else if (event.key === 'Backspace') {
      const selection = input.value.substring(input.selectionStart, input.selectionEnd); // Obtiene el texto seleccionado
      const isStarSelected = selection === '*'; // Comprueba si se ha seleccionado un asterisco
      const afterBackspace = currentText.substring(0, input.selectionStart - 1) + currentText.substring(input.selectionEnd); // Obtiene el texto después de borrar

      if (!isStarSelected && afterBackspace.indexOf('*') === -1) { // Si no se ha seleccionado un asterisco y no hay más asteriscos en el texto
        event.preventDefault(); // Evita que se borre el caracter
      }
    }
  }

  handleCheckboxNumeroSerieClienteChange(event: any){
    console.log("checkbox change ! ", event.target.checked)
    this.checkBoxNumeroSerieCliente = event.target.checked;
  }


  btnBorrarDocumentos() {
    this.mySelectionGestionDocumental.forEach(element => {
      if (element > 0) {
        this.isDeletingGestionDocumental = true;
        var ids: string = "";
        var idsRutas: string = "";
        var files: string = "";
        this.mySelectionGestionDocumental.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
            var auxi = this.gestiondocumental.filter(x => x.id == ap)[0];
            var auxFile = auxi.fichero;
            if (files == "")
              files += auxFile;
            else
              files += '&1sep1&' + auxFile;
            if (idsRutas == "")
              idsRutas += auxi.idRuta;
            else
              idsRutas += ',' + auxi.idRuta;
          }
        );
        this.gestionDocumentalService.deleteDocumentos(ids, files, idsRutas).subscribe(() => {
          this.isDeletingGestionDocumental = false;
          this.CargarDatos();
        });
      }
      this.modalReference.close();
    });
  }

  viewPDF(element: any) {
    if (element.esLink) {
      window.open(element.fichero, '_blank');
    } else {
      const win = window.open("", "_blank");
      let html = '';

      html += '<html>';
      html += '<body style="margin:0!important">';
      html += '<embed width="100%" height="100%" src="' + element.pdfBase64 + '" type="application/pdf" />';
      html += '</body>';
      html += '</html>';

      setTimeout(() => {
        win.document.write(html);
        win.document.title = element.fichero;
      }, 0);
    }
  }

  tieneRutasChange() {
    var that = this;
    setTimeout(function () {
      that.tabstrip.selectTab(0);
      that.selectedTab = 0;
    }, 10);
    // if (!this.form.value.tienerutas) { //no tiene rutas
    //   // //if(this.selectedTab == 2){
    //   // //seleccionamos operaciones
    //   // this.tabstrip.selectTab(0);
    //   // this.selectedTab = 0;
    //   // //y deshabilitar los necesarios
    //   // this.tabstrip.tablist.nativeElement.children[2].classList.add("k-state-disabled");
    //   // this.tabstrip.tablist.nativeElement.children[0].classList.remove("k-state-disabled");
    //   // this.tabstrip.tablist.nativeElement.children[1].classList.remove("k-state-disabled");
    //   // this.tabstrip.tablist.nativeElement.children[4].classList.remove("k-state-disabled");
    //   // // }
    // } else { //tiene rutas
    //   // //if(this.selectedTab == 0 || this.selectedTab == 1 || this.selectedTab == 4){
    //   // //seleccionamos rutas
    //   // this.tabstrip.selectTab(2); //lo cambiamos a rutas
    //   // this.selectedTab = 2;
    //   // //y deshabilitar los necesarios
    //   // this.tabstrip.tablist.nativeElement.children[2].classList.remove("k-state-disabled");
    //   // this.tabstrip.tablist.nativeElement.children[0].classList.add("k-state-disabled");
    //   // this.tabstrip.tablist.nativeElement.children[1].classList.add("k-state-disabled");
    //   // this.tabstrip.tablist.nativeElement.children[4].classList.add("k-state-disabled");
    //   // // }
    // }
  }
  onTabSelected(e) {
    this.selectedTab = e.index;
  }

  // DEPENDENCIAS
  borrarDependencia(dependencia) {
    let index = this.dependenciasPieza.findIndex(d => d === dependencia);
    this.dependenciasPieza.splice(index, 1);
    if (this.dependenciasPieza.length == 0)
      this.dependenciasPieza = [
        {
          id: -1
          , idPieza: this.idPieza
          , orden: 1
          , padre: { id: -1, nombre: this.translate.instant('seleccioneParte') }
          , hijo: { id: -1, nombre: this.translate.instant('seleccioneSubparte') }
          , cantidad: 1
        }];
  }
  parteDependenciaSeleccionada(tipo, dependencia, newSelection) {
    var seleccionPreviaId = 0;
    if (tipo == 'padre') {
      seleccionPreviaId = dependencia.padre.id;
      dependencia.padre = newSelection;
    }
    else {
      seleccionPreviaId = dependencia.hijo.id;
      dependencia.hijo = newSelection;
    }
    if (seleccionPreviaId <= 0) {
      // antes estaba sin seleccionar ahora esta seleccionada, si las 2 estan seleccionadas, hay que añadir una linea nueva!
      if (dependencia.padre.id > 0 && dependencia.hijo.id > 0) {
        this.dependenciasPieza.push(
          {
            id: -1
            , idPieza: this.idPieza
            , orden: 1
            , padre: { id: -1, nombre: this.translate.instant('seleccioneParte') }
            , hijo: { id: -1, nombre: this.translate.instant('seleccioneSubparte') }
            , cantidad: 1
          });
      }
    }
  }
  cargaropcionesCombo(tipo, dependencia) {
    if (tipo == 'padre') {
      return this.comboPartes.filter(f => this.padreHijoPosible(f.id, dependencia.hijo.id));
    }
    else {
      return this.comboPartes.filter(f => this.padreHijoPosible(dependencia.padre.id, f.id));
    }
  }
  // DEPENDENCIAS: CHECK RECURSIVO
  padreHijoPosible(idPadre, idHijo) {
    if (idPadre == idHijo)
      return false;
    return this.padreHijoPosible_recursivo(this.dependenciasPieza, idPadre, idHijo);
  }
  padreHijoPosible_recursivo(dependencias, idPadre, idHijo) {
    if (idPadre == idHijo)
      return false;
    var ret = true;
    dependencias.forEach(
      row => {
        if (ret) {
          if (idPadre == row.hijo.id && idHijo == row.padre.id) {
            ret = false;
          }
          else if (idPadre == row.hijo.id) {
            var newDependencias = JSON.parse(JSON.stringify(this.dependenciasPieza));
            let index = newDependencias.findIndex(d => d === row);
            newDependencias.splice(index, 1);
            ret = this.padreHijoPosible_recursivo(newDependencias, row.padre.id, idHijo);
          }
        }
      });
    return ret;
  }

  indexOf(dt, row) {
    return dt.findIndex(d => d === row);
  }

  //#region Terminar operacion boton
  btnTerminarOperacion_CantidadesTeoricas() {
    this.btnTerminarOperacion(1)
  }
  btnTerminarOperacion_CantidadesActuales() {
    this.btnTerminarOperacion(0)
  }
  btnTerminarOperacion(cantidadTeorica) {
    this.operacionesseleccionados.forEach((element, index) => { 
      this.operacionesService.updateTerminado(this.idRuta, element, cantidadTeorica, 1).pipe().subscribe((result) => {
        this.modalReference.close();
        if (result.cantidad > -1) {
          (this.operaciones as any).filter(x => x.id == element)[0].terminado = 1;
          (this.operaciones as any).filter(x => x.id == element)[0].hechos = result.cantidad;
        }
        if (index == this.operacionesseleccionados.length - 1) 
          this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
      });})
  }

  // onExcelExport(e: any){
    //HAU ERABILTZEKO, GRID-EAN HAU GEHITU: (excelExport) = "onExcelExport($event)"
  //   console.log("SARTU DA EIEIEIEIEIEIEIEI")
  //   const data = e.workbook.sheets[0].rows;
  //   for (let i=1;i< data.length;i++){
  //     if (data[i].type == "data"){
  //       const item = data[i];
  //       item.cells[10].value = item.cells[10].value + 2;
  //       item.cells[11].value = item.cells[11].value + 1;
  //       item.cells[12].value = item.cells[12].value + 3;
  //       item.cells[13].value = item.cells[13].value + 4;
  //       console.log(this.form.value.hechos)
  //     }
  //   }
  // }
  //#endregion
}



