import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataStateChangeEvent, PageChangeEvent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { CellClickEvent, RowClassArgs } from "@progress/kendo-angular-grid";

import { process } from '@progress/kendo-data-query';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
/* SERVICES*/
import {
  BonosService, MaquinasService, UsuariosService, PerdidasService, OFService,
  PiezasService, PartesService, OperacionesService, OperariosService, InformeRendimientoService,
  MenuService, InformeProyectosService, AlertService, ValidadorService, ConfiguracionService
} from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { MyFunctions } from '@app/_helpers';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { ActivatedRoute } from '@angular/router';
import { foregroundColorIcon } from '@progress/kendo-svg-icons';
import { ConfiguracionComponent } from '@app/configuracion/configuracion.component';

declare var GanttChartClick: any;

@Component({
  selector: 'app-bonos',
  templateUrl: './bonos.component.html'
})

export class BonosComponent {

  public timeout;

  public tMicroParada: number;
  public tMaximo: number;
  public tMaximoTotal: number;

  public visibleTipo: boolean = true;
  public visibleProyecto: boolean = true;
  public visibleOperario: boolean = true;
  public visibleObservaciones: boolean = true;
  public validable: boolean = false;
  public visiblePerdida: boolean = false;

  public requireProyecto: boolean = false;
  public requirePieza: boolean = false;
  public requireParte: boolean = false;
  public requireOperacion: boolean = false;

  public Tfaltas: string = "0:00:00";
  public Tejecucion: string = "0:00:00";
  public Tparada: string = "0:00:00";
  public TmicroparadaHHMMSS: string = "0:00:00";
  public Tpreparacion: string = "0:00:00";
  public Tmantenimiento: string = "0:00:00";
  public Talarma: string = "0:00:00";
  public Tapagada: string = "0:00:00";

  mySelection: number[] = [];
  mySelectionAux: number[] = [];
  user = this.userService.userValue;
  public dataBonos: any[];
  public timelinePerdidas = new Map();
  public dataBonosSelecteds: any;
  public pageSize = 40;
  public formGroup: FormGroup;
  public fecha: Date;
  public BcargarValoresOperaciones: boolean = false;

  mySelectionBonos: number[] = [];

  public contenidoTooltipTiempos: any;

  //private selectedMaquina: number = 0;
  //private Jmaquinas: any; //se usa esta variable para guardar los datos de las maquinas que nos llegan desde la API
  //public JmaquinasMostradas: any;
  //private secciones: any;
  //public groupedSeccion: GroupResult[];
  //public seccionesSeleccionadas: any[] = [];

  //PANEL MAQUINAS
  private selectedMaquina: any;
  public maquinas: any = [];
  public instalaciones: any = [];
  public maquinasMostradas: any = [];

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  private Jbonos: any; //se usa esta variable para guardar los datos de las maquinas que nos llegan desde la API

  private fechaIni: Date;
  private fechaFin: Date;

  private Jtipos: any;
  private JtiposSelected: any;
  private JDattipos: any;

  private Jperdidas: any;
  private JperdidasSelected: any;
  private JDatperdidas: any;

  private Jproyectos: any;
  private JproyectoSelected: any;
  private JDatproyectos: any;

  private Jpiezas: any;
  private JpiezasSelected: any;
  private JDatpiezas: any;

  private Jpartes: any;
  private JpartesSelected: any;
  private JDatpartes: any;

  private JnSerie: any;
  private JnSerieSelected: any;
  private JDatnSerie: any;

  private JnLotes: any;
  private JnLotesSelected: any;
  private JDatnLotes: any;

  private JnColada: any;
  private JnColadaSelected: any;
  private JDatnColada: any;

  private nuevoNserie: string = "";
  //private nSerieTextInput: boolean = false;
  private cantidadLote: number = 1;
  private lote: string = '';
  private cantidadLoteMax: number = 1;
  private usaLotes: boolean = false;
  private verCantidades: boolean = false;


  private PPcantidadTerminada: number = 0;
  private PPcantidadAchatarrada: number = 0;
  private PPcantidadApartada: number = 0;
  private PPcantidadTotal: number = 0;
  private colada: string = '';
  public terminado: boolean = false;
  public piezaTerminada: boolean = false;

  private Jprogramas: any;
  private JprogramasSelected: any;
  private JDatprogramas: any;

  private Joperarios: any;
  private JoperariosSelected: any;
  private JDatoperarios: any;

  private Jobservacion: any;
  private Jdescripcion: any;

  public aparecer = false;

  // ANNADIR OPERACION
  public esSoloParada: boolean = false;
  public JtiposAnnadirOperacion: any;
  public verNSerie: boolean = false;
  public verLotes: boolean = false;
  public verColada: boolean = false;
  public cantidadTotal: number = 0;
  public cantidadTerminadaMax: number = 9999;
  public cantidadAchatarradaMax: number = 9999;
  public cantidadApartadaMax: number = 9999;
  public verAceptar: boolean = false;
  public isOffline: boolean = false;
  public isTerminado: boolean = false;
  public dataValidador: any[];
  public dataValidadorSelecteds: any;

  // ASIGNAR PERDIDA
  public asignarMultiperdida = [];
  public dataPerdidasGrid: any = [];
  public idIntervaloTiempo: any = {};
  public perdidaAsignada: any = {};
  public fechaLimite = new Date();
  public esParadaSinJustificar = false;
  public esParadaJustificada = false;
  public perdidaSeleccionada: any = {};
  public observacionPerdida = "";
  public justificarHastaFinProceso = false;
  public justificarHastaFin = "";
  public ensennarJustificarHastaFin = false;
  public esUltimoProceso = false;
  public ultimoJustificado = false;

  // EDITAR CANTIDADES
  public hmiCantidadesAsignadas: any = [];
  public actualizarCantidadesAsignadas = [];
  public HMI_cantidadesPiezas_seleccionadas = [];

  public configuracion: any = [];

  // DISTRIBUCION PREPARACION
  public dataDistribucionTiempos: any = [];
  public distribucionTiemposOpen = false;
  public ordenSeleccionado = -1;
  public avisoPreparacionLabel = "";
  public distribucionTiemposPorDefecto = {
    orden: ''
    , numeroOF: ''
    , nombreCliente: ''
    , nombrePieza: ''
    , nombreOperacion: ''
    , referencia: ''
    , nSerie: ''
    , colada: ''
    , lote: ''
    , porcentaje: ''
    , annadir: false
    , eliminar: false
    , idOperacion: -1
    , idProcesos_Tipo: 3
    , cantidad: 0
    , tiempoEstimado: 0
  }
  public fechaIniMin;
  public fechaFinMax;

  public idMaquina: number;

  @ViewChild('popupEditar') popupEditar: NgbModalRef;
  modalReference: NgbModalRef;
  modalReferenceAsignar: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  modalReferenceObservacion: NgbModalRef;
  modalReferenceAvisoPreparacion: NgbModalRef;
  modalReferenceAbrirOperacion: NgbModalRef;
  modalReferenceTerminarOperacion: NgbModalRef;
  modalReferenceDesvalidar: NgbModalRef;

  @ViewChild('popupPregruntar') popupPregruntar: NgbModalRef;
  @ViewChild('popupAsignarPerdida') popupAsignarPerdida: NgbModalRef;
  @ViewChild('popupAnnadirOperacion') popupAnnadirOperacion: NgbModalRef;
  @ViewChild('popupRequiereObservacion') popupRequiereObservacion: NgbModalRef;
  @ViewChild('popupAvisoPreparacion') popupAvisoPreparacion: NgbModalRef;
  @ViewChild('popupAbrirOperacion') popupAbrirOperacion: NgbModalRef;
  @ViewChild('popupAbrirPieza') popupAbrirPieza: NgbModalRef;
  @ViewChild('popupAbrirOperacionYPieza') popupAbrirOperacionYPieza: NgbModalRef;
  @ViewChild('popupTerminarOperacion') popupTerminarOperacion: NgbModalRef;
  @ViewChild('popupDesvalidar') popupDesvalidar: NgbModalRef;

  public loadingPanel: boolean = false;
  public popupPreguntar: boolean = false;
  public popupRespuesta: boolean = false;
  public eliminarPerdida: boolean = false;

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  //"LOAD"


  constructor(private validadorService: ValidadorService, private bonosService: BonosService, private maquinasService: MaquinasService, private userService: UsuariosService, private translateService: TranslateService,
    private modalService: NgbModal, private perdidasService: PerdidasService, private oFService: OFService, private piezasService: PiezasService,
    private partesService: PartesService, private operacionesService: OperacionesService, private operariosService: OperariosService,
    private informeRendimientoService: InformeRendimientoService, private menuService: MenuService, private myFunctions: MyFunctions,
    private informeProyectosService: InformeProyectosService, protected alertService: AlertService, private configuracionService: ConfiguracionService,
    private route: ActivatedRoute) {
    console.log(this.userService.userValue.usuarios_TiposId);
    console.log(this.user.usuarioTipo);
    this.loadingPanel = true;

    this.idMaquina = parseInt(this.route.snapshot.params['id']);

    this.menuService.titulo = this.translateService.instant('bonos').toUpperCase();

    this.userService.user.subscribe(x => this.user = x);

    this.contenidoTooltipTiempos = this.translateService.instant('informacionTiempos');
    this.justificarHastaFin = this.translateService.instant('justificarHastaFin');

    if (this.configuracionService.get_config_global() == undefined) {
      this.configuracionService.load_config_global().subscribe(j => {
        this.configuracionService.set_config_global(j[0]);

        this.configuracion = this.configuracionService.get_config_global();
      });
    } else {
      this.configuracion = this.configuracionService.get_config_global();
    }

    this.cargarGrupos();
    this.cargarAreasProductivas();

    var fechaURL = this.route.snapshot.params['fechafin'];
    if (fechaURL != undefined) {
      this.fecha = this.myFunctions.YYYY_MM_DDToDate(fechaURL);
    } else {
      this.fecha = this.myFunctions.getDateNow();
    }

    //this.cargarBonos();

    this.cargarCombos();
  }


  cargarGrupos() {

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposCargados = true;
      if (this.gruposCargados && this.seccionesCargadas) this.filtrarMaquinasPorAreaProductivaYGrupo();
    });

  }

  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

        this.cargarMaquinas();

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

      this.cargarMaquinas();

    }

  }

  cargarMaquinas() {

    var r1, r2: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        this.seccionesCargadas = true;
        r1 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
      })
    } else {
      this.maquinas = maquinas_model;
      this.seccionesCargadas = true;
      r1 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    }

    //INSTALACIONES
    var instalaciones_model = this.maquinasService.get_instalaciones_model();
    if (instalaciones_model == false) {
      this.maquinasService.GetInstalaciones().subscribe(json => {
        this.maquinasService.set_instalaciones_model(json);
        this.instalaciones = this.maquinasService.get_instalaciones_model();
        r2 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
      })
    } else {
      this.instalaciones = instalaciones_model;
      r2 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    }

  }

  filtrarMaquinasPorAreaProductivaYGrupo(cargarDatos = true) {
    this.mySelectionBonos = [];

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    } else {
      this.secciones.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    }

    //FILTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados && this.gruposSeleccionados.length > 0) {
      this.gruposSeleccionados.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    } else {
      this.grupos.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    }

    this.maquinasMostradas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));

    if (this.maquinasMostradas.length > 0) this.selectedMaquina = this.maquinasMostradas[0].id;
    else this.selectedMaquina = undefined;

    if (this.idMaquina > 0)
      this.selectedMaquina = this.idMaquina;

    //if (cargarDatos) this.identificarMaquinaYCargarDatos();

    if (cargarDatos) {
      //this.cargarBonosZaharra();
      this.cargarBonos(); //MARI
    }

  }

  /////////SELECT ALL GRID/////////
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  public state: any;

  public onSelectAllChange(checkedState: SelectAllCheckboxState) {

    if (checkedState === 'checked') {

      this.mySelection = [];
      this.selectAllState = 'checked';

      this.mySelectionBonos = [];

      var dataParaFiltrar;
      if (this.state == undefined) {
        dataParaFiltrar = this.dataBonos;
      } else {
        this.state.skip = 0;
        this.state.take = 10000000;
        dataParaFiltrar = process(this.dataBonos, this.state).data;
      }

      dataParaFiltrar.forEach(
        operacion => {
          this.mySelectionBonos.push(operacion.identificadorGrid)
        });

    } else {

      this.mySelectionBonos = [];
      this.selectAllState = 'unchecked';

    }

    this.mySelectionAux = this.mySelectionBonos;

  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.mySelectionBonos = this.mySelectionAux;
    this.selectAllState = 'unchecked';
  }

  public pageChange(event: PageChangeEvent): void {
    this.mySelection = this.mySelectionAux;
  }

  public pageChangeBonos(event: PageChangeEvent): void {
    this.mySelectionBonos = this.mySelectionAux;
  }

  /////////FIN SELECT ALL GRID/////////

  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  public cargarCombos() {
    //TIPOS
    var obj: any =
      [
        { "value": "1", "text": "" + this.translateService.instant('ejecucion') + "" },
        { "value": "2", "text": "" + this.translateService.instant('parada') + "" },
        { "value": "3", "text": "" + this.translateService.instant('preparacion') + "" },
        { "value": "4", "text": "" + this.translateService.instant('mantenimiento') + "" },
        { "value": "6", "text": "" + this.translateService.instant('alarma') + "" },
        { "value": "8", "text": "" + this.translateService.instant('apagada') + "" }
      ];
    this.JDattipos = <JSON>obj;
    this.Jtipos = <JSON>obj;
    //PERDIDAS
    this.perdidasService.GetCombo().subscribe(
      json => {
        this.JDatperdidas = json;
        this.Jperdidas = this.JDatperdidas;
      }
    )
    //PROYECTOS
    this.oFService.GetComboConCliente().subscribe(
      json => {
        this.JDatproyectos = json;
        this.Jproyectos = this.JDatproyectos;
      }
    )
    //PIEZAS
    this.piezasService.GetCombo().subscribe(
      json => {
        this.JDatpiezas = json;
        this.Jpiezas = this.JDatpiezas;
      }
    )
    //PARTES
    this.partesService.GetCombo().subscribe(
      json => {
        this.JDatpartes = json;
        this.Jpartes = this.JDatpartes;
      }
    )
    //N SERIE //ES OBLIGATORIO SELECCIONAR PARTE PARA CARGAR SUS PIEZAS
    this.bonosService.Get_piezas_Nserie(0).subscribe(
      json => {
        this.JDatnSerie = json;
        this.JnSerie = [];// this.JDatnSerie;
      }
    )
    //Lote
    this.bonosService.Get_piezas_Lote(0).subscribe(
      json => {
        this.JDatnLotes = json;
        this.JnLotes = this.JDatnLotes;// [];
      }
    );
    //Colada
    this.bonosService.Get_piezas_Colada(0).subscribe(
      json => {
        this.JDatnColada = json;
        this.JnColada = this.JDatnColada;// [];
      }
    );
    //PROGRAMAS
    this.operacionesService.GetCombo().subscribe(
      json => {
        this.JDatprogramas = json;
        this.Jprogramas = this.JDatprogramas;
      }
    )
    //OPERARIOS
    this.operariosService.GetCombo().subscribe(
      json => {
        this.JDatoperarios = json;
        this.Joperarios = this.JDatoperarios;
      }
    )
  }

  //FECHA
  public dateChanged(value: Date): void {

    this.loadingPanel = true;

    if (this.timeout != undefined) clearTimeout(this.timeout);
    //this.cargarBonosZaharra();
    this.timeout = setTimeout(() => {
      this.mySelectionBonos = [];
      this.cargarBonos();
    }, 500);
  }

  public serieChanged(value: String): void {
    this.loadingPanel = false;
    this.loadingPanel = false;
    let nSerievalue = this.JnSerieSelected;
    if (typeof this.JnSerieSelected === 'string') {
      this.JnSerieSelected = undefined;
      // this.JnSerieSelected = {};
      // this.JnSerieSelected['rn'] = this.JDatnSerie.length + 1;

      // var idHP = this.dataBonosSelecteds.idHPi > 0 ? this.dataBonosSelecteds.idHPi : -1;
      // var idOF = this.JprogramasSelected.idOF > 0 ? this.JprogramasSelected.idOF : -1;
      // var idPieza = this.JprogramasSelected.idPieza > 0 ? this.JprogramasSelected.idPieza : -1;
      // var idParte = this.JprogramasSelected.idParte > 0 ? this.JprogramasSelected.idParte : -1;

      // this.JnSerieSelected['idOF'] = idOF; //this.JnSerie[0].idOF;
      // this.JnSerieSelected['idPieza'] = idPieza; //this.JnSerie[0].idPieza;
      // this.JnSerieSelected['idParte'] = idParte; //this.JnSerie[0].idParte;
      // this.JnSerieSelected['nSerie'] = nSerievalue;
      // this.JnSerieSelected['idHP'] = -1; //this.JnSerie[0].idHP;

      // this.JnSerie.push(this.JnSerieSelected);
      // this.JDatnSerie.push(this.JnSerieSelected);
      // // this.cargarCombos();
    }
    // else {
    this.JnSerie = this.JDatnSerie.filter(f => (this.JnSerieSelected != undefined && f.nSerie == this.JnSerieSelected?.nSerie && f.idOF == this.JproyectoSelected.value)
      || (this.JnSerieSelected == undefined && f.idOF == this.JproyectoSelected.value))
    if (this.JnColadaSelected != undefined) {
      if (this.JnSerie.filter(f => f.colada == this.JnColadaSelected.colada).length == 0) {
        this.JnColadaSelected = undefined;
      }
    }
    if (this.JnLotesSelected != undefined) {
      if (this.JnSerie.filter(f => f.lote == this.JnLotesSelected.lote).length == 0) {
        this.JnLotesSelected = undefined;
      }
    }
    // }

  }

  public coladaChanged(value: String): void {
    this.loadingPanel = false;
    let coladaValue = this.JnColadaSelected;
    if (typeof this.JnColadaSelected === 'string') {
      this.JnColadaSelected = undefined;
      // this.JnColadaSelected = {};
      // this.JnColadaSelected['colada'] = coladaValue;
      // this.JnColada.push(this.JnColadaSelected);
      // // this.JDatnColada.push(this.JnColadaSelected);
    }
    // else {
    this.JnSerie = this.JDatnSerie.filter(f => (this.JnColadaSelected != undefined && f.colada == this.JnColadaSelected?.colada && f.idOF == this.JproyectoSelected.value)
      || (this.JnSerieSelected == undefined && f.idOF == this.JproyectoSelected.value))
    // Comprobar que nSeries y lotes que estan seleccionados (si hay alguno) estan en la lista de JnSerie, si no se ponen como undefined
    if (this.JnSerieSelected != undefined) {
      if (this.JnSerie.filter(f => f.nSerie == this.JnSerieSelected.nSerie).length == 0) {
        this.JnSerieSelected = undefined;
      }
    }
    if (this.JnLotesSelected != undefined) {
      if (this.JnSerie.filter(f => f.lote == this.JnLotesSelected.lote).length == 0) {
        this.JnLotesSelected = undefined;
      }
    }

    // }
  }

  public loteChanged(value: String): void {
    this.loadingPanel = false;
    let loteValue = this.JnLotesSelected;
    if (typeof this.JnLotesSelected === 'string') {
      this.JnLotesSelected = undefined;
      // this.JnLotesSelected = {};
      // this.JnLotesSelected['lote'] = loteValue;
      // this.JnLotes.push(this.JnLotesSelected);
      // // this.JDatnLotes.push(this.JnLotesSelected);
    }
    // else {
    this.JnSerie = this.JDatnSerie.filter(f => (this.JnLotesSelected != undefined && f.lote == this.JnLotesSelected?.lote && f.idOF == this.JproyectoSelected.value)
      || (this.JnSerieSelected == undefined && f.idOF == this.JproyectoSelected.value))
    if (this.JnSerieSelected != undefined) {
      if (this.JnSerie.filter(f => f.nSerie == this.JnSerieSelected.nSerie).length == 0) {
        this.JnSerieSelected = undefined;
      }
    }
    if (this.JnColadaSelected != undefined) {
      if (this.JnSerie.filter(f => f.colada == this.JnColadaSelected.colada).length == 0) {
        this.JnColadaSelected = undefined;
      }
    }
    // }
  }

  //CLICK EN MAQUINA
  private maquinaClicked(idMaquina, sender) {

    this.loadingPanel = true;

    document.getElementsByClassName("actual")[0].classList.toggle("actual");
    if (sender.path[0].toString().includes("object HTMLDivElement")) {
      sender.path[0].setAttribute("class", 'clickable actual');
    } else {
      sender.path[1].setAttribute("class", 'clickable actual');
    }
    //var an: any = this.maquinas;
    //an.forEach(
    //  maquina => {
    //    if (maquina.id = idMaquina)
    //      this.selectedMaquina = maquina
    //  });
    this.selectedMaquina = idMaquina;

    //this.cargarBonosZaharra();
    this.cargarBonos();
  }

  //CARGAR DT BONOS
  private cargarBonos() {
    this.loadingPanel = true; //Mari

    var hoy = !(this.myFunctions.datetimeToDate(this.fecha) < this.myFunctions.datetimeToDate(this.myFunctions.getDateNow()))
      && !(this.myFunctions.datetimeToDate(this.fecha) > this.myFunctions.datetimeToDate(this.myFunctions.getDateNow()))

    // this.bonosService.getBonos(this.selectedMaquina, this.dateToYYYYMMDDtHHmmSSz(this.fecha),
    //   this.translateService.instant("ejecucion").toUpperCase(), this.translateService.instant("parada").toUpperCase(), this.translateService.instant("preparacion").toUpperCase(),
    //   this.translateService.instant("mantenimiento").toUpperCase(), this.translateService.instant("alarma").toUpperCase(), this.translateService.instant("apagada").toUpperCase(),
    //   this.translateService.instant("canal").toUpperCase(),
    //   this.translateService.instant("nombrePrograma").toUpperCase(), this.translateService.instant("descripcion").toUpperCase(), this.translateService.instant("operario").toUpperCase(),
    //   this.translateService.instant("of").toUpperCase(), this.translateService.instant("cliente").toUpperCase(), this.translateService.instant("refPieza").toUpperCase(),
    //   this.translateService.instant("pieza").toUpperCase(), this.translateService.instant("operacion").toUpperCase(), this.translateService.instant("operacionNoEncontrada").toUpperCase(),
    //   this.translateService.instant("duracion").toUpperCase(), this.translateService.instant("inicio2").toUpperCase(), this.translateService.instant("fin").toUpperCase(), hoy).subscribe(
    //     json => {
    this.bonosService.getBonosGeneral(this.selectedMaquina, this.dateToYYYYMMDDtHHmmSSz(this.fecha),
      this.translateService.instant("ejecucion").toUpperCase(), this.translateService.instant("parada").toUpperCase(), this.translateService.instant("preparacion").toUpperCase(),
      this.translateService.instant("mantenimiento").toUpperCase(), this.translateService.instant("alarma").toUpperCase(), this.translateService.instant("apagada").toUpperCase(),
      this.translateService.instant("canal").toUpperCase(),
      this.translateService.instant("nombrePrograma").toUpperCase(), this.translateService.instant("descripcion").toUpperCase(), this.translateService.instant("operario").toUpperCase(),
      this.translateService.instant("of").toUpperCase(), this.translateService.instant("cliente").toUpperCase(), this.translateService.instant("refPieza").toUpperCase(),
      this.translateService.instant("pieza").toUpperCase(), this.translateService.instant("operacion").toUpperCase(), this.translateService.instant("operacionNoEncontrada").toUpperCase(),
      this.translateService.instant("duracion").toUpperCase(), this.translateService.instant("inicio2").toUpperCase(), this.translateService.instant("fin").toUpperCase(), hoy).subscribe(
        json => {

          var dataAny: any = json;

          this.Jbonos = json; //Mari
          this.Jbonos.forEach(element => {
            element.cantidadTerminadaAnterior = element.cantidadTerminada;
            element.cantidadAchatarradaAnterior = element.cantidadAchatarrada;
            element.cantidadApartadaAnterior = element.cantidadApartada;
            element.terminadoAnterior = element.terminado;
            element.piezaTerminadaAnterior = element.piezaTerminada;
            element.eliminarPerdida = false;
            element.actualizar_idProcesos_Tipo = false;
            element.abrirOperacion = false;
            element.abrirPieza = false;
            element.idOperacionAnterior = element.idOperacion;
            element.abrirOperacionPreguntado = false;
          });

          var i = 0; // esto se utiliza para identificar el row en el grid
          dataAny.forEach(element => {
            element['horaInicio'] = new Date(element['fechaini']).toLocaleTimeString();
            element['horaFin'] = new Date(element['fechafin']).toLocaleTimeString();
            const fullName = element['operario'].split(' ');
            if (fullName.length == 1) element['iniciales'] = '';
            else element['iniciales'] = (fullName.shift().charAt(0) + fullName.pop().charAt(0)).toUpperCase();
            element['TPorcentajeEjecucion'] = element['tiempoEjecucion'] / element['tiemporeal'] * 100;
            element['TPorcentajeAlarma'] = element['tiempoAlarma'] / element['tiemporeal'] * 100;
            element['TPorcentajeApagado'] = element['tiempoApagada'] / element['tiemporeal'] * 100;
            element['TPorcentajeMantenimiento'] = element['tiempoMantenimiento'] / element['tiemporeal'] * 100;
            element['TPorcentajeParada'] = element['tiempoParada'] / element['tiemporeal'] * 100;
            element['TPorcentajePreparacion'] = element['tiempoPreparacion'] / element['tiemporeal'] * 100;
            element['TPorcentajeMicroParada'] = element['tiempoMicro'] / element['tiemporeal'] * 100;

            element['THHEjecucion'] = this.tiempoReal(element['tiempoEjecucion']);
            element['THHAlarma'] = this.tiempoReal(element['tiempoAlarma']);
            element['THHApagado'] = this.tiempoReal(element['tiempoApagada']);
            element['THHMantenimiento'] = this.tiempoReal(element['tiempoMantenimiento']);
            element['THHParada'] = this.tiempoReal(element['tiempoParada']);
            element['THHPreparacion'] = this.tiempoReal(element['tiempoPreparacion']);
            element['THHMicroParada'] = this.tiempoReal(element['tiempoMicro']);
            element['tiempoRealHH'] = this.calcularTDiferencia(element['fechafin'], element['fechaini'])

            element['coladaAnterior'] = element['colada'];
            element['loteAnterior'] = element['lote'];
            element['nSerieAnterior'] = element['nSerie'];

            element['identificadorGrid'] = i++;

            element['annadirMultiperdida'] = false;
            element['annadirOperacion'] = false;
            element['actualizarPerdida'] = false;
            element['solicitarOperacion'] = false;
            this.timelinePerdidas.set(element.identificadorGrid, { justificarHastaFinProceso: this.justificarHastaFinProceso, idsHistoricoProceso: '', timelinePerdidas: [{}] });

            element['operarioNombre'] = element['operario'];
            if (element['operarioNombre'] == 'undefined') element['operarioNombre'] = 'desconocido';

          });

          this.dataBonos = dataAny;
          console.log(this.fecha);
          this.actualizarCantidadesAsignadas = [];
          this.generarGanttBonos(); //Mari
          //this.updateBotones(); //Mari
          this.loadingPanel = false; //Mari
        }
      )
  }

  public calcularTDiferencia(TFin: string, TInicio: string): string {
    // let HoraFin = new Date(TFin).toLocaleTimeString(); // 6:40:54
    // let HoraInicio = new Date(TInicio).toLocaleTimeString(); // 6:00:54

    // let HoraFinT = HoraFin.split(':');
    // let HoraInicionT = HoraInicio.split(':');

    // let secondsFin = (+HoraFinT[0]) * 60 * 60 + (+HoraFinT[1]) * 60 + (+HoraFinT[2]); 
    // let secondsInicio = (+HoraInicionT[0]) * 60 * 60 + (+HoraInicionT[1]) * 60 + (+HoraInicionT[2]); 
    // return this.tiempoReal(secondsFin-secondsInicio);

    var a: Date = new Date(TInicio);
    var b: Date = new Date(TFin);
    //var dif = Math.abs((b.getTime() - a.getTime()) / 1000)

    return this.myFunctions.milisecondsTo_HH_MM_SS(b.getTime() - a.getTime());
  }

  public tiempoReal(tsegundos: number): string {
    //const sec = parseInt(tsegundos, 10); // convert value to number if it's string
    let hours = Math.floor(tsegundos / 3600); // get hours
    let minutes = Math.floor((tsegundos - (hours * 3600)) / 60); // get minutes
    let seconds = tsegundos - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    let hoursTxt = hours.toString();
    let minutesTxt = minutes.toString();
    let secondsTxt = seconds.toString();
    if (hours < 10) { hoursTxt = "0" + hours.toString(); }
    if (minutes < 10) { minutesTxt = "0" + minutes.toString(); }
    if (seconds < 10) { secondsTxt = "0" + seconds.toString(); }
    return hoursTxt + ':' + minutesTxt + ':' + secondsTxt; // Return is HH : MM : SS
  }

  // private cargarBonosZaharra() {
  //   this.loadingPanel = true;
  //   // cargarGrid
  //   var hoy = !(this.myFunctions.datetimeToDate(this.fecha) < this.myFunctions.datetimeToDate(this.myFunctions.getDateNow()))
  //     && !(this.myFunctions.datetimeToDate(this.fecha) > this.myFunctions.datetimeToDate(this.myFunctions.getDateNow()))

  //   this.bonosService.get(this.selectedMaquina, this.dateToYYYYMMDDtHHmmSSz(this.fecha),
  //     this.translateService.instant("ejecucion").toUpperCase(), this.translateService.instant("parada").toUpperCase(), this.translateService.instant("preparacion").toUpperCase(),
  //     this.translateService.instant("mantenimiento").toUpperCase(), this.translateService.instant("alarma").toUpperCase(), this.translateService.instant("apagada").toUpperCase(),
  //     this.translateService.instant("canal").toUpperCase(),
  //     this.translateService.instant("nombrePrograma").toUpperCase(), this.translateService.instant("descripcion").toUpperCase(), this.translateService.instant("operario").toUpperCase(),
  //     this.translateService.instant("of").toUpperCase(), this.translateService.instant("cliente").toUpperCase(), this.translateService.instant("refPieza").toUpperCase(),
  //     this.translateService.instant("pieza").toUpperCase(), this.translateService.instant("operacion").toUpperCase(), this.translateService.instant("operacionNoEncontrada").toUpperCase(),
  //     this.translateService.instant("duracion").toUpperCase(), this.translateService.instant("inicio2").toUpperCase(), this.translateService.instant("fin").toUpperCase(), hoy).subscribe(
  //       json => {

  //         var dataAny: any = json;

  //         this.Jbonos = json;
  //         this.dataBonos = dataAny;
  //         this.generarGantt();
  //         this.updateBotones();
  //         this.loadingPanel = false;
  //       }
  //     )
  // }


  private updateBotones() {
    var startDate = new Date(this.fecha.getFullYear(), this.fecha.getMonth(), this.fecha.getDate()),
      endDate = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate());

    if (this.tMicroParada == undefined) {
      this.bonosService.getTiemposMicroParadas().subscribe(
        f => {
          var an: any = f[0];
          this.tMicroParada = an.tMicroParada;
          this.tMaximo = an.tMaximo;
          this.tMaximoTotal = an.tMaximoTotal;

          var tMaxParada = 0;
          var tFalta = 0;

          var tEjecucion = 0;
          var tParada = 0;
          var tmicroParada = 0;
          var tPreparacion = 0;
          var tMantenimiento = 0;
          var tAlarma = 0;
          var tApagada = 0;


          this.Jbonos.forEach(f => {
            var a: Date = new Date(f.fechaini);
            var b: Date = new Date(f.fechafin);
            var dif = Math.abs((b.getTime() - a.getTime()) / 1000);
            if (f.idProcesos_Tipo == '2' && f.idPerdida <= '0' && dif > this.tMicroParada) {
              //si es una parada, sin perdida asignada y mayor a microparada
              if (f.tiemporeal > tMaxParada)
                tMaxParada = f.tiemporeal;
              tFalta = tFalta + dif;
            }
            if (f.idProcesos_Tipo == "1")
              tEjecucion = tEjecucion + dif;
            if (f.idProcesos_Tipo == "2")
              if (f.microParada)
                tmicroParada = tmicroParada + dif;
              else
                tParada = tParada + dif;
            if (f.idProcesos_Tipo == "3")
              tPreparacion = tPreparacion + dif;
            if (f.idProcesos_Tipo == "4")
              tMantenimiento = tMantenimiento + dif;
            if (f.idProcesos_Tipo == "6")
              tAlarma = tAlarma + dif;
            if (f.idProcesos_Tipo == "8")
              tApagada = tApagada + dif;
          });
          if (tMaxParada > this.tMaximo)
            this.validable = false;
          else if (tFalta > this.tMaximoTotal)
            this.validable = false;
          else
            this.validable = true;

          this.Tfaltas = this.secondsToHms(tFalta);
          this.Tejecucion = this.secondsToHms(tEjecucion);
          this.Tparada = this.secondsToHms(tParada);
          this.TmicroparadaHHMMSS = this.secondsToHms(tmicroParada);
          this.Tpreparacion = this.secondsToHms(tPreparacion);
          this.Tmantenimiento = this.secondsToHms(tMantenimiento);
          this.Talarma = this.secondsToHms(tAlarma);
          this.Tapagada = this.secondsToHms(tApagada);
          //if (startDate >= endDate)
          //  this.validable = false
        });
    } else {
      var tMaxParada = 0;
      var tFalta = 0;

      var tEjecucion = 0;
      var tParada = 0;
      var tMicroParada = 0;
      var tPreparacion = 0;
      var tMantenimiento = 0;
      var tAlarma = 0;
      var tApagada = 0;

      this.Jbonos.forEach(f => {
        var a: Date = new Date(f.fechaini);
        var b: Date = new Date(f.fechafin);
        var dif = Math.abs((b.getTime() - a.getTime()) / 1000);;
        if (f.idProcesos_Tipo == '2' && f.idPerdida <= '0' && dif > this.tMicroParada) {
          //si es una parada, sin perdida asignada y mayor a microparada
          if (f.tiemporeal > tMaxParada)
            tMaxParada = f.tiemporeal;
          tFalta = tFalta + dif;
        }
        if (f.idProcesos_Tipo == "1")
          tEjecucion = tEjecucion + dif;
        if (f.idProcesos_Tipo == "2")
          if (f.microParada)
            tMicroParada = tMicroParada + dif;
          else
            tParada = tParada + dif;
        if (f.idProcesos_Tipo == "3")
          tPreparacion = tPreparacion + dif;
        if (f.idProcesos_Tipo == "4")
          tMantenimiento = tMantenimiento + dif;
        if (f.idProcesos_Tipo == "6")
          tAlarma = tAlarma + dif;
        if (f.idProcesos_Tipo == "8")
          tApagada = tApagada + dif;
      });

      if (tMaxParada > this.tMaximo)
        this.validable = false;
      else if (tFalta > this.tMaximoTotal)
        this.validable = false;
      else
        this.validable = true;

      this.Tfaltas = this.secondsToHms(tFalta);
      this.Tejecucion = this.secondsToHms(tEjecucion);
      this.Tparada = this.secondsToHms(tParada);
      this.TmicroparadaHHMMSS = this.secondsToHms(tMicroParada);
      this.Tpreparacion = this.secondsToHms(tPreparacion);
      this.Tmantenimiento = this.secondsToHms(tMantenimiento);
      this.Talarma = this.secondsToHms(tAlarma);
      this.Tapagada = this.secondsToHms(tApagada);
      //if (startDate >= endDate)
      //  this.validable = false
    }
  }

  //GENERAR GANTT

  private generarGanttBonos() {

    let tasksNumber = 600,
      startDate = new Date(this.fecha.getFullYear(), this.fecha.getMonth(), this.fecha.getDate()),
      endDate = new Date(this.fecha.getFullYear(), this.fecha.getMonth(), this.fecha.getDate() + 1);

    var hoy = !(this.myFunctions.datetimeToDate(this.fecha) < this.myFunctions.datetimeToDate(this.myFunctions.getDateNow()))
      && !(this.myFunctions.datetimeToDate(this.fecha) > this.myFunctions.datetimeToDate(this.myFunctions.getDateNow()))


    this.informeRendimientoService.get_turnos_new(this.selectedMaquina, this.dateToYYYYMMDDtHHmmSSz(startDate), this.dateToYYYYMMDDtHHmmSSz(endDate), hoy).subscribe(
      json => {
        var turnosArray = [];
        var an: any = json;

        var fechaMin = new Date(startDate);
        var fechaMax = new Date(endDate);
        var diaTurno: Date = new Date(startDate);

        // se cargan los horarios de la maquina como "por defecto" para marcar el inicio y fin del dia en caso de que no se cubra 100% con turnos
        var horarioFueraTurno = an.filter(f => f.clase == 'fuera')
        if (horarioFueraTurno.length > 0) {
          fechaMin = new Date(horarioFueraTurno[0].min);
          fechaMax = new Date(horarioFueraTurno[0].max);
          diaTurno = new Date(horarioFueraTurno[0].turno); //ESTA VARIABLE NOS DIRA QUE DIA TOCA VALIDAR EN EL VALIDADOR
        }

        an.forEach(f => {
          var d = new Date(f.turno);
          if (f.clase != 'fuera') {
            if (d.getTime() == diaTurno.getTime()) {
              turnosArray.push({ min: new Date(f.min), max: new Date(f.max), clase: f.clase });
              if (new Date(f.min) < fechaMin) {
                fechaMin = new Date(f.min);
              }
              if (new Date(f.max) > fechaMax) {
                fechaMax = new Date(f.max);
              }
            }
            else if (d < fechaMin) {
              fechaMin = new Date(f.max);
            }
            else if (d >= fechaMax) {
              fechaMax = new Date(f.min);
            }
          }
        });

        let data = {
          machines: [
            {
              id: '1',
              name: this.translateService.instant("justificado"),
              image: ''
            }, {
              id: '2',
              name: this.translateService.instant("nojustificado"),
              image: ''
            }
          ],
          tasks: []
        };

        var an: any = this.dataBonos;
        //CALCULAR TOOLTIP
        an.forEach(f => {
          f.startDate = f.fechaini;
          f.endDate = f.fechafin;
          f.of = f.numeroOF_OF;

          if ((((f.numeroOF_OF == null || f.numeroOF_OF.length == 0) && (f.idPerdida == null || f.idPerdida == -1)) && [1, 3].includes(f.idProcesos_Tipo)) // si una ejecucion no tiene of y no tiene perdida
            || ((f.idPerdida == null || f.idPerdida == -1) && [2, 6, 8].includes(f.idProcesos_Tipo) && f.tiempoMicroParada <= f.tiemporeal)) // si una parada no tiene perdida
          {
            f.machine = 2;
            // if (f.esPerdidaJustificada) f.backgroundColor="#EACD1F";
            // else f.backgroundColor="#ee8625";
          }
          else {
            f.machine = 1;
            // f.backgroundColor="#22c4c4";
          }

          if ([1, 3].includes(f.idProcesos_Tipo)) { // color ejecucion
            f.backgroundColor = "#C0EADA";
          } else { // color parada
            f.backgroundColor = "#E7CB68";
          }


          var tooltip = ``;

          tooltip = tooltip + `<div class="tooltipa"><p><strong><span class="tooltip-negrita">`;

          if (f.idProcesos_Tipo == 1) tooltip = tooltip + `${this.translateService.instant("ejecucion").toUpperCase()}`;
          if (f.idProcesos_Tipo == 2) tooltip = tooltip + `${this.translateService.instant("parada").toUpperCase()}`;
          if (f.idProcesos_Tipo == 3) tooltip = tooltip + `${this.translateService.instant("preparacion").toUpperCase()}`;
          if (f.idProcesos_Tipo == 4) tooltip = tooltip + `${this.translateService.instant("mantenimiento").toUpperCase()}`;
          if (f.idProcesos_Tipo == 6) tooltip = tooltip + `${this.translateService.instant("alarma").toUpperCase()}`;
          if (f.idProcesos_Tipo == 7) tooltip = tooltip + `${this.translateService.instant("alarma").toUpperCase()}`;
          if (f.idProcesos_Tipo == 8) tooltip = tooltip + `${this.translateService.instant("apagada").toUpperCase()}`;
          if (f.idProcesos_Tipo == 9) tooltip = tooltip + `${this.translateService.instant("canal").toUpperCase()}`;

          tooltip = tooltip + `</strong></label></p>`;

          //if (f.nombrePrograma != "") tooltip = tooltip + `<p><span class="tooltip-title">${this.translateService.instant("nombrePrograma").toUpperCase()}: </span><span class="tooltip-valor">${f.nombrePrograma}<br></span></p>`;

          //if (f.descripcion != "") tooltip = tooltip + `<p><span class="tooltip-title">${this.translateService.instant("descripcion").toUpperCase()}: </span><span class="tooltip-valor">${f.descripcion}<br></span></p>`;

          if (f.operario != 'undefined')
            tooltip = tooltip + `<p><span class="tooltip-title">${this.translateService.instant("operario").toUpperCase()}: </span><span class="tooltip-valor">${f.operario}</span></p>`;
          else
            tooltip = tooltip + `<p><span class="tooltip-title">${this.translateService.instant("operario").toUpperCase()}: </span><span class="tooltip-valor">${this.translateService.instant("desconocido").toUpperCase()}</span></p>`;

          tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("nombrePrograma").toUpperCase()}: </span><span class="tooltip-valor">${f.programa}<br></span></p>`;

          tooltip = tooltip + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;

          if (f.numeroOF_OF != "" && f.idProcesos_Tipo != 4) {
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("of").toUpperCase()}: </span><span class="tooltip-valor">${f.numeroOF_OF}<br></span></p>`;
            //tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("cliente").toUpperCase()}: </span><span class="tooltip-valor">${f.cliente}<br></span></p>`;
            //tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("refPieza").toUpperCase()}: </span><span class="tooltip-valor">${f.refPieza}<br></span></p>`;
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("pieza").toUpperCase()}: </span><span class="tooltip-valor">${f.pieza}<br></span></p>`;
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("operacion").toUpperCase()}: </span><span class="tooltip-valor">${f.nombre_Operacion}<br></span></p>`;
            if (f.verColada)
              tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("colada").toUpperCase()}: </span><span class="tooltip-valor">${f.colada}<br></span></p>`;
            if (f.verLote)
              tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("lote").toUpperCase()}: </span><span class="tooltip-valor">${f.lote}<br></span></p>`;
            if (f.verNSerie)
              tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("nserie").toUpperCase()}: </span><span class="tooltip-valor">${f.nSerie}<br></span></p>`;
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("confirmadas").toUpperCase()}: </span><span class="tooltip-valor">${f.cantidadTerminada}<br></span></p>`;
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("chatarra").toUpperCase()}: </span><span class="tooltip-valor">${f.cantidadAchatarrada}<br></span></p>`;
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("apartada").toUpperCase()}: </span><span class="tooltip-valor">${f.cantidadApartada}<br></span></p>`;

          } else if (f.idProcesos_Tipo != 4) {
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("operacionNoEncontrada").toUpperCase()}<br></span></p>`;
          } else {
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("mantenimiento").toUpperCase()}: </span><span class="tooltip-valor">${f.nombreMantenimiento}<br></span></p>`;
          }

          if (f.perdida != "") {
            tooltip = tooltip + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("perdida").toUpperCase()}: </span><span class="tooltip-valor">${f.perdida}<br></span></p>`;
            if (f.observacionPerdida != "")
              tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("observacion").toUpperCase()}: </span><span class="tooltip-valor">${f.observacionPerdida}<br></span></p>`;
          }

          tooltip = tooltip + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;

          var fIni = new Date(f.fechaini);
          var fFin = new Date(f.fechafin);
          var dif = this.myFunctions.secondsTo_HH_MM_SS((fFin.getTime() - fIni.getTime()) / 1000);

          tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("duracion").toUpperCase()}: </span><span class="tooltip-valor">${dif}<br></span></p>`;
          tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("inicio2").toUpperCase()}: </span><span class="tooltip-valor">${this.myFunctions.dateToString(fIni)}<br></span></p>`;
          tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("fin").toUpperCase()}: </span><span class="tooltip-valor">${this.myFunctions.dateToString(fFin)}<br></span></p>`;

          tooltip = tooltip + `</div>`;

          f.detail2 = tooltip;

          if (this.visibleTipo) {
            f.detail = (d) => f.detail2;
          }
        });
        data.tasks = an;

        var gantt = new GanttChartClick('gantt-chart-bonos', {
          width: 'fit', //introductir un tamaño o poner 'fit' para que se adapte a su padre
          height: 150,
          startDate: fechaMin,
          endDate: fechaMax,
          shifts: turnosArray,
          data: data
        }, this.translateService).on('task_clicked', (id, identificadorGrid) => this.appointmentClick(id, identificadorGrid));


        //.on('task_clicked', function (id) {
        //  var vc = this;
        //  return vc.appointmentClick(id);
        //});

        this.loadingPanel = false;
      }
    )
  }


  appointmentClick(id, identificadorGrid) {
    this.dataBonosSelecteds = this.Jbonos.filter(f => identificadorGrid === f.identificadorGrid);

    if ([1, 3].includes(this.dataBonosSelecteds[0].idProcesos_Tipo)) {
      this.visibleTipo = false; //lehen true
      this.visibleProyecto = true;
      this.visibleOperario = true;
      this.visibleObservaciones = false; //lehen true

      this.cargarValores()

      if (Object.keys(this.dataBonosSelecteds).length > 0) {
        this.modalReference = this.modalService.open(this.popupEditar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      }
    } else {
      this.clickAsignarPerdida(this.popupAsignarPerdida, true);
    }
  }

  //BOTONES EDITAR
  // clickEditar(popupEditar) {
  //   this.dataBonosSelecteds = this.Jbonos.filter(f => this.mySelectionBonos.includes(f.id));
  //   this.visibleTipo = true;
  //   this.visibleProyecto = true;
  //   this.visibleOperario = true;
  //   this.visibleObservaciones = true;

  //   this.cargarValores()

  //   if (Object.keys(this.dataBonosSelecteds).length > 0) {
  //     this.modalReference = this.modalService.open(popupEditar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  //   }
  // }

  cambiarDePerdidasAOperacionRegistro() {
    //this.modalReference.close();
    this.eliminarPerdida = true;
    this.popupPreguntar = false;
    if (this.mySelectionBonos.length == 1) {
      this.cargarValores();
      this.modalReference = this.modalService.open(this.popupEditar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
    else this.alertService.error(this.translateService.instant('errorSeleccion1'));
  }

  clickEditarOF(popupEditar, isFromPerdidas = false) {
    if (!isFromPerdidas) this.dataBonosSelecteds = this.dataBonos.filter(f => this.mySelectionBonos.includes(f.identificadorGrid));
    //this.dataBonosSelecteds = this.dataBonos.filter(f => f.id.includes(this.mySelectionBonos));
    this.visibleTipo = false;
    this.visibleProyecto = true;
    this.visibleOperario = false;
    this.visibleObservaciones = false;
    this.usaLotes = true;

    if (this.dataBonosSelecteds.length > 0) {
      this.BcargarValoresOperaciones = true;
      this.cargarValores();
      this.cargarValoresOperaciones();

      // no influye si tiene perdida o no al asignar una operacion
      // if (this.dataBonosSelecteds[0].esPerdidaJustificada==true && (this.dataBonosSelecteds[0].of ==null || this.dataBonosSelecteds[0].of==''))
      // {
      //   this.popupPreguntar=true;
      //   this.modalReference = this.modalService.open(this.popupPreguntar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      //   //this.eliminarPerdida=true;
      // } else
      // {

      this.modalReference = this.modalService.open(popupEditar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      // }

    }
    else this.alertService.error(this.translateService.instant('errorSeleccion'));

  }

  clickeditarUsuario(popupEditar) {
    this.dataBonosSelecteds = this.dataBonos.filter(f => this.mySelectionBonos.includes(f.identificadorGrid));
    this.visibleTipo = false;
    this.visibleProyecto = false;
    this.visibleOperario = true;
    this.visibleObservaciones = false;
    this.usaLotes = false;
    this.JoperariosSelected = undefined;

    if (this.mySelectionBonos.length == 1) {
      this.cargarValores();
      this.modalReference = this.modalService.open(popupEditar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
    else {  //this.alertService.error(this.translateService.instant('errorSeleccion1'));
      this.cargarValoresOperarios();
      this.modalReference = this.modalService.open(popupEditar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }
  // clickEditarTipo(popupEditar) {
  //   this.dataBonosSelecteds = this.Jbonos.filter(f => this.mySelectionBonos.includes(f.id));
  //   this.visibleTipo = true;
  //   this.visibleProyecto = false;
  //   this.visibleOperario = false;
  //   this.visibleObservaciones = false;

  //   this.cargarValores()

  //   if (Object.keys(this.dataBonosSelecteds).length > 0) {
  //     this.modalReference = this.modalService.open(popupEditar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  //   }
  // }

  cargarValoresOperaciones() {
    this.JproyectoSelected = undefined;
    this.JpiezasSelected = undefined;
    this.JpartesSelected = undefined;
    this.JprogramasSelected = undefined;


    if (this.dataBonosSelecteds.length > 0 && !this.distribucionTiemposOpen) {
      let mismoOF = true;
      let selOF = 0;
      if (this.dataBonosSelecteds.filter(f => f.idOF > 0).length > 0) selOF = this.dataBonosSelecteds.filter(f => f.idOF > 0)[0].idOF;

      let mismaPieza = true;
      let selPieza = 0;
      if (this.dataBonosSelecteds.filter(f => f.idPieza > 0).length > 0) selPieza = this.dataBonosSelecteds.filter(f => f.idPieza > 0)[0].idPieza;

      let mismaParte = true;
      let selParte = 0;
      if (this.dataBonosSelecteds.filter(f => f.idParte > 0).length > 0) selParte = this.dataBonosSelecteds.filter(f => f.idParte > 0)[0].idParte;

      let mismaOperacion = true;
      let selOperacion = 0;
      if (this.dataBonosSelecteds.filter(f => f.idOperacion > 0).length > 0) selOperacion = this.dataBonosSelecteds.filter(f => f.idOperacion > 0)[0].idOperacion;


      this.dataBonosSelecteds.forEach(element => {
        if (selOF > 0 && element.idOF > 0 && selOF != element.idOF) mismoOF = false;
        if (selPieza > 0 && element.idPieza > 0 && selPieza != element.idPieza) mismaPieza = false;
        if (selParte > 0 && element.idParte > 0 && selParte != element.idParte) mismaParte = false;
        if (selOperacion > 0 && element.idOperacion > 0 && selOperacion != element.idOperacion) mismaOperacion = false;
      });

      if (mismoOF) { //this.JproyectoSelected=selOF;
        this.JproyectoSelected = this.JDatproyectos.filter(f => f.value == selOF)[0];
        if (this.JproyectoSelected != undefined) this.ProyectoValueChanged(this.JproyectoSelected);
      }
      if (mismaPieza) { //this.JpiezasSelected=selPieza;
        this.JpiezasSelected = this.JDatpiezas.filter(f => f.value == selPieza)[0];
        if (this.JpiezasSelected != undefined) this.PiezaValueChanged(this.JpiezasSelected);
      }
      if (mismaParte) { //this.JpartesSelected=selParte;
        this.JpartesSelected = this.JDatpartes.filter(f => f.value == selParte)[0];
        if (this.JpartesSelected != undefined) this.ParteValueChanged(this.JpartesSelected);
      }
      if (mismaOperacion) { //this.JprogramasSelected=selOperacion;
        this.JprogramasSelected = this.JDatprogramas.filter(f => f.value == selOperacion)[0];
        if (this.JprogramasSelected != undefined) this.ProgramaValueChanged(this.JprogramasSelected);
      }

      this.JnSerieSelected = this.JnSerie.filter(f => f.nSerie == this.dataBonosSelecteds[0].nSerie)[0];
      this.JnSerie = this.JDatnSerie.filter(f => f.idParte == selParte);
    }
  }

  cargarValoresOperarios() {
    if (this.dataBonosSelecteds.length > 0) {
      let mismoOperario = true;
      let operario = this.dataBonosSelecteds[0].numOperario_Operario;
      this.dataBonosSelecteds.forEach(element => {
        if (element.numOperario_Operario != operario) {
          mismoOperario = false;
        }
      });
      if (mismoOperario)
        this.JoperariosSelected = this.JDatoperarios.filter(f => f.text == this.dataBonosSelecteds.filter(k => k.operario > '')[0].operario)[0];
      else this.JoperariosSelected = undefined;
    }
  }

  clickAnnadirOperacion(popup) {
    this.dataBonosSelecteds = this.dataBonos.filter(f => this.mySelectionBonos.includes(f.identificadorGrid));
    this.cargarValoresAnnadirOperacion();

    this.modalReference = this.modalService.open(popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }
  // Cargar los valores para el popup de annadir operacion
  cargarValoresAnnadirOperacion() {
    this.limpiarValores();
    this.fechaIni = new Date(this.dataBonosSelecteds[0].fechaini);
    this.fechaFin = new Date(this.dataBonosSelecteds[0].fechafin);
    this.periodoValido();

    this.JtiposAnnadirOperacion = this.Jtipos.filter(f => (f.value == '1' || f.value == '3'));
    this.JtiposSelected = this.Jtipos.filter(f => (f.value == '1'))[0];
    // this.JoperariosSelected = this.Joperarios[0];
    this.JoperariosSelected = undefined;
  }
  limpiarValores() {
    this.esSoloParada = false;
    this.verNSerie = false;
    this.verLotes = false;
    this.verColada = false;
    this.verCantidades = false;
    this.cantidadTotal = 0;
    this.JnColadaSelected = undefined;
    this.PPcantidadTerminada = 0;
    this.PPcantidadAchatarrada = 0;
    this.PPcantidadApartada = 0;
    this.cantidadTerminadaMax = 9999;
    this.cantidadAchatarradaMax = 9999;
    this.cantidadApartadaMax = 9999;
    this.verAceptar = false;
    this.isTerminado = false;
    this.JproyectoSelected = undefined;
    this.JpiezasSelected = undefined;
    this.JpartesSelected = undefined;
    this.JprogramasSelected = undefined;
  }

  //Cargar los valores del POPUP
  cargarValores() {
    this.fechaIni = new Date(this.dataBonosSelecteds[0].fechaini);
    this.fechaFin = new Date(this.dataBonosSelecteds[0].fechafin);


    this.requireProyecto = false;
    this.requirePieza = false;
    this.requireParte = false;
    this.requireOperacion = false;

    this.PPcantidadTerminada = this.dataBonosSelecteds[0].cantidadTerminada;
    this.PPcantidadAchatarrada = this.dataBonosSelecteds[0].cantidadAchatarrada;
    this.PPcantidadApartada = this.dataBonosSelecteds[0].cantidadApartada;
    this.PPcantidadTotal = this.dataBonosSelecteds[0].cantidadTotalAHacer;
    this.colada = this.dataBonosSelecteds[0].colada;
    this.terminado = this.dataBonosSelecteds[0].terminado;
    this.piezaTerminada = this.dataBonosSelecteds[0].piezaTerminada;


    this.JtiposSelected = this.JDattipos.filter(f => f.value == this.dataBonosSelecteds[0].idProcesos_Tipo)[0];
    if (this.JtiposSelected.value == '2')
      this.visiblePerdida = true;
    else
      this.visiblePerdida = false;

    this.JperdidasSelected = this.JDatperdidas.filter(f => f.value == this.dataBonosSelecteds[0].idPerdida)[0];
    //proyecto
    //cargarValoresOperaciones
    if (!this.BcargarValoresOperaciones)
      this.JproyectoSelected = this.JDatproyectos.filter(f => f.value == this.dataBonosSelecteds[0].idOF)[0]; //idOF==idCampirnt_proyecto
    //if (this.dataBonosSelecteds[0].idOF > 0) {
    if (this.JproyectoSelected?.value > 0) {
      //pieza
      //      this.Jpiezas = this.JDatpiezas.filter(f => f.idOF == this.dataValidadorSelecteds[0].idCamprint_proyecto);
      this.Jpiezas = this.JDatpiezas.filter(f => f.idOF == this.dataBonosSelecteds[0].idOF);
      //cargarValoresOperaciones
      if (!this.BcargarValoresOperaciones)
        this.JpiezasSelected = this.Jpiezas.filter(f => f.value == this.dataBonosSelecteds[0].idPieza)[0]; //idPieza==idCamprint_pieza
      if (this.dataBonosSelecteds[0].idPieza > 0) {

        //parte  this.JDatpartes.filter(f => f.text=='-')
        this.Jpartes = this.JDatpartes.filter(f => f.idPieza == this.dataBonosSelecteds[0].idPieza);
        //cargarValoresOperaciones
        if (!this.BcargarValoresOperaciones)
          this.JpartesSelected = this.Jpartes.filter(f => f.value == this.dataBonosSelecteds[0].idParte)[0]; //idParte==idCamprint_parte
        if (this.dataBonosSelecteds[0].idParte > 0) {
          //programa
          this.Jprogramas = this.JDatprogramas.filter(f => f.idPieza == this.dataBonosSelecteds[0].idPieza);
          //cargarValoresOperaciones
          if (!this.BcargarValoresOperaciones)
            this.JprogramasSelected = this.Jprogramas.filter(f => f.value == this.dataBonosSelecteds[0].idOperacion)[0]; //idOperacion==idCamprint_programa
          //nSerie (si hay parte se cargan los Nserie de la parte)
          //this.JnSerie = this.JDatnSerie.filter(f => f.idParte == this.dataValidadorSelecteds[0].idCamprint_parte);
          //this.JnSerie = this.JDatnSerie.filter(f => f.idParte== this.dataBonosSelecteds[0].idParte);
          this.JnSerie = this.JDatnSerie.filter(f => f.idPieza == this.dataBonosSelecteds[0].idPieza)
          //this.JnSerieSelected = this.JnSerie.filter(f => f.nSerie == this.dataBonosSelecteds.filter(k => k.pieza != '')[0].nSerie);
          //this.JnSerieSelected = this.JnSerie.filter(f => f.nSerie == Number(this.dataBonosSelecteds.filter(k => k.pieza != '')[0].nSerie))[0];
          if (this.dataBonosSelecteds[0].nuevoNserie.length == 0)
            this.cargarNserie(this.dataBonosSelecteds[0].idHPi, this.dataBonosSelecteds[0].nSerie);
          else
            this.cargarNserie(this.dataBonosSelecteds[0].idHPi, this.dataBonosSelecteds[0].nuevoNserie);//idHPi, nuevoNserie
        } else {
          this.Jprogramas = this.JDatprogramas;
          this.JprogramasSelected = undefined;
          //nSerie (si no hay parte se cargan los Nserie de la pieza)
          this.JnSerie = this.JDatnSerie.filter(f => f.idPieza == this.dataBonosSelecteds[0].idPieza);
          if (this.dataBonosSelecteds[0].nuevoNserie.length == 0)
            this.cargarNserie(this.dataBonosSelecteds[0].idHPi, this.dataBonosSelecteds[0].nSerie);
          else
            this.cargarNserie(this.dataBonosSelecteds[0].idHPi, this.dataBonosSelecteds[0].nuevoNserie);
        }
      }
      else {
        this.Jpartes = this.JDatpartes;
        this.JpartesSelected = undefined;
        this.Jprogramas = this.JDatprogramas;
        this.JprogramasSelected = undefined;
        //nSerie (si no hay pieza se cargan los Nserie del proyecto)
        this.JnSerie = this.JDatnSerie.filter(f => f.idOF == this.dataBonosSelecteds[0].idOF);
        //this.cargarNserie(this.dataBonosSelecteds[0].idHPi, this.dataBonosSelecteds[0].nuevoNserie);
        if (this.dataBonosSelecteds[0].nuevoNserie.length == 0)
          this.cargarNserie(this.dataBonosSelecteds[0].idHPi, this.dataBonosSelecteds[0].nSerie);
        else
          this.cargarNserie(this.dataBonosSelecteds[0].idHPi, this.dataBonosSelecteds[0].nuevoNserie);
      }
    } else {
      this.Jpiezas = this.JDatpiezas;
      this.JpiezasSelected = undefined;
      this.Jpartes = this.JDatpartes;
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas;
      this.JprogramasSelected = undefined;
      //nSerie (si no hay proyecto se carga todo)
      this.JnSerie = this.JDatnSerie;
      // this.nSerieTextInput = false;
      this.JnSerieSelected = undefined;
      this.nuevoNserie = "";
      this.hmiCantidadesAsignadas = [];
    }


    this.cargarCantidadLotes(this.usaLotes, this.dataBonosSelecteds[0].lote);

    this.JoperariosSelected = this.JDatoperarios.filter(f => f.text == this.dataBonosSelecteds.filter(k => k.operario > '')[0].operario)[0];
    this.JnColadaSelected = this.JnColada.filter(f => f.colada == this.dataBonosSelecteds[0].colada)[0];
    this.JnLotesSelected = this.JnLotes.filter(f => f.lote == this.dataBonosSelecteds[0].lote)[0];

    if (this.distribucionTiemposOpen) {
      this.JnColadaSelected = undefined;
      this.JnLotesSelected = undefined;
      this.JnSerieSelected = undefined;
    }
    //this.JoperariosSelected = this.JDatoperarios.filter(f => f.valor == this.dataValidadorSelecteds[0].numOperario_Operario)[0];

    //this.Jdescripcion = this.dataBonosSelecteds[0].descripcion;
    //this.Jobservacion = this.dataBonosSelecteds[0].observacion;

  }
  //CARGAR N SERIE
  cargarNserie(idHPi, nuevoNserie) {
    //this.nSerieTextInput = true;
    this.nuevoNserie = nuevoNserie;
    var an: any = this.JnSerie;
    // an.forEach(
    //   row => {
    //     if (row.idPans > 0)
    //       this.nSerieTextInput = false;
    //   });

    if (idHPi > 0)
      this.JnSerieSelected = this.JnSerie.filter(f => f.idHP == idHPi)[0];
    else this.JnSerieSelected = this.JnSerie.filter(f => f.nSerie == nuevoNserie)[0]
  }

  // CARGAR CANTIDAD LOTES
  cargarCantidadLotes(usaLotes, cantidadLote) {
    if (usaLotes > 0) {
      //USA LOTES
      //this.cantidadLote = cantidadLotesHistoricoOperacion;
      //this.cantidadLoteMax = cantidadParte;
      this.lote = cantidadLote;
      this.usaLotes = true;
    } else {
      //NO USA LOTES
      //this.cantidadLote = 1;
      //this.cantidadLoteMax = 1;
      this.lote = cantidadLote;
      this.usaLotes = false;
    }
    if (this.dataBonosSelecteds.length == 1) { this.verCantidades = true; }
    else { this.verCantidades = false; }
  }

  //BOTONES FILTRO!
  // clickTodo() {
  //   //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
  //   this.mySelectionBonos = [];
  //   this.selectAllState = 'unchecked';
  //   this.dataBonos = this.Jbonos;
  //   this.generarGantt();
  // }
  // clickSinOF() {
  //   //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
  //   this.mySelectionBonos = [];
  //   this.selectAllState = 'unchecked';
  //   this.dataBonos = this.Jbonos.filter(function (i, n) {
  //     return i.numeroOF_OF === '';
  //   });
  //   this.generarGantt();
  // }
  // clickSinOperario() {
  //   //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
  //   this.mySelectionBonos = [];
  //   this.selectAllState = 'unchecked';
  //   this.dataBonos = this.Jbonos.filter(function (i, n) {
  //     return i.numOperario_Operario <= 0;
  //   });
  //   this.generarGantt();
  // }
  // clickSinPerdida() {
  //   //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
  //   this.mySelectionBonos = [];
  //   this.selectAllState = 'unchecked';
  //   var variable_tMicroParada = this.tMicroParada
  //   this.dataBonos = this.Jbonos.filter(function (i, n) {
  //     return i.idPerdida === 0 && (((i.idProcesos_Tipo === 2 || i.idProcesos_Tipo === 6) && i.tiemporeal > variable_tMicroParada) || i.idProcesos_Tipo === 8) ;
  //   });
  //   this.generarGantt();
  // }
  // clickEjecuciones() {
  //   //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
  //   this.mySelectionBonos = [];
  //   this.selectAllState = 'unchecked';
  //   this.dataBonos = this.Jbonos.filter(function (i, n) {
  //     return i.idProcesos_Tipo === 1;
  //   });
  //   this.generarGantt();
  // }
  // clickParadas() {
  //   //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
  //   this.mySelectionBonos = [];
  //   this.selectAllState = 'unchecked';
  //   this.dataBonos = this.Jbonos.filter(function (i, n) {
  //     return i.idProcesos_Tipo === 2 && i.microParada == 0;
  //   });
  //   this.generarGantt();
  // }
  // clickMicroParadas() {
  //   //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
  //   this.mySelectionBonos = [];
  //   this.selectAllState = 'unchecked';
  //   this.dataBonos = this.Jbonos.filter(function (i, n) {
  //     return i.idProcesos_Tipo === 2 && i.microParada == 1;
  //   });
  //   this.generarGantt();
  // }
  // clickPreparaciones() {
  //   //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
  //   this.mySelectionBonos = [];
  //   this.selectAllState = 'unchecked';
  //   this.dataBonos = this.Jbonos.filter(function (i, n) {
  //     return i.idProcesos_Tipo === 3;
  //   });
  //   this.generarGantt();
  // }
  // cickMantenimientos() {
  //   //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
  //   this.mySelectionBonos = [];
  //   this.selectAllState = 'unchecked';
  //   this.dataBonos = this.Jbonos.filter(function (i, n) {
  //     return i.idProcesos_Tipo === 4;
  //   });
  //   this.generarGantt();
  // }
  // clickAlarmas() {
  //   //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
  //   this.mySelectionBonos = [];
  //   this.selectAllState = 'unchecked';
  //   this.dataBonos = this.Jbonos.filter(function (i, n) {
  //     return i.idProcesos_Tipo === 6;
  //   });
  //   this.generarGantt();
  // }
  // clickApagadas() {
  //   //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
  //   this.mySelectionBonos = [];
  //   this.selectAllState = 'unchecked';
  //   this.dataBonos = this.Jbonos.filter(function (i, n) {
  //     return i.idProcesos_Tipo === 8;
  //   });
  //   this.generarGantt();
  // }

  //POPUP : FILTRO
  tipoChanged(value: any) {
    if (value != undefined)
      if (value.value == '2')
        this.visiblePerdida = true;
      else
        this.visiblePerdida = false;
  }
  ProyectoValueChanged(value: any) {
    if (value != undefined) {
      this.Jpiezas = this.JDatpiezas.filter(f => f.idOF == value.value);
      this.JpiezasSelected = undefined;
      this.Jpartes = this.JDatpartes.filter(f => f.idOF == value.value);
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas.filter(f => f.idOF == value.value);
      this.JprogramasSelected = undefined;
      //nSerie (si no hay pieza se cargan los Nserie del proyecto)
      this.JnSerie = this.JDatnSerie.filter(f => f.idOF == value.value);
      this.JnSerieSelected = undefined;
      this.cargarNserie(this.dataBonosSelecteds[0].idHPi, this.dataBonosSelecteds[0].nuevoNserie);
    } else {
      this.Jpiezas = this.JDatpiezas
      this.JpiezasSelected = undefined;
      this.Jpartes = this.JDatpartes
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas
      this.JprogramasSelected = undefined;
      //nSerie (si no hay proyecto se carga todo)
      this.JnSerie = this.JDatnSerie;
      this.JnSerieSelected = undefined;
    }
    //this.alertService.warn(this.translateService.instant('nSerieVacio') + '<br />'+ this.translateService.instant('piezaVacio') + '<br />'+this.translateService.instant('programaVacio'));


  }
  PiezaValueChanged(value: any) {
    if (value != undefined) {
      this.JproyectoSelected = this.Jproyectos.filter(f => f.value == value.idOF)[0];
      this.Jpartes = this.JDatpartes.filter(f => f.idPieza == value.value);
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas.filter(f => f.idPieza == value.value);
      this.JprogramasSelected = undefined;
      //nSerie (si no hay parte se cargan los Nserie de la pieza)
      this.JnSerie = this.JDatnSerie.filter(f => f.idPieza == value.value);
      this.JnSerieSelected = undefined;
      this.cargarNserie(this.dataBonosSelecteds[0].idHPi, this.dataBonosSelecteds[0].nuevoNserie);


    } else {
      this.Jpartes = this.JDatpartes.filter(f => f.idOF == this.JproyectoSelected.value);
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas.filter(f => f.idOF == this.JproyectoSelected.value);
      this.JprogramasSelected = undefined;
      //nSerie (si no hay pieza se cargan los Nserie del proyecto)
      this.JnSerie = this.JDatnSerie.filter(f => f.idOF == this.JproyectoSelected);
      this.JnSerieSelected = undefined;

      this.cargarNserie(this.dataBonosSelecteds[0].idHPi, this.dataBonosSelecteds[0].nuevoNserie);
    }
    //this.alertService.warn(this.translateService.instant('programaVacio')+'<br />'+this.translateService.instant('nSerieVacio'));
  }
  ParteValueChanged(value: any) {
    if (value != undefined) {
      this.JproyectoSelected = this.Jproyectos.filter(f => f.value == value.idOF)[0];
      this.JpiezasSelected = this.Jpiezas.filter(f => f.value == value.idPieza)[0];
      this.Jprogramas = this.JDatprogramas.filter(f => f.idParte == value.value);
      this.JprogramasSelected = undefined;
      this.cantidadLoteMax = this.Jpartes.filter(f => f.value == value.value)[0].cantidadLotesParte;
      //nSerie (si hay parte se cargan los Nserie de la parte)
      this.JnSerie = this.JDatnSerie.filter(f => f.idParte == value.id);
      this.JnSerieSelected = undefined;
      this.cargarNserie(this.dataBonosSelecteds[0].idHPi, this.dataBonosSelecteds[0].nuevoNserie);
    } else {
      this.Jprogramas = this.JDatprogramas.filter(f => f.idPieza == this.JpiezasSelected.value);
      this.JprogramasSelected = undefined;
      this.cantidadLoteMax = 1;
      //nSerie (si no hay parte se cargan los Nserie de la pieza)
      this.JnSerie = this.JDatnSerie.filter(f => f.idPieza == this.JpiezasSelected);
      this.JnSerieSelected = undefined;
      this.cargarNserie(this.dataBonosSelecteds[0].idHPi, this.dataBonosSelecteds[0].nuevoNserie);
    }
  }
  ProgramaValueChanged(value: any) {
    if (value != undefined) {

      //#region Cantidades
      this.bonosService.getCantidades(this.dataBonosSelecteds.map(function (value) { return value.idHO }).join(","),
        this.dataBonosSelecteds.map(function (value) { return value.id.replaceAll(";", ",") }).join(","), this.selectedMaquina, this.fecha).subscribe(json => {
          this.hmiCantidadesAsignadas = json;
          this.hmiCantidadesAsignadas.forEach((element, index) => {
            element.eliminar = false;
            element.insertar = false;
            element.terminado = false;
            element.quitarTerminado = false;
            element.idHistorico_operaciones_Anterior = element.idHistorico_operaciones;
            element.idOperacionAnterior = element.idOperacion;
            element.piezaTerminada = false;
            element.quitarPiezaTerminada = false;
            element.identificador = index + "_" + this.myFunctions.dateToHHMMSS(new Date(element.fecha));
          });
        });
      //#endregion

      this.JproyectoSelected = this.Jproyectos.filter(f => f.value == value.idOF)[0];
      this.JpiezasSelected = this.Jpiezas.filter(f => f.value == value.idPieza)[0];
      this.JpartesSelected = this.Jpartes.filter(f => f.value == value.idParte)[0];
      this.JnSerie = this.JDatnSerie.filter(f => f.idParte == value.idParte);
    } else {
      this.hmiCantidadesAsignadas = [];
    }
  }

  //actualizar Bonos, POPUPeko botoia
  actualizarJBonos() {
    //actualizar el JSON de los datos del bonos
    //si programa esta visible, tiene que estar seleccionado!
    var salir = false;
    var textoAlert = '';

    var ofSinSeleccionar = this.JprogramasSelected == undefined && (this.JpiezasSelected == undefined || this.JproyectoSelected == undefined)
    var piezaSinSeleccionar = (this.JprogramasSelected == undefined || this.JproyectoSelected == undefined) && this.JpiezasSelected == undefined
    var operacionSinSeleccionar = (this.JprogramasSelected == undefined || this.JpiezasSelected == undefined) && this.JproyectoSelected == undefined
    var nSerieSinSeleccionar = (this.JnSerieSelected == undefined && this.user.verNSerie) && this.JproyectoSelected != undefined
    var coladaSinSeleccionar = (this.JnColadaSelected == undefined && this.user.verColada) && this.JproyectoSelected != undefined
    var loteSinSeleccionar = (this.JnLotesSelected == undefined && this.user.verLote) && this.JproyectoSelected != undefined
    var arrayBool = [ofSinSeleccionar, piezaSinSeleccionar, operacionSinSeleccionar, nSerieSinSeleccionar, coladaSinSeleccionar, loteSinSeleccionar]
    if (this.visibleProyecto && (arrayBool.filter(Boolean).length != 6 && arrayBool.filter(Boolean).length != 0)) {
      if (this.JprogramasSelected == undefined) textoAlert = textoAlert + '<br />' + this.translateService.instant('programaVacio');
      if (this.JpiezasSelected == undefined) textoAlert = textoAlert + '<br />' + this.translateService.instant('piezaVacio');
      if (this.JproyectoSelected == undefined) textoAlert = textoAlert + '<br />' + this.translateService.instant('proyectoVacio');

      if (this.user.verNSerie)
        if (this.JnSerieSelected == undefined) textoAlert = textoAlert + '<br />' + this.translateService.instant('nSerieVacio');
      if (this.user.verColada)
        if (this.JnColadaSelected == undefined) textoAlert = textoAlert + '<br />' + this.translateService.instant('coladaVacio');
      if (this.user.verLote)
        if (this.JnLotesSelected == undefined) textoAlert = textoAlert + '<br />' + this.translateService.instant('loteVacio');

      this.alertService.warn(textoAlert);
    }
    else {
      if (!salir) {

        var an: any = [];
        //CUANDO SE PASO DE HISTORICO PROCESOS A HISTORICO TURNOS SE TUBO QUE AÑADIR ESTA LINEA
        //var anLAG: any = this.dataBonosSelecteds;
        //Mari: nik komentatu ditut, orain ez dugu IDhistorico lerrorik eta...
        // anLAG.forEach(
        //   row => {
        //     var anLAG2: any = this.dataBonos;
        //     anLAG2.forEach(
        //       row2 => {
        //         if (row2.iDhistorico == row.iDhistorico)
        //           an.push(row2)
        //       });
        //   }
        // );
        an = this.dataBonos;


        an.forEach(f => {

          //if ultimo:
          f.cantidadTerminadaAnterior = f.cantidadTerminada;
          f.cantidadAchatarradaAnterior = f.cantidadAchatarrada;
          f.cantidadApartadaAnterior = f.cantidadApartada;
          f.terminadoAnterior = f.terminado;
          f.piezaTerminadaAnterior = f.piezaTerminada;


          f.eliminarPerdida = false;
          if (this.visibleTipo) {

            f.idProcesos_Tipo = Number(this.JtiposSelected.value);
            switch (this.JtiposSelected.value) {
              case '1':
                f.machine = 1;
                f.backgroundColor = "#C0EADA";
                f.procesos_Tipo = "ejecucion"; break;
              case '2':
                f.machine = 2;
                f.backgroundColor = "#E7CB68";
                f.procesos_Tipo = "parada"; break;
              case '3':
                f.machine = 1;
                f.backgroundColor = "#096844";
                f.procesos_Tipo = "preparacion"; break;
              case '4':
                f.machine = 3;
                f.backgroundColor = "#99AFC6";
                f.procesos_Tipo = "mantenimiento"; break;
              case '6':
                f.machine = 4;
                f.backgroundColor = "#D33737";
                f.procesos_Tipo = "alarma"; break;
              case '8':
                f.machine = 5;
                f.backgroundColor = "#424242";
                f.procesos_Tipo = "apagada"; break;
            }
            if (this.JperdidasSelected != undefined) {
              f.idPerdida = this.JperdidasSelected.value;
              f.perdida = this.JperdidasSelected.text;
              f.observacion = this.Jobservacion;
            }
            else {
              f.idPerdida = 0;
              f.perdida = '';
              f.observacion = '';
            }
          }

          if (this.visibleProyecto) {
            if (this.bonoEnLista(f, this.dataBonosSelecteds)) {

              //#region Se comprueba que no este la misma operacion terminada
              if (this.terminado && !f.terminado && f.operacionTerminada) { // Si no esta terminado y se quiere terminar pero anteriormente hay una operacion terminada
                // f.terminado = this.terminado;
                if (this.modalReferenceTerminarOperacion == undefined)
                  this.modalReferenceTerminarOperacion = this.modalService.open(this.popupTerminarOperacion, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
              } else if (this.terminado && !f.terminado && !f.operacionTerminada) { // Si no esta terminado y se quiere terminar se necesita indicar que la opereacion ya esta terminada
                f.terminado = this.terminado;
                this.dataBonos.forEach(element => {
                  if (element.idOperacion == f.idOperacion) {
                    element.operacionTerminada = true;
                  }
                });

              } else if (!this.terminado) {
                f.terminado = this.terminado;
                this.dataBonos.forEach(element => {
                  if (element.idOperacion == f.idOperacion) {
                    element.operacionTerminada = false;
                  }
                });
              }

              // Tratar las piezas terminadas, si se vuelven a terminar entonces las demas terminadas se quitan
              this.dataBonos.forEach(element => {
                if (element.piezaTerminada && element.idOperacion == f.idOperacion && element.nSerie == f.nSerie) {
                  element.piezaTerminada = false;
                }
              });
              f.piezaTerminada = this.piezaTerminada;
              //#endregion


              // Si la operacion anterior estaba terminada y ahora se ha cambiado de operacion preguntar si se quiere quitar lo terminado
              var operacionSeleccionada = this.JprogramasSelected == undefined ? -1 : this.JprogramasSelected.value;
              if (f.idOperacion != operacionSeleccionada && (f.terminadoAnterior || f.piezaTerminadaAnterior)) {
                f.abrirOperacionPreguntar = true;
                f.abrirOperacion = false;
                f.abrirPieza = false;
                f.terminado = false;
                if (this.modalReferenceAbrirOperacion == undefined) {
                  if (f.terminadoAnterior && f.piezaTerminadaAnterior) this.modalReferenceAbrirOperacion = this.modalService.open(this.popupAbrirOperacionYPieza, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
                  else if (f.terminadoAnterior) this.modalReferenceAbrirOperacion = this.modalService.open(this.popupAbrirOperacion, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
                  else if (f.piezaTerminadaAnterior) this.modalReferenceAbrirOperacion = this.modalService.open(this.popupAbrirPieza, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
                }
              }

              if (this.eliminarPerdida) {
                f.eliminarPerdida = true;
                f.perdida = '';
              }
              if (this.JprogramasSelected == undefined) {

                f.idOF = -1;
                f.numeroOF_OF = '';
                //f.cliente = '';
                f.lote = '';
                f.colada = '';

                f.idPieza = -1;
                f.pieza = '';

                f.idParte = -1;
                f.parte = '';

                f.cantidadTerminada = 0;
                f.cantidadAchatarrada = 0;
                f.cantidadApartada = 0;

                f.idOperacion = -1;
                f.nombre_Operacion = '';

                f.idCamprint_programa = -1;
                f.programa = '';

              }
              else {
                f.idOF = this.JproyectoSelected.value;
                f.numeroOF_OF = this.JproyectoSelected.text;
                //f.cliente = this.JproyectoSelected.cliente;

                // Comprobar que la operacion se ha cambiado o (si es seriado) que la colada y lote se hayan cambiado
                if (this.JnLotesSelected != undefined && this.JnColadaSelected != undefined) {
                  if (f.idOperacion != this.JprogramasSelected.value || (f.lote != this.JnLotesSelected.lote || f.colada != this.JnColadaSelected.colada))
                    f.idHO = -1;
                } else if (f.idOperacion != this.JprogramasSelected.value) {
                  f.idHO = -1;
                }


                if (this.JnLotesSelected == undefined) f.lote = '';
                else f.lote = this.JnLotesSelected.lote;

                if (this.JnColadaSelected == undefined) f.colada = '';
                else f.colada = this.JnColadaSelected.colada;

                f.idPieza = this.JpiezasSelected.value;
                f.pieza = this.JpiezasSelected.text;

                f.idParte = this.JpartesSelected.value;
                f.parte = this.JpartesSelected.text;

                f.idOperacion = this.JprogramasSelected.value;
                f.nombre_Operacion = this.JprogramasSelected.text;
              }
              //se actualiza la cantidad lote hecha en esa operacion.
              //if ultimo
              f.cantidadTerminadaAnterior = f.cantidadTerminada;
              f.cantidadAchatarradaAnterior = f.cantidadAchatarrada;
              f.cantidadApartadaAnterior = f.cantidadApartada;
              // f.cantidadTerminada = this.PPcantidadTerminada;
              // f.cantidadAchatarrada = this.PPcantidadAchatarrada;
              // f.cantidadApartada = this.PPcantidadApartada;
              if (this.hmiCantidadesAsignadas.length != 0 && this.hmiCantidadesAsignadas.filter(value => (!value.eliminar && (new Date(f.fechaini).getTime() <= new Date(value.fecha).getTime()) && (new Date(value.fecha).getTime() < new Date(f.fechafin).getTime()))).length > 0) {
                f.cantidadTerminada = this.hmiCantidadesAsignadas.filter(value => (!value.eliminar && (new Date(f.fechaini).getTime() <= new Date(value.fecha).getTime()) && (new Date(value.fecha).getTime() < new Date(f.fechafin).getTime()))).map(function (value) { return value.cantidadTerminada }).reduce((a, b) => { return a + b });
                f.cantidadAchatarrada = this.hmiCantidadesAsignadas.filter(value => (!value.eliminar && (new Date(f.fechaini).getTime() <= new Date(value.fecha).getTime()) && (new Date(value.fecha).getTime() < new Date(f.fechafin).getTime()))).map(function (value) { return value.cantidadAchatarrada }).reduce((a, b) => { return a + b });
                f.cantidadApartada = this.hmiCantidadesAsignadas.filter(value => (!value.eliminar && (new Date(f.fechaini).getTime() <= new Date(value.fecha).getTime()) && (new Date(value.fecha).getTime() < new Date(f.fechafin).getTime()))).map(function (value) { return value.cantidadApartada }).reduce((a, b) => { return a + b });
              } else {
                f.cantidadTerminada = 0;
                f.cantidadAchatarrada = 0;
                f.cantidadApartada = 0;
              }

              this.hmiCantidadesAsignadas.forEach(element => {
                if (!this.actualizarCantidadesAsignadas.map(f => f.identificador).includes(element.identificador)) {
                  if (element.insertar) {
                    if (element.idOperario == undefined)
                      element.idOperario = -1;
                    else
                      element.idOperario = element.idOperario.valor;

                    element.fecha = this.myFunctions.dateToYYYYMMDDtHHmmSS(new Date(element.fecha));
                    if (element.idOperario == undefined) element.idOperario = -1;
                  }
                  element.terminado = false;
                  this.actualizarCantidadesAsignadas.push(element);
                }
              });
              // f.terminadoAnterior = f.terminado;

              //si se ha cambiado el Nserie y ahora se ha añadido a mano, se actualiza.
              f.nuevoNserie = this.nuevoNserie;


              //f.idHO se actualiza arriba al actualizar la Operacion! una vez se cambia ya no se mira si existe si se pone de nuevo la anterior 
              if (this.JnSerieSelected == undefined) {
                //si se ha seleccionado otra pieza ya existente se actualiza.
                f.idHPi = 0;
                //si la nueva pieza seleccionada se añade 
                f.idNserieParte = 0;
                f.nuevoNserie = this.nuevoNserie;
                f.nSerie = f.nuevoNserie; //para actualizar el grid
              } else {
                //si se ha seleccionado otra pieza ya existente se actualiza.

                f.idHPi = this.JnSerieSelected.idHP;
                //si la nueva pieza seleccionada se añade 
                //f.idNserieParte = this.JnSerieSelected.idPans;
                //if (this.JnSerieSelected.idHP == 0)
                f.nuevoNserie = this.JnSerieSelected.nSerie;
                f.numeroSerie = this.JnSerieSelected.nSerie; //para actualizar el grid
                f.nSerie = this.JnSerieSelected.nSerie;

              }
            }
          }

          if (this.visibleOperario) {
            console.log(this.bonoEnLista(f, this.dataBonosSelecteds));
            if (this.bonoEnLista(f, this.dataBonosSelecteds)) {
              if (this.JoperariosSelected != undefined) {
                f.numOperario_Operario = this.JoperariosSelected.valor;
                f.operario = this.JoperariosSelected.text;
                f.operarioNombre = this.JoperariosSelected.text;
                const fullName = f['operario'].split(' ');
                if (fullName.length == 1) f.iniciales = '';
                else f.iniciales = (fullName.shift().charAt(0) + fullName.pop().charAt(0)).toUpperCase();
              } else {
                f.numOperario_Operario = -1;
                f.operario = 'undefined';
                f.operarioNombre = 'desconocido';
                f.iniciales = '';
              }
            }
            /*if (this.JoperariosSelected != undefined) {
              f.numOperario_Operario = this.JoperariosSelected.valor;
              f.operario = this.JoperariosSelected.text;
            } else {
              f.numOperario_Operario = 0;
              f.operario = "";
            }*/
          }

          if (this.visibleObservaciones) {
            f.descripcion = this.Jdescripcion;
          }
        })

        // for ( let idx = 0; idx < this.dataBonosSelecteds.length; idx++) 
        //       {
        //         if (idx==this.dataBonosSelecteds.length-1)
        //         {//if ultimo:
        //           this.dataBonosSelecteds[idx].cantidadTerminadaAnterior = this.dataBonosSelecteds[idx].cantidadTerminada;
        //           this.dataBonosSelecteds[idx].cantidadAchatarradaAnterior = this.dataBonosSelecteds[idx].cantidadAchatarrada;
        //           this.dataBonosSelecteds[idx].cantidadApartadaAnterior = this.dataBonosSelecteds[idx].cantidadApartada;
        //           this.dataBonosSelecteds[idx].terminadoAnterior = this.dataBonosSelecteds[idx].terminado;
        //           this.dataBonosSelecteds[idx].cantidadTerminada = this.PPcantidadTerminada;
        //           this.dataBonosSelecteds[idx].cantidadAchatarrada = this.PPcantidadAchatarrada;
        //           this.dataBonosSelecteds[idx].cantidadApartada = this.PPcantidadApartada;
        //           this.dataBonosSelecteds[idx].terminado= this.terminado;
        //         }
        //       }


        //this.updateBotones();
        //cerrar popup
        this.modalReference.close();
        this.generarGanttBonos();
      }
    }

  }

  private bonoEnLista(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }
    return false;
  }

  //BOTONES GUARDAR
  guardarValidar() {
    this.guardar(true, false);
  }
  guardarDesvalidar() {
    this.modalReferenceDesvalidar.close();
    this.modalReferenceDesvalidar = undefined;
    this.guardar(false, true);
  }
  guardarActualizar() {
    this.guardar(false, false);
  }

  guardar(validado, desvalidado) {
    this.loadingPanel = true;
    //this.bonosService.update(this.dataBonos, this.selectedMaquina, validado, this.dateToYYYYMMDDtHHmmSSz(this.fecha)).subscribe(

    var JbonosAux;
    if (validado || desvalidado) JbonosAux = this.Jbonos.filter(f => this.mySelectionBonos.includes(f.identificadorGrid))
    else JbonosAux = this.myFunctions.copy(this.Jbonos);

    this.timelinePerdidas = new Map([...this.timelinePerdidas.entries()].sort((a, b) => a[0] - b[0]))
    var arrayPerdidas = [...this.timelinePerdidas.values()]

   
    // var arrayPerdidas = [...this.timelinePerdidas.values()] Si no se seleccionan todas las lineas del grid, falla esta linea.
    var indexAGuardar = JbonosAux.map(value => value.identificadorGrid );
    var arrayPerdidas = []
    // indexAGuardar.forEach(
    //   index => {
    //     arrayPerdidas.push(this.timelinePerdidas[index]);
    //   });
      this.timelinePerdidas.forEach(
        (value: any, key: any) => {
          if(indexAGuardar.includes(key))
            arrayPerdidas.push(value);
        });
  


    //#region Se procesan las lineas terminadas para que en la tabla CantidadesAsignadas se introduzca una linea de terminado
    var lineasTerminadas = JbonosAux.filter(f => (f.terminado && f.terminado != f.terminadoAnterior) || (f.piezaTerminada && f.piezaTerminada != f.piezaTerminadaAnterior));
    var lineasQuitarTerminadas = JbonosAux.filter(f => (!f.terminado && f.terminado != f.terminadoAnterior) || (!f.piezaTerminada && f.piezaTerminada != f.piezaTerminadaAnterior));
    var linea;
    lineasQuitarTerminadas.forEach(element => {
      var fecha: any = new Date(element.fechafin);
      fecha.setSeconds(fecha.getSeconds() - 1)
      fecha = this.myFunctions.dateToYYYYMMDDtHHmmSSz(fecha);
      var quitarTerminado = element.terminadoAnterior && !element.terminado;
      var quitarPiezaTerminada = element.piezaTerminadaAnterior && !element.piezaTerminada;;
      linea = {
        id: -1,
        idHistoricoProcesos: -1,
        idHistorico_operaciones: element.idHO,
        idHistorico_operaciones_Anterior: element.idHO,
        cantidadTerminada: 0,
        cantidadAchatarrada: 0,
        cantidadApartada: 0,
        fechaini: element.fechaini,
        fechafin: element.fechafin,
        fecha: fecha,
        idMaquina: element.idMaquina,
        nombreMaquina: element.nombre_Maquina,
        nombreOperario: " ",
        idOperario: -1,
        eliminar: false,
        insertar: false,
        terminado: false,
        piezaTerminada: false,
        quitarTerminado: quitarTerminado,
        quitarPiezaTerminada: quitarPiezaTerminada,
        idOperacion: element.idOperacion,
        idOperacionAnterior: element.idOperacion,
        idOFs_Partes_NumerosDeSerie: element.idOFs_Partes_NumerosDeSerie
      }
      this.actualizarCantidadesAsignadas.push(linea);
    });
    lineasTerminadas.forEach(element => {
      var fecha: any = new Date(element.fechafin);
      fecha.setSeconds(fecha.getSeconds() - 1)
      fecha = this.myFunctions.dateToYYYYMMDDtHHmmSSz(fecha);
      linea = {
        id: -1,
        idHistoricoProcesos: -1,
        idHistorico_operaciones: element.idHO,
        idHistorico_operaciones_Anterior: element.idHO,
        cantidadTerminada: 0,
        cantidadAchatarrada: 0,
        cantidadApartada: 0,
        fechaini: element.fechaini,
        fechafin: element.fechafin,
        fecha: fecha,
        idMaquina: element.idMaquina,
        nombreMaquina: element.nombre_Maquina,
        nombreOperario: " ",
        idOperario: -1,
        eliminar: false,
        insertar: true,
        terminado: element.terminado,
        piezaTerminada: element.piezaTerminada,
        quitarTerminado: false,
        quitarPiezaTerminada: false,
        idOperacion: element.idOperacion,
        idOperacionAnterior: element.idOperacion,
        idOFs_Partes_NumerosDeSerie: element.idOFs_Partes_NumerosDeSerie
      }
      this.actualizarCantidadesAsignadas.push(linea);
    });
    //#endregion

    this.bonosService.update(JbonosAux, this.selectedMaquina, validado, desvalidado, this.dateToYYYYMMDDtHHmmSSz(this.fecha), arrayPerdidas, this.actualizarCantidadesAsignadas.reverse()).subscribe(
      r => {
        this.loadingPanel = false;
        this.actualizarCantidadesAsignadas = [];
        if (validado) {
          var an: any = this.dataBonos
          an.forEach(
            row => {
              row.validado = true;
            });
        }
        //this.cargarGrupos();
        //this.cargarAreasProductivas();

        this.cargarCombos();
        this.cargarBonos();
      }
    );
    this.mySelectionBonos = [];

  }

  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  };
  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }
  addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }

  desplegarMaquinasClick() {
    this.myFunctions.desplegarMaquinasClick(this);
  }

  public cellClickHandler(e: CellClickEvent): void {
    this.mySelectionBonos = [];
    this.mySelectionBonos.push(e.dataItem.identificadorGrid)
    console.log(this.mySelectionBonos[0]);
  }

  public changeTerminado(e: any): void {
    //if (e.currentTarget.checked) this.terminado=true;
    this.terminado = e.currentTarget.checked;
  }

  // ANNADIR OPERACION

  periodoValido() {
    this.esSoloParada = false;

    this.dataBonos.forEach(element => {
      var validoFechaIni = this.myFunctions.sqlToJsDateT(element.fechaini) <= this.fechaIni && this.fechaIni < this.fechaFin;
      var validoFechaFin = this.myFunctions.sqlToJsDateT(element.fechafin) >= this.fechaFin;
      var validoIdOperacion = element.idOperacion < 0;
      var validoIdHistoricoOperacion = element.idHO <= 0;
      var validoIdProcesos = element.idProcesos_Tipo == 2;
      var validoIdPerdida = element.perdida == '';

      if (validoFechaIni && validoFechaFin && validoIdOperacion && validoIdHistoricoOperacion && validoIdProcesos && validoIdPerdida && this.isOffline) // maquinas offline
        this.esSoloParada = true;
      else if (validoFechaIni && validoFechaFin && validoIdHistoricoOperacion && !this.isOffline) // maquinas no offline
        this.esSoloParada = true;
    })
  }

  // si el valor de los combos cambian
  ofValueChanged(value: any) {
    if (value != undefined) {
      this.Jpiezas = this.JDatpiezas.filter(f => f.idOF == value.value);
      this.JpiezasSelected = undefined;
      this.Jpartes = this.JDatpartes.filter(f => f.idOF == value.value);
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas.filter(f => f.idOF == value.value);
      this.JprogramasSelected = undefined;
      //nSerie (si no hay pieza se cargan los Nserie del proyecto)
      this.JnSerie = this.JDatnSerie.filter(f => f.idOF == value.value);
      this.JnSerieSelected = undefined;
      // this.cargarNserie(this.dataValidadorSelecteds[0].idHPi, this.dataValidadorSelecteds[0].nuevoNserie);
    } else {
      this.Jpiezas = this.JDatpiezas
      this.JpiezasSelected = undefined;
      this.Jpartes = this.JDatpartes
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas
      this.JprogramasSelected = undefined;
      //nSerie (si no hay proyecto se carga todo)
      this.JnSerie = this.JDatnSerie;
      this.JnSerieSelected = undefined;
    }
  }
  PiezaValueChangedAnnadirOp(value: any) {
    if (value != undefined) {
      this.JproyectoSelected = this.Jproyectos.filter(f => f.value == value.idOF)[0];
      this.Jpartes = this.JDatpartes.filter(f => f.idPieza == value.value);
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas.filter(f => f.idPieza == value.value);
      this.JprogramasSelected = undefined;
      //nSerie (si no hay parte se cargan los Nserie de la pieza)
      this.JnSerie = this.JDatnSerie.filter(f => f.idPieza == value.value);
      this.JnSerieSelected = undefined;
    } else {
      this.Jpartes = this.JDatpartes.filter(f => f.idOF == this.JproyectoSelected.value);
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas.filter(f => f.idOF == this.JproyectoSelected.value);
      this.JprogramasSelected = undefined;
      //nSerie (si no hay pieza se cargan los Nserie del proyecto)
      this.JnSerie = this.JDatnSerie.filter(f => f.idOF == this.JproyectoSelected);
      this.JnSerieSelected = undefined;
    }
  }
  ParteValueChangedAnnadirOp(value: any) {
    if (value != undefined) {
      this.JproyectoSelected = this.Jproyectos.filter(f => f.value == value.idOF)[0];
      this.JpiezasSelected = this.Jpiezas.filter(f => f.value == value.idPieza)[0];
      this.Jprogramas = this.JDatprogramas.filter(f => f.idParte == value.value);
      this.JprogramasSelected = undefined;
      this.cantidadLoteMax = this.Jpartes.filter(f => f.value == value.id)[0].cantidadParte;
      //nSerie (si hay parte se cargan los Nserie de la parte)
      this.JnSerie = this.JDatnSerie.filter(f => f.idParte == value.id);
      this.JnSerieSelected = undefined;
    } else {
      this.Jprogramas = this.JDatprogramas.filter(f => f.idPieza == this.JpiezasSelected.value);
      this.JprogramasSelected = undefined;
      this.cantidadLoteMax = 1;
      //nSerie (si no hay parte se cargan los Nserie de la pieza)
      this.JnSerie = this.JDatnSerie.filter(f => f.idPieza == this.JpiezasSelected);
      this.JnSerieSelected = undefined;
      //this.cargarNserie(this.dataBonosSelecteds[0].idHPi, this.dataBonosSelecteds[0].nuevoNserie);
    }
  }
  operacionValueChanged(value: any) {
    if (value != undefined) {
      this.JproyectoSelected = this.Jproyectos.filter(f => f.value == value.idOF)[0];
      this.JpiezasSelected = this.Jpiezas.filter(f => f.value == value.idPieza)[0];
      this.JpartesSelected = this.Jpartes.filter(f => f.value == value.idParte)[0];
    }

    this.verCantidades = true;
    this.verAceptar = true;

    this.operacionesService.GetByID(value.idOF, value.idPieza, value.idParte, 1, value.value).subscribe(
      json => {

        this.cantidadTotal = json.operacion[0].cantidad;
        this.verColada = json.operacion[0].solicitar_Colada;
        this.verLotes = json.operacion[0].solicitar_Lote;
        this.verNSerie = json.operacion[0].solicitar_N_Serie;

        if (this.verNSerie) {
          this.cantidadTerminadaMax = 1;
          this.cantidadAchatarradaMax = 1;
          this.cantidadApartadaMax = 1;
          this.JnSerie = this.JDatnSerie.filter(f => f.idOF == value.idOF);
          this.JnSerieSelected = undefined;
        } else {
          this.cantidadTerminadaMax = this.cantidadTotal;
          this.cantidadAchatarradaMax = this.cantidadTotal;
          this.cantidadApartadaMax = this.cantidadTotal;
        }
      });
  }
  onChangeCantidad() {
    if (this.verNSerie) {
      if (this.PPcantidadTerminada == 1) {
        this.cantidadAchatarradaMax = 0;
        this.cantidadApartadaMax = 0;
      } else if (this.PPcantidadAchatarrada == 1) {
        this.cantidadTerminadaMax = 0;
        this.cantidadApartadaMax = 0;
      } else if (this.PPcantidadApartada == 1) {
        this.cantidadTerminadaMax = 0;
        this.cantidadAchatarradaMax = 0;
      } else {
        this.cantidadTerminadaMax = 1;
        this.cantidadAchatarradaMax = 1;
        this.cantidadApartadaMax = 1;
      }
    }
  }

  annadirOperacion() {

    // var fechaInicio = new Date(this.fechaIni.setHours(this.fechaIni.getHours() + 1)); 
    // var fechaFin = new Date(this.fechaFin.setHours(this.fechaFin.getHours() + 1)); 
    var piezasTerminadas = this.PPcantidadTerminada; //
    var piezasAchatarradas = this.PPcantidadAchatarrada; //
    var piezasApartadas = this.PPcantidadApartada; //
    var idOperacion = this.JprogramasSelected.value;
    var idPlanificado = -1;
    var idMaquina = this.selectedMaquina;
    var numoperario = this.JoperariosSelected == undefined ? -1 : this.JoperariosSelected.valor;
    // var idHistoricoProcesos; 
    var idProcesoTipo = parseInt(this.JtiposSelected.value);
    var idMantenimiento = -1;
    var idPlanificadoLargo = -1;

    var colada;
    var nserie;
    var lote;

    var terminado;

    if (this.verColada)
      colada = this.JnColadaSelected?.colada;
    else
      colada = '';

    if (this.verNSerie)
      nserie = this.JnSerieSelected?.rn;
    else
      nserie = '';

    if (this.verLotes)
      lote = this.cantidadLote;
    else
      lote = '';

    this.dataBonos.every(element => {
      if (element.identificadorGrid == this.dataBonosSelecteds[0].identificadorGrid) {

        //#region asignar los valores
        var row = this.myFunctions.copy(element);
        row.piezasTerminadas = piezasTerminadas;
        row.piezasAchatarradas = piezasAchatarradas;
        row.piezasApartadas = piezasApartadas;
        row.idOperacion = idOperacion;
        row.idHO = -1;
        row.idPlanificado = idPlanificado;
        row.idMaquina = idMaquina;
        row.numoperario = numoperario;
        row.idProcesos_Tipo = idProcesoTipo;
        row.idMantenimiento = idMantenimiento;
        row.idPlanificadoLargo = idPlanificadoLargo;
        row.colada = colada;
        row.nserie = nserie;
        row.lote = lote;

        row.nombre_Operacion = this.JprogramasSelected.text;
        row.pieza = this.JpiezasSelected.text;
        row.numeroOF_OF = this.JproyectoSelected.text;
        row.parte = this.JpartesSelected.text;
        row.idPieza = this.JpiezasSelected.value;
        row.annadirOperacion = true;
        row.identificadorGrid = row.identificadorGrid + 0.1;
        row.esUltimoProceso = 0;
        row.idProcesos_Tipo = idProcesoTipo;
        row.idProcesos_TipoOriginal = idProcesoTipo;
        row.actualizar_idProcesos_Tipo = true;

        if (this.JnSerieSelected == undefined) {
          //si se ha seleccionado otra pieza ya existente se actualiza.
          row.idHPi = 0;
          //si la nueva pieza seleccionada se añade 
          row.idNserieParte = 0;
          row.nuevoNserie = this.nuevoNserie;
          row.nSerie = row.nuevoNserie; //para actualizar el grid
        } else {
          //si se ha seleccionado otra pieza ya existente se actualiza.
          row.idHPi = this.JnSerieSelected.idHP;
          //si la nueva pieza seleccionada se añade 
          row.nuevoNserie = this.JnSerieSelected.nSerie;
          row.numeroSerie = this.JnSerieSelected.nSerie; //para actualizar el grid
          row.nSerie = this.JnSerieSelected.nSerie;
        }
        //#endregion

        if (this.fechaIni.getTime() != new Date(element.fechaini).getTime() && this.fechaFin.getTime() != new Date(element.fechafin).getTime()) { // se debe dividir la linea en tres lineas

          row.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaIni);
          row.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaFin);
          row.horaInicio = new Date(row.fechaini).toLocaleTimeString();
          row.horaFin = new Date(row.fechafin).toLocaleTimeString();
          row.tiempoRealHH = this.calcularTDiferencia(row['fechafin'], row['fechaini']);
          //#region Barra de tiempos
          row.tiempoPreparacion = idProcesoTipo == 1 ? 0 : row.tiemporeal;
          row.tiempoEjecucion = idProcesoTipo == 3 ? 0 : row.tiemporeal;
          row.tiempoParada = 0;
          row.tiempoMantenimiento = 0;
          row.tiempoAlarma = 0;
          row.tiempoApagada = 0;
          row.tiempoMicro = 0;
          row.TPorcentajePreparacion = idProcesoTipo == 1 ? 0 : 100;
          row.TPorcentajeEjecucion = idProcesoTipo == 3 ? 0 : 100;
          row.TPorcentajeParada = 0;
          row.TPorcentajeMantenimiento = 0;
          row.TPorcentajeAlarma = 0;
          row.TPorcentajeApagado = 0;
          row.TPorcentajeMicroParada = 0;

          row.THHEjecucion = row.tiempoRealHH;
          //#endregion
          this.dataBonos.push(row);

          var row2 = this.myFunctions.copy(element);
          row2.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaFin);
          row2.horaInicio = new Date(row2.fechaini).toLocaleTimeString();
          row2.horaFin = new Date(row2.fechafin).toLocaleTimeString();
          row2.tiempoRealHH = this.calcularTDiferencia(row2['fechafin'], row2['fechaini']);
          row2.THHEjecucion = row2.tiempoRealHH;
          row2.identificadorGrid = row2.identificadorGrid + 0.2;
          this.dataBonos.push(row2);

          element.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaIni);
          element.horaInicio = new Date(element.fechaini).toLocaleTimeString();
          element.horaFin = new Date(element.fechafin).toLocaleTimeString();
          element.tiempoRealHH = this.calcularTDiferencia(element['fechafin'], element['fechaini']);
          element.THHEjecucion = element.tiempoRealHH;
          element.esUltimoProceso = 0;
        } else if (this.fechaIni.getTime() == new Date(element.fechaini).getTime() && this.fechaFin.getTime() != new Date(element.fechafin).getTime()) { // se debe dividir la linea en dos lineas

          row.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaFin);
          row.horaInicio = new Date(row.fechaini).toLocaleTimeString();
          row.horaFin = new Date(row.fechafin).toLocaleTimeString();
          row.tiempoRealHH = this.calcularTDiferencia(row['fechafin'], row['fechaini']);
          //#region Barra de tiempos
          row.tiempoPreparacion = idProcesoTipo == 1 ? 0 : row.tiemporeal;
          row.tiempoEjecucion = idProcesoTipo == 3 ? 0 : row.tiemporeal;
          row.tiempoParada = 0;
          row.tiempoMantenimiento = 0;
          row.tiempoAlarma = 0;
          row.tiempoApagada = 0;
          row.tiempoMicro = 0;
          row.TPorcentajePreparacion = idProcesoTipo == 1 ? 0 : 100;
          row.TPorcentajeEjecucion = idProcesoTipo == 3 ? 0 : 100;
          row.TPorcentajeParada = 0;
          row.TPorcentajeMantenimiento = 0;
          row.TPorcentajeAlarma = 0;
          row.TPorcentajeApagado = 0;
          row.TPorcentajeMicroParada = 0;

          row.THHEjecucion = row.tiempoRealHH;
          //#endregion
          this.dataBonos.push(row);

          element.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaFin);
          element.horaInicio = new Date(element.fechaini).toLocaleTimeString();
          element.horaFin = new Date(element.fechafin).toLocaleTimeString();
          element.tiempoRealHH = this.calcularTDiferencia(element['fechafin'], element['fechaini']);
          element.THHEjecucion = element.tiempoRealHH;
        } else if (this.fechaIni.getTime() != new Date(element.fechaini).getTime() && this.fechaFin.getTime() == new Date(element.fechafin).getTime()) { // se debe dividir la linea en dos lineas

          row.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaIni);
          row.horaInicio = new Date(row.fechaini).toLocaleTimeString();
          row.horaFin = new Date(row.fechafin).toLocaleTimeString();
          row.tiempoRealHH = this.calcularTDiferencia(row['fechafin'], row['fechaini']);
          //#region Barra de tiempos
          row.tiempoPreparacion = idProcesoTipo == 1 ? 0 : row.tiemporeal;
          row.tiempoEjecucion = idProcesoTipo == 3 ? 0 : row.tiemporeal;
          row.tiempoParada = 0;
          row.tiempoMantenimiento = 0;
          row.tiempoAlarma = 0;
          row.tiempoApagada = 0;
          row.tiempoMicro = 0;
          row.TPorcentajePreparacion = idProcesoTipo == 1 ? 0 : 100;
          row.TPorcentajeEjecucion = idProcesoTipo == 3 ? 0 : 100;
          row.TPorcentajeParada = 0;
          row.TPorcentajeMantenimiento = 0;
          row.TPorcentajeAlarma = 0;
          row.TPorcentajeApagado = 0;
          row.TPorcentajeMicroParada = 0;

          row.THHEjecucion = row.tiempoRealHH;
          //#endregion
          row.esUltimoProceso = element.esUltimoProceso;
          this.dataBonos.push(row);

          element.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaIni);
          element.horaInicio = new Date(element.fechaini).toLocaleTimeString();
          element.horaFin = new Date(element.fechafin).toLocaleTimeString();
          element.tiempoRealHH = this.calcularTDiferencia(element['fechafin'], element['fechaini']);
          element.THHEjecucion = element.tiempoRealHH;
          element.esUltimoProceso = 0;
        } else { // no se divide la linea solo se actualiza
          //#region asignar los valores
          element.piezasTerminadas = piezasTerminadas;
          element.piezasAchatarradas = piezasAchatarradas;
          element.piezasApartadas = piezasApartadas;
          element.idOperacion = idOperacion;
          element.idHO = -1;
          element.idPlanificado = idPlanificado;
          element.idMaquina = idMaquina;
          element.numoperario = numoperario;
          element.idProcesos_Tipo = idProcesoTipo;
          element.idMantenimiento = idMantenimiento;
          element.idPlanificadoLargo = idPlanificadoLargo;
          element.colada = colada;
          element.nserie = nserie;
          element.lote = lote;
          element.nombre_Operacion = this.JprogramasSelected.text;
          element.pieza = this.JpiezasSelected.text;
          element.numeroOF_OF = this.JproyectoSelected.text;
          element.parte = this.JpartesSelected.text;
          element.idPieza = this.JpiezasSelected.value;
          element.idProcesos_Tipo = idProcesoTipo;
          element.idProcesos_TipoOriginal = idProcesoTipo;
          //#region Barra de tiempos
          element.tiempoPreparacion = idProcesoTipo == 1 ? 0 : element.tiemporeal;
          element.tiempoEjecucion = idProcesoTipo == 3 ? 0 : element.tiemporeal;
          element.tiempoParada = 0;
          element.tiempoMantenimiento = 0;
          element.tiempoAlarma = 0;
          element.tiempoApagada = 0;
          element.tiempoMicro = 0;
          element.TPorcentajePreparacion = idProcesoTipo == 1 ? 0 : 100;
          element.TPorcentajeEjecucion = idProcesoTipo == 3 ? 0 : 100;
          element.TPorcentajeParada = 0;
          element.TPorcentajeMantenimiento = 0;
          element.TPorcentajeAlarma = 0;
          element.TPorcentajeApagado = 0;
          element.TPorcentajeMicroParada = 0;
          element.actualizar_idProcesos_Tipo = true;

          element.THHEjecucion = element.tiempoRealHH;
          //#endregion

          if (this.JnSerieSelected == undefined) {
            //si se ha seleccionado otra pieza ya existente se actualiza.
            element.idHPi = 0;
            //si la nueva pieza seleccionada se añade 
            element.idNserieParte = 0;
            element.nuevoNserie = this.nuevoNserie;
            element.nSerie = element.nuevoNserie; //para actualizar el grid
          } else {
            //si se ha seleccionado otra pieza ya existente se actualiza.
            element.idHPi = this.JnSerieSelected.idHP;
            //si la nueva pieza seleccionada se añade 
            element.nuevoNserie = this.JnSerieSelected.nSerie;
            element.numeroSerie = this.JnSerieSelected.nSerie; //para actualizar el grid
            element.nSerie = this.JnSerieSelected.nSerie;
          }
          //#endregion
        }
        return false;
      }
      return true;
    })

    // ordenar la lista del grid con las nuevas lineas insertadas
    this.dataBonos = this.dataBonos.sort((a, b) => {
      if (new Date(a.fechaini).getTime() > new Date(b.fechaini).getTime()) return 1
      else if (new Date(a.fechaini).getTime() < new Date(b.fechaini).getTime()) return -1
      else return 0
    })
    console.log(this.dataBonos);
    this.dataBonos = this.myFunctions.copy(this.dataBonos);
    this.Jbonos = this.dataBonos;

    // this.validadorService.asignarOperacionEndiferida(fechaInicio, fechaFin, piezasTerminadas, piezasAchatarradas, piezasApartadas, idOperacion, idPlanificado, idMaquina, numoperario/*, idHistoricoProcesos*/, idProcesoTipo, idMantenimiento, idPlanificadoLargo, colada, nserie, lote, this.isOffline, terminado, this.JproyectoSelected.value, this.JpiezasSelected.value, this.JpartesSelected.value).subscribe(
    //   json => {
    //      // cargar de nuevo la informacion del validador
    //      this.cargarBonos();
    //   }
    // )

    //cerrar popup
    this.modalReference.close();
    this.generarGanttBonos();
    this.mySelectionBonos = [];
  }
  // END ANNADIR OPERACION

  //#region ASIGNAR PERDIDA
  clickAsignarPerdida(popup, fromGantt = false) {

    if (!fromGantt)
      this.dataBonosSelecteds = this.dataBonos.filter(f => this.mySelectionBonos.includes(f.identificadorGrid));

    //#region comprobar que es una parada sin justificar
    var idPerdida = this.dataBonosSelecteds[0].idPerdida;
    var idProcesosTipo = this.dataBonosSelecteds[0].idProcesos_Tipo;
    var tiempoParada = this.dataBonosSelecteds[0].tiempoParada;
    if (idPerdida == -1 /*&& (idProcesosTipo == 2 || idProcesosTipo == 6 || idProcesosTipo == 8)*/) {
      this.esParadaSinJustificar = true;
      this.esParadaJustificada = false;
    } else if (idPerdida != -1 /*&& (idProcesosTipo == 2 || idProcesosTipo == 6 || idProcesosTipo == 8)*/) {
      this.esParadaSinJustificar = false;
      this.esParadaJustificada = true;
    }
    // else {
    //   this.esParadaSinJustificar = false;
    //   this.esParadaJustificada = false;
    // }
    //#endregion

    if (this.esParadaSinJustificar) {
      this.perdidasService.getPerdidas(this.selectedMaquina).subscribe(
        json => {
          this.dataPerdidasGrid = json;
        }
      )

      var fechaFinAux = this.dataBonosSelecteds[0].fechafin;
      if (this.dataBonosSelecteds.length > 1)
        fechaFinAux = this.dataBonosSelecteds[this.dataBonosSelecteds.length - 1]?.fechafin;

      this.fechaLimite = new Date(fechaFinAux);

      this.asignarMultiperdida = [{
        id: 0,
        fechaIni: new Date(this.dataBonosSelecteds[0].fechaini),
        fechaFin: this.fechaLimite,
        fechaMin: new Date(this.dataBonosSelecteds[0].fechaini),
        fechaMax: this.fechaLimite,
        perdida: "",
        idPerdida: -1,
        idTipoPerdida: -1,
        tieneSubtipos: 0,
        requiereExplicacion: 0,
        explicacion: "",
        solicitarOF: 0
      }]

      var index = this.dataBonos.indexOf(this.dataBonosSelecteds[0])
      if (index == this.dataBonos.length - 1) {
        this.esUltimoProceso = true;
      } else {
        this.esUltimoProceso = false;
      }
      this.ensennarJustificarHastaFin = this.esUltimoProceso && this.ultimoJustificado;
    } else if (this.esParadaJustificada) {
      this.perdidasService.getPerdidas(this.selectedMaquina).subscribe(
        json => {
          this.dataPerdidasGrid = json;
          this.perdidaAsignada = this.dataPerdidasGrid.filter(f => f.idPerdidaHistoricoBase == idPerdida)[0];
        }
      )
    }

    this.modalReferenceAsignar = this.modalService.open(popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  clickEnsennarPerdidas(popup, perdida) {
    this.idIntervaloTiempo = perdida;
    this.modalReference = this.modalService.open(popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  elegirPerdida(event, popup) {

    var index = this.asignarMultiperdida.indexOf(this.idIntervaloTiempo);

    if (this.esParadaSinJustificar) {
      if (event.dataItem.requiereExplicacion == 0) {
        if (event.dataItem.idSubPerdida != -1) {
          this.asignarMultiperdida[index].idPerdida = event.dataItem.idSubPerdida;
          this.asignarMultiperdida[index].perdida = event.dataItem.subperdida;
        } else if (event.dataItem.idPerdida != -1) {
          this.asignarMultiperdida[index].idPerdida = event.dataItem.idPerdida;
          this.asignarMultiperdida[index].perdida = event.dataItem.perdida;
        } else {
          this.asignarMultiperdida[index].idPerdida = event.dataItem.idGrupoPerdida;
          this.asignarMultiperdida[index].perdida = event.dataItem.grupoPerdida;
        }
        this.asignarMultiperdida[index].requiereExplicacion = 0;
        this.asignarMultiperdida[index].explicacion = "";
        this.asignarMultiperdida[index].idTipoPerdida = event.dataItem.idTipoPerdida;
        this.asignarMultiperdida[index].tieneSubtipos = event.dataItem.tieneSubtipos;
        this.asignarMultiperdida[index].solicitarOF = event.dataItem.solicitarOF;
        this.comprobarTipoPerdida(event.dataItem);
      } else {
        this.perdidaSeleccionada = event.dataItem;
        this.modalReferenceObservacion = this.modalService.open(popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      }

      // Comprobar que el ultimo rango esta justificado para dar la opcion de justificar hasta el final del proceso
      if (this.asignarMultiperdida[this.asignarMultiperdida.length - 1].idPerdida != -1)
        this.ultimoJustificado = true;
      else
        this.ultimoJustificado = false;
      this.ensennarJustificarHastaFin = this.esUltimoProceso && this.ultimoJustificado;
    } else if (this.esParadaJustificada) {
      if (event.dataItem.requiereExplicacion == 0) {
        if (event.dataItem.idSubPerdida != -1) {
          this.perdidaAsignada.idPerdida = event.dataItem.idSubPerdida;
          this.perdidaAsignada.perdida = event.dataItem.subperdida;
        } else if (event.dataItem.idPerdida != -1) {
          this.perdidaAsignada.idPerdida = event.dataItem.idPerdida;
          this.perdidaAsignada.perdida = event.dataItem.perdida;
        } else {
          this.perdidaAsignada.idPerdida = event.dataItem.idGrupoPerdida;
          this.perdidaAsignada.perdida = event.dataItem.grupoPerdida;
        }
        this.perdidaAsignada.requiereExplicacion = 0;
        this.perdidaAsignada.explicacion = "";
        this.perdidaAsignada.idTipoPerdida = event.dataItem.idTipoPerdida;
        this.perdidaAsignada.tieneSubtipos = event.dataItem.tieneSubtipos;
        this.perdidaAsignada.solicitarOF = event.dataItem.solicitarOF;
        this.comprobarTipoPerdida(event.dataItem);
      } else {
        this.perdidaSeleccionada = event.dataItem;
        this.modalReferenceObservacion = this.modalService.open(popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      }
    }
  }

  // esta funcion comprueba que si la perdida es de tipo ejecucion/preparacion (1,3) entonces que se deba asignar una of
  comprobarTipoPerdida(perdida) {
    // if (perdida.idTipoPerdida == 1 || perdida.idTipoPerdida == 3) {
    if (perdida.solicitarOF == 1 && this.dataBonosSelecteds[0].idOperacion <= 0) {
      this.clickEditarOF(this.popupEditar, true);
    }
  }

  addPerdida(perdida) {
    if (perdida.fechaFin > this.fechaLimite) { // se controla que no pongan desde el teclado una fecha mayor
      perdida.fechaFin = this.fechaLimite;
    }

    var ultimaFila = this.asignarMultiperdida[this.asignarMultiperdida.length - 1];
    if (ultimaFila.id == perdida.id && this.fechaLimite != ultimaFila.fechaFin) {
      this.asignarMultiperdida.push({
        id: ultimaFila.id + 1,
        fechaIni: ultimaFila.fechaFin,
        fechaFin: this.fechaLimite,
        fechaMin: ultimaFila.fechaFin,
        fechaMax: this.fechaLimite,
        perdida: "",
        idPerdida: -1,
        idTipoPerdida: -1,
        tieneSubtipos: 0,
        requiereExplicacion: 0,
        explicacion: "",
        solicitarOF: 0
      })
    } else if (ultimaFila.id != perdida.id) {
      var index = this.asignarMultiperdida.indexOf(perdida);
      if (this.myFunctions.datetimeToSQL(perdida.fechaFin) >= this.myFunctions.datetimeToSQL(this.asignarMultiperdida[index + 1].fechaFin)) { // hay que cambiar las fechas siguientes
        this.asignarMultiperdida[index + 1].fechaFin = this.fechaLimite;
        this.asignarMultiperdida[index + 1].fechaIni = perdida.fechaFin;
        if (this.asignarMultiperdida.length > 2)
          this.asignarMultiperdida = this.asignarMultiperdida.slice(0, index + 2);
        else
          this.asignarMultiperdida = this.asignarMultiperdida.slice(0, index + 1);
      } else { // solo hay que cambiar la fecha de inicio de la siguiente linea
        this.asignarMultiperdida[index + 1].fechaIni = perdida.fechaFin;
      }
    }

    // Comprobar que el ultimo rango esta justificado para dar la opcion de justificar hasta el final del proceso
    if (this.asignarMultiperdida[this.asignarMultiperdida.length - 1].idPerdida != -1)
      this.ultimoJustificado = true;
    else
      this.ultimoJustificado = false;
    this.ensennarJustificarHastaFin = this.esUltimoProceso && this.ultimoJustificado;


  }

  eliminar(perdida) {
    if (this.esParadaSinJustificar) {
      var index = this.asignarMultiperdida.indexOf(perdida);
      if (this.asignarMultiperdida.length != 2) {
        this.asignarMultiperdida[index + 1].fechaIni = this.asignarMultiperdida[index - 1].fechaFin;
        this.asignarMultiperdida[index + 1].fechaMin = this.asignarMultiperdida[index - 1].fechaFin;
      } else {
        this.asignarMultiperdida[0].fechaFin = this.fechaLimite;
      }
      this.asignarMultiperdida = this.asignarMultiperdida.filter(f => f.id != perdida.id);

      // Comprobar que el ultimo rango esta justificado para dar la opcion de justificar hasta el final del proceso
      if (this.asignarMultiperdida[this.asignarMultiperdida.length - 1].idPerdida != -1)
        this.ultimoJustificado = true;
      else
        this.ultimoJustificado = false;
      this.ensennarJustificarHastaFin = this.esUltimoProceso && this.ultimoJustificado;
    } else if (this.esParadaJustificada) {
      this.perdidaAsignada = {
        perdida: "",
        idPerdida: -1,
        idTipoPerdida: -1,
        tieneSubtipos: 0,
        requiereExplicacion: 0,
        explicacion: "",
        solicitarOF: 0
      }
    }
  }

  guardarObservacion() {
    var index = this.asignarMultiperdida.indexOf(this.idIntervaloTiempo);

    if (this.esParadaSinJustificar) {
      this.asignarMultiperdida[index].requiereExplicacion = this.perdidaSeleccionada.requiereExplicacion;
      this.asignarMultiperdida[index].explicacion = this.observacionPerdida;
      this.asignarMultiperdida[index].idTipoPerdida = this.perdidaSeleccionada.idTipoPerdida;
      this.asignarMultiperdida[index].solicitarOF = this.perdidaSeleccionada.solicitarOF;
      if (this.perdidaSeleccionada.idSubPerdida != -1) {
        this.asignarMultiperdida[index].idPerdida = this.perdidaSeleccionada.idSubPerdida;
        this.asignarMultiperdida[index].perdida = this.perdidaSeleccionada.subperdida;
      } else if (this.perdidaSeleccionada.idPerdida != -1) {
        this.asignarMultiperdida[index].idPerdida = this.perdidaSeleccionada.idPerdida;
        this.asignarMultiperdida[index].perdida = this.perdidaSeleccionada.perdida;
      } else {
        this.asignarMultiperdida[index].idPerdida = this.perdidaSeleccionada.idGrupoPerdida;
        this.asignarMultiperdida[index].perdida = this.perdidaSeleccionada.grupoPerdida;
      }

      // Comprobar que el ultimo rango esta justificado para dar la opcion de justificar hasta el final del proceso
      if (this.asignarMultiperdida[this.asignarMultiperdida.length - 1].idPerdida != -1)
        this.ultimoJustificado = true;
      else
        this.ultimoJustificado = false;
      this.ensennarJustificarHastaFin = this.esUltimoProceso && this.ultimoJustificado;
    } else if (this.esParadaJustificada) {
      this.perdidaAsignada.requiereExplicacion = this.perdidaSeleccionada.requiereExplicacion;
      this.perdidaAsignada.explicacion = this.observacionPerdida;
      this.perdidaAsignada.idTipoPerdida = this.perdidaSeleccionada.idTipoPerdida;
      this.perdidaAsignada.solicitarOF = this.perdidaSeleccionada.solicitarOF;
      this.perdidaAsignada.tieneSubtipos = this.perdidaSeleccionada.tieneSubtipos;
      if (this.perdidaSeleccionada.idSubPerdida != -1) {
        this.perdidaAsignada.idPerdida = this.perdidaSeleccionada.idSubPerdida;
        this.perdidaAsignada.perdida = this.perdidaSeleccionada.subperdida;
      } else if (this.perdidaSeleccionada.idPerdida != -1) {
        this.perdidaAsignada.idPerdida = this.perdidaSeleccionada.idPerdida;
        this.perdidaAsignada.perdida = this.perdidaSeleccionada.perdida;
      } else {
        this.perdidaAsignada.idPerdida = this.perdidaSeleccionada.idGrupoPerdida;
        this.perdidaAsignada.perdida = this.perdidaSeleccionada.grupoPerdida;
      }
    }

    this.observacionPerdida = "";


    //cerrar popup
    this.modalReferenceObservacion?.close();

    this.comprobarTipoPerdida(this.perdidaSeleccionada);

  }

  validarPerdidas() {

    // preparar la lista de datos para que en local se vean los cambios (sin hacerlos en la base de datos)
    var auxPerd;
    if (this.esParadaSinJustificar) {
      this.dataBonos.every(element => {
        if (element.identificadorGrid == this.dataBonosSelecteds[0].identificadorGrid) {
          element.annadirMultiperdida = true;
          if (this.asignarMultiperdida.length < 2) {
            element.perdida = this.asignarMultiperdida[0].perdida;
            element.idPerdida = this.asignarMultiperdida[0].idPerdida;
            element.solicitarOperacion = this.asignarMultiperdida[0].solicitarOF;
            auxPerd = {
              fechaInicio: this.myFunctions.dateToYYYYMMDDtHHmmSS(new Date(element.fechaini)),
              fechaFin: this.myFunctions.dateToYYYYMMDDtHHmmSS(new Date(element.fechafin)),
              observacion: {
                id: this.asignarMultiperdida[0].idPerdida,
                idTipoPerdida: this.asignarMultiperdida[0].idTipoPerdida,
                titulo: this.asignarMultiperdida[0].perdida,
                descripcion: this.asignarMultiperdida[0].explicacion,
                requiereExplicacion: this.asignarMultiperdida[0].requiereExplicacion,
                tieneSubtipos: this.asignarMultiperdida[0].tieneSubtipos,
              }
            }
            this.timelinePerdidas.set(element.identificadorGrid, { justificarHastaFinProceso: this.justificarHastaFinProceso, idsHistoricoProceso: '', timelinePerdidas: [auxPerd] })
          } else {
            var i = 1;
            this.asignarMultiperdida.forEach(perd => {
              let row = this.myFunctions.copy(element);
              row.perdida = perd.perdida;
              row.idPerdida = perd.idPerdida;
              row.solicitarOperacion = perd.solicitarOF;
              row.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(perd.fechaIni);
              row.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(perd.fechaFin);
              row.horaInicio = new Date(row.fechaini).toLocaleTimeString();
              row.horaFin = new Date(row.fechafin).toLocaleTimeString();
              row.tiempoRealHH = this.calcularTDiferencia(row['fechafin'], row['fechaini']);
              row.THHParada = row.tiempoRealHH;
              row.identificadorGrid = row.identificadorGrid + (0.1 * i)
              auxPerd = {
                fechaInicio: this.myFunctions.dateToYYYYMMDDtHHmmSSz(perd.fechaIni),
                fechaFin: this.myFunctions.dateToYYYYMMDDtHHmmSSz(perd.fechaFin),
                observacion: {
                  id: perd.idPerdida,
                  idTipoPerdida: perd.idTipoPerdida,
                  titulo: perd.perdida,
                  descripcion: perd.explicacion,
                  requiereExplicacion: perd.requiereExplicacion,
                  tieneSubtipos: perd.tieneSubtipos,
                }
              }
              this.timelinePerdidas.set(row.identificadorGrid, { justificarHastaFinProceso: this.justificarHastaFinProceso, idsHistoricoProceso: '', timelinePerdidas: [auxPerd] })
              this.dataBonos.push(row);
              i++;
            })
            this.dataBonos = this.dataBonos.filter(f => f.identificadorGrid != this.dataBonosSelecteds[0].identificadorGrid)
            this.Jbonos = this.dataBonos;
            this.timelinePerdidas.delete(element.identificadorGrid);
          }

          //#region Si la perdida no tiene operacion entonces se debe quitar la operacion
          if (!element.solicitarOperacion) {

            // Si la operacion anterior estaba terminada y ahora se ha cambiado de operacion preguntar si se quiere quitar lo terminado
            if (element.terminadoAnterior || element.piezaTerminadaAnterior) {
              element.abrirOperacionPreguntar = true;
              element.abrirOperacion = false;
              element.abrirPieza = false;
              element.terminado = false;
              if (this.modalReferenceAbrirOperacion == undefined) {
                if (element.terminadoAnterior && element.piezaTerminadaAnterior) this.modalReferenceAbrirOperacion = this.modalService.open(this.popupAbrirOperacionYPieza, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
                else if (element.terminadoAnterior) this.modalReferenceAbrirOperacion = this.modalService.open(this.popupAbrirOperacion, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
                else if (element.piezaTerminadaAnterior) this.modalReferenceAbrirOperacion = this.modalService.open(this.popupAbrirPieza, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
              }
            }

            element.idOF = -1;
            element.numeroOF_OF = '';
            //element.cliente = '';
            element.lote = '';
            element.colada = '';
            element.nSerie = '';

            element.idPieza = -1;
            element.pieza = '';

            element.idParte = -1;
            element.parte = '';

            element.cantidadTerminada = 0;
            element.cantidadAchatarrada = 0;
            element.cantidadApartada = 0;

            element.idOperacion = -1;
            element.nombre_Operacion = '';

            element.idCamprint_programa = -1;
            element.programa = '';
          }
          //#endregion

          return false;
        }
        return true
      })
      // ordenar la lista del grid con las nuevas lineas insertadas
      this.dataBonos = this.dataBonos.sort((a, b) => {
        if (new Date(a.fechaini).getTime() > new Date(b.fechaini).getTime()) return 1
        else if (new Date(a.fechaini).getTime() < new Date(b.fechaini).getTime()) return -1
        else return 0
      })
    } else if (this.esParadaJustificada) {
      this.dataBonos.every(element => {
        if (element.identificadorGrid == this.dataBonosSelecteds[0].identificadorGrid) {
          if (this.perdidaAsignada.idPerdida != -1) element.annadirMultiperdida = true;
          element.actualizarPerdida = true;
          element.perdida = this.perdidaAsignada.perdida;
          element.idPerdida = this.perdidaAsignada.idPerdida;
          element.solicitarOperacion = this.perdidaAsignada.solicitarOF;
          auxPerd = {
            fechaInicio: this.myFunctions.dateToYYYYMMDDtHHmmSS(new Date(element.fechaini)),
            fechaFin: this.myFunctions.dateToYYYYMMDDtHHmmSS(new Date(element.fechafin)),
            observacion: {
              id: this.perdidaAsignada.idPerdida,
              idTipoPerdida: this.perdidaAsignada.idTipoPerdida,
              titulo: this.perdidaAsignada.perdida,
              descripcion: this.perdidaAsignada.explicacion,
              requiereExplicacion: this.perdidaAsignada.requiereExplicacion,
              tieneSubtipos: this.perdidaAsignada.tieneSubtipos,
            }
          }
          this.timelinePerdidas.set(element.identificadorGrid, { justificarHastaFinProceso: this.justificarHastaFinProceso, idsHistoricoProceso: '', timelinePerdidas: [auxPerd] })


          //#region Si la perdida no tiene operacion entonces se debe quitar la operacion
          if (!element.solicitarOperacion && element.idPerdida > 0) {
            // Si la operacion anterior estaba terminada y ahora se ha cambiado de operacion preguntar si se quiere quitar lo terminado
            if (element.terminadoAnterior) {
              element.abrirOperacionPreguntar = true;
              element.abrirOperacion = false;
              element.abrirPieza = false;
              element.terminado = false;
              if (this.modalReferenceAbrirOperacion == undefined) {
                if (element.terminadoAnterior && element.piezaTerminadaAnterior) this.modalReferenceAbrirOperacion = this.modalService.open(this.popupAbrirOperacionYPieza, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
                else if (element.terminadoAnterior) this.modalReferenceAbrirOperacion = this.modalService.open(this.popupAbrirOperacion, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
                else if (element.piezaTerminadaAnterior) this.modalReferenceAbrirOperacion = this.modalService.open(this.popupAbrirPieza, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
              }
            }

            element.idOF = -1;
            element.numeroOF_OF = '';
            //element.cliente = '';
            element.lote = '';
            element.colada = '';
            element.nSerie = '';

            element.idPieza = -1;
            element.pieza = '';

            element.idParte = -1;
            element.parte = '';

            element.cantidadTerminada = 0;
            element.cantidadAchatarrada = 0;
            element.cantidadApartada = 0;

            element.idOperacion = -1;
            element.nombre_Operacion = '';

            element.idCamprint_programa = -1;
            element.programa = '';
          }
          //#endregion

          return false;
        }
        return true
      })
    }

    //cerrar popup
    this.modalReferenceAsignar.close();
    this.generarGanttBonos();
    this.mySelectionBonos = [];
  }
  //#endregion

  //#region HMI CANTIDADES PIEZAS ASIGNADAS
  btnAnnadir_HMI_CantidadesPiezasAsignadas(cantidadTerminada = 0) {
    var idGrid = Math.min(...this.hmiCantidadesAsignadas.map(value => { return value.id }));
    if (idGrid > 0) idGrid = 0;
    // Ordenar dataBonosSelecteds por fechafin descendente y obtener la fechafin mas reciente
    var informacion = this.dataBonosSelecteds.sort((a, b) => {
      if (new Date(a.fechafin).getTime() < new Date(b.fechafin).getTime()) return 1
      else if (new Date(a.fechafin).getTime() > new Date(b.fechafin).getTime()) return -1
      else return 0
    })[0];
    var fechaAux = new Date(informacion.fechafin);
    fechaAux.setSeconds(fechaAux.getSeconds() - 1);
    var identificador = this.hmiCantidadesAsignadas.length + "_" + this.myFunctions.dateToHHMMSS(fechaAux);
    this.hmiCantidadesAsignadas.push({
      cantidadAchatarrada: 0
      , cantidadApartada: 0
      , cantidadTerminada: cantidadTerminada
      , eliminar: false
      , fecha: fechaAux
      , fechaAnnadir: this.myFunctions.dateToHHMM(fechaAux)
      , fechafin: new Date(informacion.fechafin)
      , fechaini: new Date(informacion.fechaini)
      , id: idGrid - 1
      , idHistorico_operaciones: informacion.idHO
      , idHistorico_operaciones_Anterior: informacion.idHO
      , idHistoricoProcesos: -1
      , idMaquina: this.selectedMaquina
      , insertar: true
      , nombreMaquina: this.maquinas.filter(f => f.id == this.selectedMaquina)[0].nombre
      , nombreOperario: ' '
      , terminado: false
      , piezaTerminada: false
      , quitarTerminado: false
      , quitarPiezaTerminada: false
      , idOperacion: informacion.idOperacion
      , idOperacionAnterior: informacion.idOperacionAnterior
      , idOperario: informacion.idOperario
      , identificador: identificador
      , idOFs_Partes_NumerosDeSerie: informacion.idOFs_Partes_NumerosDeSerie
    })
  }
  btnEliminar_HMI_CantidadesPiezasAsignadas() {
    this.hmiCantidadesAsignadas.forEach(element => {
      if (this.HMI_cantidadesPiezas_seleccionadas.includes(element.id)) {
        element.eliminar = true;
      } else {
        element.eliminar = false;
      }
    });
  }
  onChangeFecha(event, id) {
    if (event.length > 5) {

      this.hmiCantidadesAsignadas.every(f => {
        if (f.id == id) {
          f.fechaAnnadir = this.myFunctions.dateToHHMM(f.fechaini);
          f.fecha = new Date(f.fechaini);
          return false
        }
        return true
      })

    } else if (event.length == 5) {

      this.hmiCantidadesAsignadas.every(f => {
        if (f.id == id) {
          f.fecha.setHours(event[0] + event[1]);
          f.fecha.setMinutes(event[3] + event[4]);
          if (!(f.fechaini.getTime() <= f.fecha.getTime() && f.fecha.getTime() < f.fechafin.getTime())) {
            if (f.fechafin.getTime() <= f.fecha.getTime()) { // esta mas cerca de la fechafin por lo que se establece la fechafin 
              f.fecha = new Date(f.fechafin);
              f.fecha.setSeconds(f.fecha.getSeconds() - 1);
              f.fechaAnnadir = this.myFunctions.dateToHHMM(f.fecha);
            } else { // esta mas cerca de la fechaini por lo que se establece la fechamin
              f.fechaAnnadir = this.myFunctions.dateToHHMM(f.fechaini);
              f.fecha = new Date(f.fechaini);
            }
          }
          return false
        }
        return true
      })
    }
  }
  public rowCallback(context: RowClassArgs) {
    switch (context.dataItem.eliminar) {
      case false:
        return "";
      case true:
        return "d-none";
      default:
        return "";
    }
  }
  //#endregion

  // Esta funcion comprueba que entre las OF-s seleccionadas no se encuentren diferentes
  // Si se encuentra mas de una OF, entonces se devuelve true para que el boton editar OF se deshabilite
  comprobarOFUnica() {
    var OFsSeleccionadas = [];
    if (this.Jbonos != undefined)
      OFsSeleccionadas = this.Jbonos.filter(f => this.mySelectionBonos.includes(f.identificadorGrid)).map(f => f.idOF).filter(function (v, i, self) {
        return i == self.indexOf(v);
      });
    return OFsSeleccionadas.length > 1;
  }

  //#region DISTRIBUCION TIEMPOS
  clickDistribucionTiempos(popup) {
    this.distribucionTiemposOpen = true;

    this.dataBonosSelecteds = this.dataBonos.filter(f => this.mySelectionBonos.includes(f.identificadorGrid));

    this.fechaIni = new Date(this.dataBonosSelecteds[0].fechaini);
    this.fechaFin = new Date(this.dataBonosSelecteds[this.dataBonosSelecteds.length - 1].fechafin);
    this.fechaIniMin = new Date(this.fechaIni);
    this.fechaFinMax = new Date(this.fechaFin);

    this.dataDistribucionTiempos = [];
    if (this.dataBonosSelecteds[0]?.idOperacion > 0) {
      var operacion = this.dataBonosSelecteds[0];
      this.dataDistribucionTiempos.push({
        orden: 1
        , numeroOF: operacion.numeroOF_OF
        , nombreCliente: operacion.nombreCliente_OF
        , nombrePieza: operacion.pieza
        , nombreOperacion: operacion.nombre_Operacion
        , referencia: operacion.numeroOF_OF + ' - ' + operacion.nombreCliente_OF + ' - ' + operacion.pieza + ' - ' + operacion.nombre_Operacion
        , nSerie: operacion.nSerie
        , colada: operacion.colada
        , lote: operacion.lote
        , porcentaje: 100.0
        , annadir: false
        , eliminar: false
        , idOperacion: operacion.idOperacion
        , idProcesos_Tipo: 3
        , cantidad: operacion.cantidadTotalAHacer
        , tiempoEstimado: operacion.tiempoEstimado * operacion.cantidadTotalAHacer

        , idOF: operacion.idOF
        , idHO: -1
        , idPieza: operacion.idPieza
        , idParte: operacion.idParte
        , parte: operacion.parte
      })
    }

    this.dataDistribucionTiempos.push(this.distribucionTiemposPorDefecto);

    // Abrir popup
    this.modalReferenceAsignar = this.modalService.open(popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  distribucionTiemposAsignarOF(orden) {
    this.ordenSeleccionado = orden;

    this.clickEditarOF(this.popupEditar, true);
  }

  OFSeleccionada() {

    // Primero comprobar que la operacion se ha seleccionado
    if (this.JprogramasSelected == undefined) {
      this.avisoPreparacionLabel = this.translateService.instant("informacionIncompleta");
      this.modalReferenceAvisoPreparacion = this.modalService.open(this.popupAvisoPreparacion, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      return;
    }

    // Se mira cual debe ser el orden si el seleccionado ha sido una nueva linea
    var ordenTeorico = this.ordenSeleccionado;
    if (this.ordenSeleccionado == 0) {
      ordenTeorico = Math.max(...this.dataDistribucionTiempos.map(f => f.orden)) + 1;
    }

    // se crea la fila que se debe insertar/actualizar en asignarPreparacion
    var infOperacion = {
      orden: ordenTeorico
      , numeroOF: this.JproyectoSelected.text
      , nombreCliente: this.JproyectoSelected.cliente
      , nombrePieza: this.JpiezasSelected.text
      , nombreOperacion: this.JprogramasSelected.text
      , referencia: this.JproyectoSelected.text + ' - ' + this.JproyectoSelected.cliente + ' - ' + this.JpiezasSelected.text + ' - ' + this.JprogramasSelected.text
      , nSerie: (this.JnSerieSelected != undefined) ? this.JnSerieSelected.nSerie : ''
      , colada: (this.JnColadaSelected != undefined) ? this.JnColadaSelected.colada : ''
      , lote: (this.JnLotesSelected != undefined) ? this.JnLotesSelected.lote : ''
      , porcentaje: ''
      , annadir: false
      , eliminar: false
      , idOperacion: this.JprogramasSelected.value
      , idProcesos_Tipo: 3
      , cantidad: 0
      , tiempoEstimado: this.JprogramasSelected.tiempoEstimado * this.JprogramasSelected.cantidad

      , idOF: this.JproyectoSelected.value
      , idHO: -1
      , idPieza: this.JpiezasSelected.value
      , idParte: this.JpartesSelected.value
      , parte: this.JpartesSelected.text
    }

    // Se elimina el elemento que hay que actualizar y el de por defecto
    this.dataDistribucionTiempos = this.dataDistribucionTiempos.filter(f => f.orden != this.ordenSeleccionado && f.orden != 0);

    this.dataDistribucionTiempos.push(infOperacion)

    // Se ordena la lista
    this.dataDistribucionTiempos = this.dataDistribucionTiempos.sort((a, b) => (a.orden > b.orden) ? 1 : ((b.orden > a.orden) ? -1 : 0));
    this.dataDistribucionTiempos.push(this.distribucionTiemposPorDefecto);

    // Se actualizan los porcentajes
    this.actualizarPorcentajes();

    this.modalReference.close();
  }

  distribucionTiemposEliminar(orden) {
    this.dataDistribucionTiempos = this.dataDistribucionTiempos.filter(f => f.orden != orden);

    // corregir el orden
    var ordenAux = 1;
    this.dataDistribucionTiempos.forEach(element => {
      if (element.idOperacion != -1) {
        element.orden = ordenAux;
        ordenAux++;
      }
    });

    this.actualizarPorcentajes();
  }

  aceptarDistribucionTiempos() {

    // Primero es necesario comprobar que los porcentajes den 100
    var porcentajeAux = 0;
    this.dataDistribucionTiempos.forEach(element => {
      if (element.idOperacion > 0) porcentajeAux += element.porcentaje;
    });
    if (porcentajeAux < 99.5 || 100 < porcentajeAux) {
      this.avisoPreparacionLabel = this.translateService.instant("porcentajeIncorrecto");
      this.modalReferenceAvisoPreparacion = this.modalService.open(this.popupAvisoPreparacion, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      return;
    }

    // Procesar los datos indicados en el rango de fechas
    this.dataBonos.forEach(element => {
      if (element.actualizar_idProcesos_Tipo == undefined)
        element.actualizar_idProcesos_Tipo = false;
      // Si esta dentro del rango entonces se debe tratar al completo
      if (this.fechaIni <= new Date(element.fechaini) && new Date(element.fechafin) <= this.fechaFin) {
        element.idProcesos_Tipo = 3;
        element.actualizar_idProcesos_Tipo = true;
        // no se divide

        element['THHEjecucion'] = this.tiempoReal(0);
        element['THHAlarma'] = this.tiempoReal(0);
        element['THHApagado'] = this.tiempoReal(0);
        element['THHMantenimiento'] = this.tiempoReal(0);
        element['THHParada'] = this.tiempoReal(0);
        element['THHPreparacion'] = this.tiempoReal(element.tiemporeal);
        element['THHMicroParada'] = this.tiempoReal(0);

        element['tiempoEjecucion'] = 0;
        element['tiempoAlarma'] = 0;
        element['tiempoApagado'] = 0;
        element['tiempoMantenimiento'] = 0;
        element['tiempoParada'] = 0;
        element['tiempoPreparacion'] = element.tiemporeal;
        element['tiempoMicro'] = 0;

        element['TPorcentajeEjecucion'] = 0;
        element['TPorcentajeAlarma'] = 0;
        element['TPorcentajeApagado'] = 0;
        element['TPorcentajeMantenimiento'] = 0;
        element['TPorcentajeParada'] = 0;
        element['TPorcentajePreparacion'] = 100;
        element['TPorcentajeMicroParada'] = 0;
      }
      // La fechafin si esta dentro del rango pero la fechaini no
      else if (new Date(element.fechaini) < this.fechaIni && (this.fechaIni < new Date(element.fechafin) && new Date(element.fechafin) <= this.fechaFin)) {
        var lineaNueva = this.myFunctions.copy(element);
        element.idProcesos_Tipo = 3;
        element.actualizar_idProcesos_Tipo = true;

        element.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaIni);
        element.horaInicio = new Date(element.fechaini).toLocaleTimeString();
        lineaNueva.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaIni);
        lineaNueva.horaFin = new Date(lineaNueva.fechafin).toLocaleTimeString();

        //#region tiempo real y barra porcentaje
        element.tiempoRealHH = this.calcularTDiferencia(element['fechafin'], element['fechaini']);
        lineaNueva.tiempoRealHH = this.calcularTDiferencia(lineaNueva['fechafin'], lineaNueva['fechaini']);
        element.tiemporeal = (new Date(element.fechafin).getTime() - new Date(element.fechaini).getTime()) / 1000;
        lineaNueva.tiemporeal = (new Date(lineaNueva.fechafin).getTime() - new Date(lineaNueva.fechaini).getTime()) / 1000;


        element['THHEjecucion'] = this.tiempoReal(0);
        element['THHAlarma'] = this.tiempoReal(0);
        element['THHApagado'] = this.tiempoReal(0);
        element['THHMantenimiento'] = this.tiempoReal(0);
        element['THHParada'] = this.tiempoReal(0);
        element['THHPreparacion'] = this.tiempoReal(element.tiemporeal);
        element['THHMicroParada'] = this.tiempoReal(0);

        element['tiempoEjecucion'] = 0;
        element['tiempoAlarma'] = 0;
        element['tiempoApagado'] = 0;
        element['tiempoMantenimiento'] = 0;
        element['tiempoParada'] = 0;
        element['tiempoPreparacion'] = element.tiemporeal;
        element['tiempoMicro'] = 0;

        element['TPorcentajeEjecucion'] = 0;
        element['TPorcentajeAlarma'] = 0;
        element['TPorcentajeApagado'] = 0;
        element['TPorcentajeMantenimiento'] = 0;
        element['TPorcentajeParada'] = 0;
        element['TPorcentajePreparacion'] = 100;
        element['TPorcentajeMicroParada'] = 0;

        // lineaNueva['THHEjecucion'] = this.tiempoReal(0);
        // lineaNueva['THHAlarma'] = this.tiempoReal(0);
        // lineaNueva['THHApagado'] = this.tiempoReal(0);
        // lineaNueva['THHMantenimiento'] = this.tiempoReal(0);
        // lineaNueva['THHParada'] = this.tiempoReal(0);
        // lineaNueva['THHPreparacion'] = this.tiempoReal(lineaNueva.tiemporeal);
        // lineaNueva['THHMicroParada'] = this.tiempoReal(0);

        // lineaNueva['tiempoEjecucion'] = 0;
        // lineaNueva['tiempoAlarma'] = 0;
        // lineaNueva['tiempoApagado'] = 0;
        // lineaNueva['tiempoMantenimiento'] = 0;
        // lineaNueva['tiempoParada'] = 0;
        // lineaNueva['tiempoPreparacion'] = lineaNueva.tiemporeal;
        // lineaNueva['tiempoMicro'] = 0;

        // lineaNueva['TPorcentajeEjecucion'] = 0;
        // lineaNueva['TPorcentajeAlarma'] = 0;
        // lineaNueva['TPorcentajeApagado'] = 0;
        // lineaNueva['TPorcentajeMantenimiento'] = 0;
        // lineaNueva['TPorcentajeParada'] = 0;
        // lineaNueva['TPorcentajePreparacion'] = 100;
        // lineaNueva['TPorcentajeMicroParada'] = 0;
        //#endregion

        this.dataBonos.push(lineaNueva);
      }
      // La fechaini si esta dentro del rango pero la fechafin no 
      else if ((this.fechaIni <= new Date(element.fechaini) && new Date(element.fechaini) < this.fechaFin) && this.fechaFin < new Date(element.fechafin)) {
        var lineaNueva = this.myFunctions.copy(element);
        element.idProcesos_Tipo = 3;
        element.actualizar_idProcesos_Tipo = true;

        element.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaFin);
        element.horaFin = new Date(element.fechafin).toLocaleTimeString();
        lineaNueva.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaFin);
        lineaNueva.horaInicio = new Date(lineaNueva.fechaini).toLocaleTimeString();

        //#region tiempo real y barra porcentaje
        element.tiempoRealHH = this.calcularTDiferencia(element['fechafin'], element['fechaini']);
        lineaNueva.tiempoRealHH = this.calcularTDiferencia(lineaNueva['fechafin'], lineaNueva['fechaini']);
        element.tiemporeal = (new Date(element.fechafin).getTime() - new Date(element.fechaini).getTime()) / 1000;
        lineaNueva.tiemporeal = (new Date(lineaNueva.fechafin).getTime() - new Date(lineaNueva.fechaini).getTime()) / 1000;

        element['THHEjecucion'] = this.tiempoReal(0);
        element['THHAlarma'] = this.tiempoReal(0);
        element['THHApagado'] = this.tiempoReal(0);
        element['THHMantenimiento'] = this.tiempoReal(0);
        element['THHParada'] = this.tiempoReal(0);
        element['THHPreparacion'] = this.tiempoReal(element.tiemporeal);
        element['THHMicroParada'] = this.tiempoReal(0);

        element['tiempoEjecucion'] = 0;
        element['tiempoAlarma'] = 0;
        element['tiempoApagado'] = 0;
        element['tiempoMantenimiento'] = 0;
        element['tiempoParada'] = 0;
        element['tiempoPreparacion'] = element.tiemporeal;
        element['tiempoMicro'] = 0;

        element['TPorcentajeEjecucion'] = 0;
        element['TPorcentajeAlarma'] = 0;
        element['TPorcentajeApagado'] = 0;
        element['TPorcentajeMantenimiento'] = 0;
        element['TPorcentajeParada'] = 0;
        element['TPorcentajePreparacion'] = 100;
        element['TPorcentajeMicroParada'] = 0;

        // lineaNueva['THHEjecucion'] = this.tiempoReal(0);
        // lineaNueva['THHAlarma'] = this.tiempoReal(0);
        // lineaNueva['THHApagado'] = this.tiempoReal(0);
        // lineaNueva['THHMantenimiento'] = this.tiempoReal(0);
        // lineaNueva['THHParada'] = this.tiempoReal(0);
        // lineaNueva['THHPreparacion'] = this.tiempoReal(lineaNueva.tiemporeal);
        // lineaNueva['THHMicroParada'] = this.tiempoReal(0);

        // lineaNueva['tiempoEjecucion'] = 0;
        // lineaNueva['tiempoAlarma'] = 0;
        // lineaNueva['tiempoApagado'] = 0;
        // lineaNueva['tiempoMantenimiento'] = 0;
        // lineaNueva['tiempoParada'] = 0;
        // lineaNueva['tiempoPreparacion'] = lineaNueva.tiemporeal;
        // lineaNueva['tiempoMicro'] = 0;

        // lineaNueva['TPorcentajeEjecucion'] = 0;
        // lineaNueva['TPorcentajeAlarma'] = 0;
        // lineaNueva['TPorcentajeApagado'] = 0;
        // lineaNueva['TPorcentajeMantenimiento'] = 0;
        // lineaNueva['TPorcentajeParada'] = 0;
        // lineaNueva['TPorcentajePreparacion'] = 100;
        // lineaNueva['TPorcentajeMicroParada'] = 0;
        //#endregion

        this.dataBonos.push(lineaNueva);
      }
      // La fechaini es menor al rango y la fechafin mayor
      else if (new Date(element.fechaini) < this.fechaIni && this.fechaFin < new Date(element.fechafin)) {
        var lineaNuevaIzq = this.myFunctions.copy(element);
        var lineaNuevaDer = this.myFunctions.copy(element);
        element.idProcesos_Tipo = 3;
        element.actualizar_idProcesos_Tipo = true;

        element.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaIni);
        element.horaInicio = new Date(element.fechaini).toLocaleTimeString();
        element.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaFin);
        element.horaFin = new Date(element.fechafin).toLocaleTimeString();
        lineaNuevaIzq.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaIni);
        lineaNuevaIzq.horaFin = this.calcularTDiferencia(lineaNuevaIzq['fechafin'], lineaNuevaIzq['fechaini']);
        lineaNuevaDer.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaFin);
        lineaNuevaDer.horaInicio = new Date(lineaNuevaDer.fechaini).toLocaleTimeString();

        //#region tiempo real y barra porcentaje
        element.tiempoRealHH = this.calcularTDiferencia(element['fechafin'], element['fechaini']);
        lineaNuevaIzq.tiempoRealHH = this.calcularTDiferencia(lineaNuevaIzq['fechafin'], lineaNuevaIzq['fechaini']);
        lineaNuevaDer.tiempoRealHH = this.calcularTDiferencia(lineaNuevaDer['fechafin'], lineaNuevaDer['fechaini']);
        element.tiemporeal = (new Date(element.fechafin).getTime() - new Date(element.fechaini).getTime()) / 1000;
        lineaNuevaIzq.tiemporeal = (new Date(lineaNuevaIzq.fechafin).getTime() - new Date(lineaNuevaIzq.fechaini).getTime()) / 1000;
        lineaNuevaDer.tiemporeal = (new Date(lineaNuevaDer.fechafin).getTime() - new Date(lineaNuevaDer.fechaini).getTime()) / 1000;

        element['THHEjecucion'] = this.tiempoReal(0);
        element['THHAlarma'] = this.tiempoReal(0);
        element['THHApagado'] = this.tiempoReal(0);
        element['THHMantenimiento'] = this.tiempoReal(0);
        element['THHParada'] = this.tiempoReal(0);
        element['THHPreparacion'] = this.tiempoReal(element.tiemporeal);
        element['THHMicroParada'] = this.tiempoReal(0);

        element['tiempoEjecucion'] = 0;
        element['tiempoAlarma'] = 0;
        element['tiempoApagado'] = 0;
        element['tiempoMantenimiento'] = 0;
        element['tiempoParada'] = 0;
        element['tiempoPreparacion'] = element.tiemporeal;
        element['tiempoMicro'] = 0;

        element['TPorcentajeEjecucion'] = 0;
        element['TPorcentajeAlarma'] = 0;
        element['TPorcentajeApagado'] = 0;
        element['TPorcentajeMantenimiento'] = 0;
        element['TPorcentajeParada'] = 0;
        element['TPorcentajePreparacion'] = 100;
        element['TPorcentajeMicroParada'] = 0;

        // lineaNuevaIzq['THHEjecucion'] = this.tiempoReal(0);
        // lineaNuevaIzq['THHAlarma'] = this.tiempoReal(0);
        // lineaNuevaIzq['THHApagado'] = this.tiempoReal(0);
        // lineaNuevaIzq['THHMantenimiento'] = this.tiempoReal(0);
        // lineaNuevaIzq['THHParada'] = this.tiempoReal(0);
        // lineaNuevaIzq['THHPreparacion'] = this.tiempoReal(lineaNuevaIzq.tiemporeal);
        // lineaNuevaIzq['THHMicroParada'] = this.tiempoReal(0);

        // lineaNuevaIzq['tiempoEjecucion'] = 0;
        // lineaNuevaIzq['tiempoAlarma'] = 0;
        // lineaNuevaIzq['tiempoApagado'] = 0;
        // lineaNuevaIzq['tiempoMantenimiento'] = 0;
        // lineaNuevaIzq['tiempoParada'] = 0;
        // lineaNuevaIzq['tiempoPreparacion'] = lineaNuevaIzq.tiemporeal;
        // lineaNuevaIzq['tiempoMicro'] = 0;

        // lineaNuevaIzq['TPorcentajeEjecucion'] = 0;
        // lineaNuevaIzq['TPorcentajeAlarma'] = 0;
        // lineaNuevaIzq['TPorcentajeApagado'] = 0;
        // lineaNuevaIzq['TPorcentajeMantenimiento'] = 0;
        // lineaNuevaIzq['TPorcentajeParada'] = 0;
        // lineaNuevaIzq['TPorcentajePreparacion'] = 100;
        // lineaNuevaIzq['TPorcentajeMicroParada'] = 0;

        // lineaNuevaDer['THHEjecucion'] = this.tiempoReal(0);
        // lineaNuevaDer['THHAlarma'] = this.tiempoReal(0);
        // lineaNuevaDer['THHApagado'] = this.tiempoReal(0);
        // lineaNuevaDer['THHMantenimiento'] = this.tiempoReal(0);
        // lineaNuevaDer['THHParada'] = this.tiempoReal(0);
        // lineaNuevaDer['THHPreparacion'] = this.tiempoReal(lineaNuevaDer.tiemporeal);
        // lineaNuevaDer['THHMicroParada'] = this.tiempoReal(0);

        // lineaNuevaDer['tiempoEjecucion'] = 0;
        // lineaNuevaDer['tiempoAlarma'] = 0;
        // lineaNuevaDer['tiempoApagado'] = 0;
        // lineaNuevaDer['tiempoMantenimiento'] = 0;
        // lineaNuevaDer['tiempoParada'] = 0;
        // lineaNuevaDer['tiempoPreparacion'] = lineaNuevaDer.tiemporeal;
        // lineaNuevaDer['tiempoMicro'] = 0;

        // lineaNuevaDer['TPorcentajeEjecucion'] = 0;
        // lineaNuevaDer['TPorcentajeAlarma'] = 0;
        // lineaNuevaDer['TPorcentajeApagado'] = 0;
        // lineaNuevaDer['TPorcentajeMantenimiento'] = 0;
        // lineaNuevaDer['TPorcentajeParada'] = 0;
        // lineaNuevaDer['TPorcentajePreparacion'] = 100;
        // lineaNuevaDer['TPorcentajeMicroParada'] = 0;
        //#endregion

        this.dataBonos.push(lineaNuevaIzq);
        this.dataBonos.push(lineaNuevaDer);
      }
    });

    // Indicar cuales son los rangos para cortar (para eso se tiene en cuenta el porcentaje)
    var rangos = [];
    var tiempoTotalSegundos = (new Date(this.fechaFin).getTime() - new Date(this.fechaIni).getTime()) / 1000
    var fechainiAux = new Date(this.fechaIni);
    var fechafinAux = new Date(this.fechaFin);
    var index = 0;
    this.dataDistribucionTiempos.forEach(element => {
      if (index == this.dataDistribucionTiempos.length - 1)
        fechafinAux = this.fechaFin
      else
        fechafinAux = this.establecerFechaFin(fechainiAux, element.porcentaje, tiempoTotalSegundos);

      element.fechaini = fechainiAux;
      element.fechafin = fechafinAux;
      if (element.idOperacion != -1) rangos.push(element);
      fechainiAux = new Date(fechafinAux);
      index++;
    });

    // Cortar las filas con las operaciones y porcentajes indicados
    this.cortarFilas(rangos);

    // ordenar la lista del grid con las nuevas lineas insertadas
    this.dataBonos = this.dataBonos.sort((a, b) => {
      if (new Date(a.fechaini).getTime() > new Date(b.fechaini).getTime()) return 1
      else if (new Date(a.fechaini).getTime() < new Date(b.fechaini).getTime()) return -1
      else return 0
    })
    this.dataBonos = this.myFunctions.copy(this.dataBonos);
    this.Jbonos = this.dataBonos;

    //cerrar popup
    this.modalReferenceAsignar.close();
    this.distribucionTiemposOpen = false;
    this.generarGanttBonos();
    this.mySelectionBonos = [];
  }

  establecerFechaFin(fechaini, porcentaje, tiempoTotal) {
    var segundosASumar = tiempoTotal * porcentaje / 100;
    var result = new Date(fechaini);
    result.setSeconds(result.getSeconds() + segundosASumar);
    return result;
  }

  cortarFilas(rangos) {
    this.dataBonos.forEach(element => {
      rangos.forEach(rango => {
        // CASO 1: esta dentro del rango
        if (rango.fechaini <= new Date(element.fechaini) && new Date(element.fechafin) <= rango.fechafin) {
          element.idHO = rango.idHO;
          element.idOF = rango.idOF;
          element.idOperacion = rango.idOperacion;
          element.idParte = rango.idParte;
          element.idPieza = rango.idPieza;
          element.idProcesos_Tipo = rango.idProcesos_Tipo;
          element.lote = rango.lote;
          element.nombreCliente = rango.nombreCliente;
          element.nombre_Operacion = rango.nombreOperacion;
          element.pieza = rango.nombrePieza;
          element.nuevoNserie = rango.nSerie;
          element.numeroOF_OF = rango.numeroOF;
          element.parte = rango.parte;
        }
        // CASO 2: Se sale por la izquierda, cortar primera parte y la segunda queda dentro
        else if (new Date(element.fechaini) < rango.fechaini && (rango.fechaini < new Date(element.fechafin) && new Date(element.fechafin) <= rango.fechafin)) {
          var lineaNueva = this.myFunctions.copy(element);

          element.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechaini);
          element.horaInicio = new Date(element.fechaini).toLocaleTimeString();
          lineaNueva.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechaini);
          lineaNueva.horaFin = new Date(lineaNueva.fechafin).toLocaleTimeString();

          //#region tiempos
          element.tiempoRealHH = this.calcularTDiferencia(element['fechafin'], element['fechaini']);
          lineaNueva.tiempoRealHH = this.calcularTDiferencia(lineaNueva['fechafin'], lineaNueva['fechaini']);
          element.tiemporeal = (new Date(element.fechafin).getTime() - new Date(element.fechaini).getTime()) / 1000;
          lineaNueva.tiemporeal = (new Date(lineaNueva.fechafin).getTime() - new Date(lineaNueva.fechaini).getTime()) / 1000;

          element['THHEjecucion'] = this.tiempoReal(0);
          element['THHAlarma'] = this.tiempoReal(0);
          element['THHApagado'] = this.tiempoReal(0);
          element['THHMantenimiento'] = this.tiempoReal(0);
          element['THHParada'] = this.tiempoReal(0);
          element['THHPreparacion'] = this.tiempoReal(element.tiemporeal);
          element['THHMicroParada'] = this.tiempoReal(0);

          element['tiempoEjecucion'] = 0;
          element['tiempoAlarma'] = 0;
          element['tiempoApagado'] = 0;
          element['tiempoMantenimiento'] = 0;
          element['tiempoParada'] = 0;
          element['tiempoPreparacion'] = element.tiemporeal;
          element['tiempoMicro'] = 0;
          //#endregion

          element.idHO = rango.idHO;
          element.idOF = rango.idOF;
          element.idOperacion = rango.idOperacion;
          element.idParte = rango.idParte;
          element.idPieza = rango.idPieza;
          element.idProcesos_Tipo = rango.idProcesos_Tipo;
          element.lote = rango.lote;
          element.nombreCliente = rango.nombreCliente;
          element.nombre_Operacion = rango.nombreOperacion;
          element.pieza = rango.nombrePieza;
          element.nuevoNserie = rango.nSerie;
          element.numeroOF_OF = rango.numeroOF;
          element.parte = rango.parte;

          lineaNueva = this.establecerOF(lineaNueva, rangos);
          this.dataBonos.push(lineaNueva);
        }
        // CASO 3: Se sale por ambos lados, cortar en tres trozos y la del medio queda dentro
        else if (new Date(element.fechaini) < rango.fechaini && rango.fechafin < new Date(element.fechafin)) {
          var lineaNuevaIzq = this.myFunctions.copy(element);
          var lineaNuevaDer = this.myFunctions.copy(element);

          element.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechaini);
          element.horaInicio = new Date(element.fechaini).toLocaleTimeString();
          element.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechafin);
          element.horaFin = new Date(element.fechafin).toLocaleTimeString();
          lineaNuevaIzq.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechaini);
          lineaNuevaIzq.horaFin = this.calcularTDiferencia(lineaNuevaIzq['fechafin'], lineaNuevaIzq['fechaini']);
          lineaNuevaDer.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechafin);
          lineaNuevaDer.horaInicio = new Date(lineaNuevaDer.fechaini).toLocaleTimeString();

          //#region tiempos
          element.tiempoRealHH = this.calcularTDiferencia(element['fechafin'], element['fechaini']);
          lineaNuevaIzq.tiempoRealHH = this.calcularTDiferencia(lineaNuevaIzq['fechafin'], lineaNuevaIzq['fechaini']);
          lineaNuevaDer.tiempoRealHH = this.calcularTDiferencia(lineaNuevaDer['fechafin'], lineaNuevaDer['fechaini']);
          element.tiemporeal = (new Date(element.fechafin).getTime() - new Date(element.fechaini).getTime()) / 1000;
          lineaNuevaIzq.tiemporeal = (new Date(lineaNuevaIzq.fechafin).getTime() - new Date(lineaNuevaIzq.fechaini).getTime()) / 1000;
          lineaNuevaDer.tiemporeal = (new Date(lineaNuevaDer.fechafin).getTime() - new Date(lineaNuevaDer.fechaini).getTime()) / 1000;

          element['THHEjecucion'] = this.tiempoReal(0);
          element['THHAlarma'] = this.tiempoReal(0);
          element['THHApagado'] = this.tiempoReal(0);
          element['THHMantenimiento'] = this.tiempoReal(0);
          element['THHParada'] = this.tiempoReal(0);
          element['THHPreparacion'] = this.tiempoReal(element.tiemporeal);
          element['THHMicroParada'] = this.tiempoReal(0);

          element['tiempoEjecucion'] = 0;
          element['tiempoAlarma'] = 0;
          element['tiempoApagado'] = 0;
          element['tiempoMantenimiento'] = 0;
          element['tiempoParada'] = 0;
          element['tiempoPreparacion'] = element.tiemporeal;
          element['tiempoMicro'] = 0;
          //#endregion

          element.idHO = rango.idHO;
          element.idOF = rango.idOF;
          element.idOperacion = rango.idOperacion;
          element.idParte = rango.idParte;
          element.idPieza = rango.idPieza;
          element.idProcesos_Tipo = rango.idProcesos_Tipo;
          element.lote = rango.lote;
          element.nombreCliente = rango.nombreCliente;
          element.nombre_Operacion = rango.nombreOperacion;
          element.pieza = rango.nombrePieza;
          element.nuevoNserie = rango.nSerie;
          element.numeroOF_OF = rango.numeroOF;
          element.parte = rango.parte;

          lineaNuevaIzq = this.establecerOF(lineaNuevaIzq, rangos);
          lineaNuevaDer = this.establecerOF(lineaNuevaDer, rangos);
          this.dataBonos.push(lineaNuevaIzq);
          this.dataBonos.push(lineaNuevaDer);

        }
        // CASO 4: Se sale por la derecha, cortar segunda parte y la primera queda dentro
        else if ((rango.fechaini <= new Date(element.fechaini) && new Date(element.fechaini) < rango.fechafin) && rango.fechafin < new Date(element.fechafin)) {
          var lineaNueva = this.myFunctions.copy(element);

          element.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechafin);
          element.horaFin = new Date(element.fechafin).toLocaleTimeString();
          lineaNueva.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechafin);
          lineaNueva.horaInicio = new Date(lineaNueva.fechaini).toLocaleTimeString();

          //#region tiempos
          element.tiempoRealHH = this.calcularTDiferencia(element['fechafin'], element['fechaini']);
          lineaNueva.tiempoRealHH = this.calcularTDiferencia(lineaNueva['fechafin'], lineaNueva['fechaini']);
          element.tiemporeal = (new Date(element.fechafin).getTime() - new Date(element.fechaini).getTime()) / 1000;
          lineaNueva.tiemporeal = (new Date(lineaNueva.fechafin).getTime() - new Date(lineaNueva.fechaini).getTime()) / 1000;

          element['THHEjecucion'] = this.tiempoReal(0);
          element['THHAlarma'] = this.tiempoReal(0);
          element['THHApagado'] = this.tiempoReal(0);
          element['THHMantenimiento'] = this.tiempoReal(0);
          element['THHParada'] = this.tiempoReal(0);
          element['THHPreparacion'] = this.tiempoReal(element.tiemporeal);
          element['THHMicroParada'] = this.tiempoReal(0);

          element['tiempoEjecucion'] = 0;
          element['tiempoAlarma'] = 0;
          element['tiempoApagado'] = 0;
          element['tiempoMantenimiento'] = 0;
          element['tiempoParada'] = 0;
          element['tiempoPreparacion'] = element.tiemporeal;
          element['tiempoMicro'] = 0;
          //#endregion

          element.idHO = rango.idHO;
          element.idOF = rango.idOF;
          element.idOperacion = rango.idOperacion;
          element.idParte = rango.idParte;
          element.idPieza = rango.idPieza;
          element.idProcesos_Tipo = rango.idProcesos_Tipo;
          element.lote = rango.lote;
          element.nombreCliente = rango.nombreCliente;
          element.nombre_Operacion = rango.nombreOperacion;
          element.pieza = rango.nombrePieza;
          element.nuevoNserie = rango.nSerie;
          element.numeroOF_OF = rango.numeroOF;
          element.parte = rango.parte;

          lineaNueva = this.establecerOF(lineaNueva, rangos);
          this.dataBonos.push(lineaNueva);
        }
      });
    })
  }

  // Funcion recursiva para ir editando las filas
  establecerOF(element, rangos) {
    rangos.forEach(rango => {
      // CASO 1: esta dentro del rango
      if (rango.fechaini <= new Date(element.fechaini) && new Date(element.fechafin) <= rango.fechafin) {

        //#region tiempos
        element.tiemporeal = (new Date(element.fechafin).getTime() - new Date(element.fechaini).getTime()) / 1000;

        element['THHEjecucion'] = this.tiempoReal(0);
        element['THHAlarma'] = this.tiempoReal(0);
        element['THHApagado'] = this.tiempoReal(0);
        element['THHMantenimiento'] = this.tiempoReal(0);
        element['THHParada'] = this.tiempoReal(0);
        element['THHPreparacion'] = this.tiempoReal(element.tiemporeal);
        element['THHMicroParada'] = this.tiempoReal(0);

        element['tiempoEjecucion'] = 0;
        element['tiempoAlarma'] = 0;
        element['tiempoApagado'] = 0;
        element['tiempoMantenimiento'] = 0;
        element['tiempoParada'] = 0;
        element['tiempoPreparacion'] = element.tiemporeal;
        element['tiempoMicro'] = 0;
        //#endregion

        element.idHO = rango.idHO;
        element.idOF = rango.idOF;
        element.idOperacion = rango.idOperacion;
        element.idParte = rango.idParte;
        element.idPieza = rango.idPieza;
        element.idProcesos_Tipo = rango.idProcesos_Tipo;
        element.lote = rango.lote;
        element.nombreCliente = rango.nombreCliente;
        element.nombre_Operacion = rango.nombreOperacion;
        element.pieza = rango.nombrePieza;
        element.nuevoNserie = rango.nSerie;
        element.numeroOF_OF = rango.numeroOF;
        element.parte = rango.parte;
      }
      // CASO 2: Se sale por la izquierda, cortar primera parte y la segunda queda dentro
      else if (new Date(element.fechaini) < rango.fechaini && (rango.fechaini < new Date(element.fechafin) && new Date(element.fechafin) <= rango.fechafin)) {
        var lineaNueva = this.myFunctions.copy(element);

        element.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechaini);
        element.horaInicio = new Date(element.fechaini).toLocaleTimeString();
        lineaNueva.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechaini);
        lineaNueva.horaFin = new Date(lineaNueva.fechafin).toLocaleTimeString();

        //#region tiempos
        element.tiempoRealHH = this.calcularTDiferencia(element['fechafin'], element['fechaini']);
        lineaNueva.tiempoRealHH = this.calcularTDiferencia(lineaNueva['fechafin'], lineaNueva['fechaini']);
        element.tiemporeal = (new Date(element.fechafin).getTime() - new Date(element.fechaini).getTime()) / 1000;
        lineaNueva.tiemporeal = (new Date(lineaNueva.fechafin).getTime() - new Date(lineaNueva.fechaini).getTime()) / 1000;

        element['THHEjecucion'] = this.tiempoReal(0);
        element['THHAlarma'] = this.tiempoReal(0);
        element['THHApagado'] = this.tiempoReal(0);
        element['THHMantenimiento'] = this.tiempoReal(0);
        element['THHParada'] = this.tiempoReal(0);
        element['THHPreparacion'] = this.tiempoReal(element.tiemporeal);
        element['THHMicroParada'] = this.tiempoReal(0);

        element['tiempoEjecucion'] = 0;
        element['tiempoAlarma'] = 0;
        element['tiempoApagado'] = 0;
        element['tiempoMantenimiento'] = 0;
        element['tiempoParada'] = 0;
        element['tiempoPreparacion'] = element.tiemporeal;
        element['tiempoMicro'] = 0;
        //#endregion

        element.idHO = rango.idHO;
        element.idOF = rango.idOF;
        element.idOperacion = rango.idOperacion;
        element.idParte = rango.idParte;
        element.idPieza = rango.idPieza;
        element.idProcesos_Tipo = rango.idProcesos_Tipo;
        element.lote = rango.lote;
        element.nombreCliente = rango.nombreCliente;
        element.nombre_Operacion = rango.nombreOperacion;
        element.pieza = rango.nombrePieza;
        element.nuevoNserie = rango.nSerie;
        element.numeroOF_OF = rango.numeroOF;
        element.parte = rango.parte;

        lineaNueva = this.establecerOF(lineaNueva, rangos);
        this.dataBonos.push(lineaNueva);
      }
      // CASO 3: Se sale por ambos lados, cortar en tres trozos y la del medio queda dentro
      else if (new Date(element.fechaini) < rango.fechaini && rango.fechafin < new Date(element.fechafin)) {
        var lineaNuevaIzq = this.myFunctions.copy(element);
        var lineaNuevaDer = this.myFunctions.copy(element);

        element.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechaini);
        element.horaInicio = new Date(element.fechaini).toLocaleTimeString();
        element.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechafin);
        element.horaFin = new Date(element.fechafin).toLocaleTimeString();
        lineaNuevaIzq.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechaini);
        lineaNuevaIzq.horaFin = this.calcularTDiferencia(lineaNuevaIzq['fechafin'], lineaNuevaIzq['fechaini']);
        lineaNuevaDer.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechafin);
        lineaNuevaDer.horaInicio = new Date(lineaNuevaDer.fechaini).toLocaleTimeString();

        //#region tiempos
        element.tiempoRealHH = this.calcularTDiferencia(element['fechafin'], element['fechaini']);
        lineaNuevaIzq.tiempoRealHH = this.calcularTDiferencia(lineaNuevaIzq['fechafin'], lineaNuevaIzq['fechaini']);
        lineaNuevaDer.tiempoRealHH = this.calcularTDiferencia(lineaNuevaDer['fechafin'], lineaNuevaDer['fechaini']);
        element.tiemporeal = (new Date(element.fechafin).getTime() - new Date(element.fechaini).getTime()) / 1000;
        lineaNuevaIzq.tiemporeal = (new Date(lineaNuevaIzq.fechafin).getTime() - new Date(lineaNuevaIzq.fechaini).getTime()) / 1000;
        lineaNuevaDer.tiemporeal = (new Date(lineaNuevaDer.fechafin).getTime() - new Date(lineaNuevaDer.fechaini).getTime()) / 1000;

        element['THHEjecucion'] = this.tiempoReal(0);
        element['THHAlarma'] = this.tiempoReal(0);
        element['THHApagado'] = this.tiempoReal(0);
        element['THHMantenimiento'] = this.tiempoReal(0);
        element['THHParada'] = this.tiempoReal(0);
        element['THHPreparacion'] = this.tiempoReal(element.tiemporeal);
        element['THHMicroParada'] = this.tiempoReal(0);

        element['tiempoEjecucion'] = 0;
        element['tiempoAlarma'] = 0;
        element['tiempoApagado'] = 0;
        element['tiempoMantenimiento'] = 0;
        element['tiempoParada'] = 0;
        element['tiempoPreparacion'] = element.tiemporeal;
        element['tiempoMicro'] = 0;
        //#endregion

        element.idHO = rango.idHO;
        element.idOF = rango.idOF;
        element.idOperacion = rango.idOperacion;
        element.idParte = rango.idParte;
        element.idPieza = rango.idPieza;
        element.idProcesos_Tipo = rango.idProcesos_Tipo;
        element.lote = rango.lote;
        element.nombreCliente = rango.nombreCliente;
        element.nombre_Operacion = rango.nombreOperacion;
        element.pieza = rango.nombrePieza;
        element.nuevoNserie = rango.nSerie;
        element.numeroOF_OF = rango.numeroOF;
        element.parte = rango.parte;

        lineaNuevaIzq = this.establecerOF(lineaNuevaIzq, rangos);
        lineaNuevaDer = this.establecerOF(lineaNuevaDer, rangos);
        this.dataBonos.push(lineaNuevaIzq);
        this.dataBonos.push(lineaNuevaDer);

      }
      // CASO 4: Se sale por la derecha, cortar segunda parte y la primera queda dentro
      else if ((rango.fechaini <= new Date(element.fechaini) && new Date(element.fechaini) < rango.fechafin) && rango.fechafin < new Date(element.fechafin)) {
        var lineaNueva = this.myFunctions.copy(element);

        element.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechafin);
        element.horaFin = new Date(element.fechafin).toLocaleTimeString();
        lineaNueva.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(rango.fechafin);
        lineaNueva.horaInicio = new Date(lineaNueva.fechaini).toLocaleTimeString();

        //#region tiempos
        element.tiempoRealHH = this.calcularTDiferencia(element['fechafin'], element['fechaini']);
        lineaNueva.tiempoRealHH = this.calcularTDiferencia(lineaNueva['fechafin'], lineaNueva['fechaini']);
        element.tiemporeal = (new Date(element.fechafin).getTime() - new Date(element.fechaini).getTime()) / 1000;
        lineaNueva.tiemporeal = (new Date(lineaNueva.fechafin).getTime() - new Date(lineaNueva.fechaini).getTime()) / 1000;

        element['THHEjecucion'] = this.tiempoReal(0);
        element['THHAlarma'] = this.tiempoReal(0);
        element['THHApagado'] = this.tiempoReal(0);
        element['THHMantenimiento'] = this.tiempoReal(0);
        element['THHParada'] = this.tiempoReal(0);
        element['THHPreparacion'] = this.tiempoReal(element.tiemporeal);
        element['THHMicroParada'] = this.tiempoReal(0);

        element['tiempoEjecucion'] = 0;
        element['tiempoAlarma'] = 0;
        element['tiempoApagado'] = 0;
        element['tiempoMantenimiento'] = 0;
        element['tiempoParada'] = 0;
        element['tiempoPreparacion'] = element.tiemporeal;
        element['tiempoMicro'] = 0;
        //#endregion

        element.idHO = rango.idHO;
        element.idOF = rango.idOF;
        element.idOperacion = rango.idOperacion;
        element.idParte = rango.idParte;
        element.idPieza = rango.idPieza;
        element.idProcesos_Tipo = rango.idProcesos_Tipo;
        element.lote = rango.lote;
        element.nombreCliente = rango.nombreCliente;
        element.nombre_Operacion = rango.nombreOperacion;
        element.pieza = rango.nombrePieza;
        element.nuevoNserie = rango.nSerie;
        element.numeroOF_OF = rango.numeroOF;
        element.parte = rango.parte;

        lineaNueva = this.establecerOF(lineaNueva, rangos);
        this.dataBonos.push(lineaNueva);
      }
    });

    return element;
  }

  actualizarPorcentajes() {
    var porcentaje = 100.0;
    switch (this.configuracion.idDistribucionTiempoPreparacion) {
      case 1: // Manual
        this.dataDistribucionTiempos.forEach(
          element => {
            if (element.idOperacion != -1) element.porcentaje = (porcentaje / (this.dataDistribucionTiempos.length - 1)).toFixed(2);
          });
        break;
      case 2: // Cantidades
        var cantidadTotalSuma = this.dataDistribucionTiempos.map(f => f.cantidad).reduce(function (a, b) { return a + b; }, 0);
        this.dataDistribucionTiempos.forEach(element => {
          if (element.idOperacion != -1) element.porcentaje = (element.cantidad / (cantidadTotalSuma) * 100).toFixed(2);
        })
        break;
      case 3: // Tiempos
        var tiempoEstimadoSuma = this.dataDistribucionTiempos.map(f => f.tiempoEstimado).reduce(function (a, b) { return a + b; }, 0);
        this.dataDistribucionTiempos.forEach(element => {
          if (element.idOperacion != -1) element.porcentaje = (element.tiempoEstimado / (tiempoEstimadoSuma) * 100).toFixed(2);
        })
        break;
      default:
        break;
    }
  }
  //#endregion

  //#region VOLVER A ABRIR LA OPERACION
  aceptarAbrirOperacion() {
    this.Jbonos.forEach(element => {
      if (element.abrirOperacionPreguntar) {
        element.terminado = false;
        element.abrirOperacion = true;
        element.abrirOperacionPreguntar = false;
        element.abrirOperacionPreguntado = true;
      }
    })
    this.modalReferenceAbrirOperacion.close();
    this.modalReferenceAbrirOperacion = undefined;
  }
  cancelarAbrirOperacion() {
    this.Jbonos.forEach(element => {
      if (element.abrirOperacionPreguntar) {
        element.terminado = false;
        element.abrirOperacion = false;
        element.abrirOperacionPreguntar = false;
        element.abrirOperacionPreguntado = true;
      }
    })
    this.modalReferenceAbrirOperacion.close();
    this.modalReferenceAbrirOperacion = undefined;
  };
  //#endregion

  //#region VOLVER A ABRIR LA PIEZA
  aceptarAbrirPieza() {
    this.Jbonos.forEach(element => {
      if (element.abrirOperacionPreguntar) {
        element.piezaTerminada = false;
        element.abrirPieza = true;
        element.abrirOperacionPreguntar = false;
        element.abrirOperacionPreguntado = true;
      }
    })
    this.modalReferenceAbrirOperacion.close();
    this.modalReferenceAbrirOperacion = undefined;
  }
  cancelarAbrirPieza() {
    this.Jbonos.forEach(element => {
      if (element.abrirOperacionPreguntar) {
        element.terminado = false;
        element.abrirPieza = false;
        element.abrirOperacionPreguntar = false;
        element.abrirOperacionPreguntado = true;
      }
    })
    this.modalReferenceAbrirOperacion.close();
    this.modalReferenceAbrirOperacion = undefined;
  };
  //#endregion

  //#region VOLVER A ABRIR LA OPERACION Y PIEZA
  aceptarAbrirOperacionYPieza() {
    this.Jbonos.forEach(element => {
      if (element.abrirOperacionPreguntar) {
        element.terminado = false;
        element.abrirOperacion = true;
        element.abrirPieza = true;
        element.abrirOperacionPreguntar = false;
        element.abrirOperacionPreguntado = true;
      }
    })
    this.modalReferenceAbrirOperacion.close();
    this.modalReferenceAbrirOperacion = undefined;
  }
  cancelarAbrirOperacionYPieza() {
    this.Jbonos.forEach(element => {
      if (element.abrirOperacionPreguntar) {
        element.terminado = false;
        element.abrirOperacion = false;
        element.abrirPieza = false;
        element.abrirOperacionPreguntar = false;
        element.abrirOperacionPreguntado = true;
      }
    })
    this.modalReferenceAbrirOperacion.close();
    this.modalReferenceAbrirOperacion = undefined;
  };
  //#endregion

  //#region TERMINAR OPERACION
  aceptarTerminarOperacion() {
    this.Jbonos.forEach(element => {
      // Si es la misma linea seleccionada se le debe poner terminado
      if (this.dataBonosSelecteds[0].idOperacion == element.idOperacion && this.dataBonosSelecteds[0].identificadorGrid == element.identificadorGrid) {
        element.terminado = true;
      }
      // Si es la misma operacion y no es la linea terminada entonces se debe poner como no terminado
      else if (this.dataBonosSelecteds[0].idOperacion == element.idOperacion && this.dataBonosSelecteds[0].identificadorGrid != element.identificadorGrid) {
        element.terminado = false;
      }
    })
    this.modalReferenceTerminarOperacion.close();
    this.modalReferenceTerminarOperacion = undefined;
  }
  cancelarTerminarOperacion() {
    this.modalReferenceTerminarOperacion.close();
    this.modalReferenceTerminarOperacion = undefined;
  };
  //#endregion


  //#region POPUP: DESVALIDAR
  advertenciaDesvalidar() {
    this.modalReferenceDesvalidar = this.modalService.open(this.popupDesvalidar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  cancelarDesvalidar() {
    this.modalReferenceDesvalidar.close();
    this.modalReferenceDesvalidar = undefined;
  }
  //#endregion

  piezaTerminadaChange() {
    if (this.piezaTerminada) {
      // Añadir una pieza terminada
      if (this.hmiCantidadesAsignadas.map(f => f.cantidadTerminada).reduce(function (a, b) { return a + b; }, 0) == 0)
        this.btnAnnadir_HMI_CantidadesPiezasAsignadas(1);
    } else {
      this.hmiCantidadesAsignadas.forEach(element => {
        if (element.cantidadTerminada == 1)
          element.cantidadTerminada = 0;
      });
    }
  }
}
